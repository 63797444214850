import { createContext, createElement, type ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { useActiveOrg } from '../components/propelauth';
import { trpc } from '../components/trpc';
import { CREDITS_THRESHOLD } from '../constants';
import { canPerformPlanLimitedAction } from '../guards/billingLimitGuard';

type ContextType = {
  isLoading: boolean;
  hasSubscription: boolean;
  hasValidSubscription: boolean;
  hasEnoughCredits: boolean;
  hasReachedUsersCountLimit: boolean;
  hasReachedProjectsCountLimit: boolean;
  setOrgId: (orgId: string) => void;
};

const BillingGuardContext = createContext<ContextType | undefined>(undefined);

const BillingGuardProvider = ({ children }: { children: ReactNode }) => {
  const activeOrg = useActiveOrg();
  const [orgId, setOrgId] = useState('');

  const hasOrgId = orgId !== '';

  const getCheckoutQuery = trpc.subscriptions.getCheckoutInfo.useQuery({ orgId }, { enabled: hasOrgId });
  const { data: subscriptionInfo, isLoading: isBillingLoading } = getCheckoutQuery;

  const creditsQuery = trpc.creditsBalance.getCreditsBalance.useQuery({ orgId }, { enabled: hasOrgId });
  const { isLoading: areCreditsLoading, data: credits } = creditsQuery;

  const teammatesQuery = trpc.auth.fetchOrgUsers.useQuery({ orgId }, { enabled: hasOrgId });
  const { data: teammates } = teammatesQuery;

  const projectsQuery = trpc.projects.getProjects.useQuery({ orgId }, { enabled: hasOrgId });
  const { data: projects } = projectsQuery;

  const subscription = subscriptionInfo?.session;

  const hasSubscription = useMemo(() => {
    if (!subscription || typeof subscription === 'string') {
      return false;
    }

    return true;
  }, [subscription]);

  const hasValidSubscription = useMemo(() => {
    if (!subscription || typeof subscription === 'string') {
      return false;
    }

    const subscriptionStatus = subscription?.status;

    return !!subscriptionInfo && subscriptionStatus === 'active';
  }, [subscriptionInfo, subscription]);

  const hasReachedUsersCountLimit = useMemo(() => {
    if (!subscription || typeof subscription === 'string' || !teammates) {
      return true;
    }

    // @ts-ignore
    const plan = subscription.plan;
    if (!plan) {
      return true;
    }

    return !canPerformPlanLimitedAction(plan.product.name, teammates?.length, 'maxUsers');
  }, [subscription, activeOrg, teammates]);

  const hasReachedProjectsCountLimit = useMemo(() => {
    if (!subscription || typeof subscription === 'string' || !projects) {
      return true;
    }

    // @ts-ignore
    const plan = subscription.plan;
    if (!plan) {
      return true;
    }

    return !canPerformPlanLimitedAction(plan.product.name, projects?.length, 'maxProjects');
  }, [subscription, activeOrg, projects]);

  const hasEnoughCredits = useMemo(() => {
    if (!credits?.balance) {
      return false;
    }

    return Number(credits.balance) > CREDITS_THRESHOLD;
  }, [credits?.balance]);

  const isLoading = useMemo(() => {
    return !hasOrgId || !activeOrg || isBillingLoading || areCreditsLoading;
  }, [activeOrg, isBillingLoading, hasOrgId, areCreditsLoading]);

  useEffect(() => {
    if (activeOrg) {
      setOrgId(activeOrg.orgId);
    }
  }, [activeOrg]);

  const value = useMemo(
    () => ({
      setOrgId,
      isLoading,
      hasSubscription,
      hasValidSubscription,
      hasReachedUsersCountLimit,
      hasReachedProjectsCountLimit,
      hasEnoughCredits,
    }),
    [isLoading, hasSubscription, hasValidSubscription, setOrgId, hasEnoughCredits, hasReachedUsersCountLimit]
  );

  return createElement(BillingGuardContext.Provider, { value }, children);
};

const useBillingGuard = () => {
  const context = useContext(BillingGuardContext);

  if (context === undefined) {
    throw new Error('useBillingGuard must be used within a BillingGuardProvider');
  }

  return context;
};

export { BillingGuardProvider, useBillingGuard };
