import { Switch } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useNotifications } from '../../providers/notifications';
import { useActiveOrg, useAuthInfo } from '../propelauth';
import { trpc } from '../trpc';
import { classNames } from '../utils/classNames';
import { Button } from './Button';
import { SectionHeader } from './SectionHeader/SectionHeader';

export function SettingsProjectGeneral() {
  const { t } = useTranslation();
  const { projectId } = useParams<{ projectId: string }>();
  // @ts-ignore
  const { sendNotification } = useNotifications();
  const [name, setName] = useState('');
  const [isShared, setIsShared] = useState(false);
  const activeOrg = useActiveOrg();
  const orgId = activeOrg?.orgId ?? '';

  const getProjectsQuery = trpc.projects.getProjects.useQuery({ orgId }, { enabled: !!orgId });
  const projectQuery = trpc.projects.getProject.useQuery(
    { projectId: projectId!, orgId },
    { enabled: !!orgId && !!projectId }
  );
  const { data: project } = projectQuery;

  const updateProjectMutation = trpc.projects.updateProject.useMutation({
    onSuccess: () => {
      sendNotification({
        id: 'update-project-success',
        text: t('settings.projects.updateSuccess'),
        type: 'success',
        autoDismiss: true,
      });

      projectQuery.refetch();
      getProjectsQuery.refetch();
    },
    onError: (err) => {
      sendNotification({
        id: 'update-project-error',
        text: t('settings.projects.updateFailure'),
        type: 'error',
      });
    },
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!projectId) return;
    if (!orgId) return;

    updateProjectMutation.mutate({
      projectId,
      orgId,
      name,
      shared: isShared,
    });
  };

  useEffect(() => {
    if (project?.name) {
      setName(project.name);
    }

    if (project?.shared !== undefined) {
      setIsShared(project.shared);
    }
  }, [project]);

  return (
    <div className="flex h-full flex-col bg-slate-900 p-4 rounded-lg">
      <SectionHeader
        title={t('settings.projects.general.title')}
        subTitle={t('settings.projects.general.subtitle')}
        component={<InformationCircleIcon className="text-white h-6 w-6 shrink-0" aria-hidden="true" />}
      />

      <form className="flex h-full flex-col gap-6">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <div className="sm:col-span-2">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-white sm:mt-1.5">
                {t('settings.projects.general.name')}
              </label>
            </div>
            <div className="sm:col-span-1">
              <input
                type="text"
                name="name"
                id="name"
                className="block w-full rounded-md border-0 bg-slate-900 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                placeholder={t('settings.projects.general.name')}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>

          <div className="flex items-center">
            <div>
              <label htmlFor="access" className="block text-sm font-medium leading-6 text-white mb-2">
                {t('settings.projects.general.shareWithTeammates')}
              </label>
              <p className="text-sm text-slate-400 mr-6">
                {t('settings.projects.general.shareWithTeammatesSubtitle', { orgName: activeOrg?.orgName })}
              </p>
            </div>
            <div className="sm:col-span-2 ">
              <Switch
                checked={isShared}
                onChange={setIsShared}
                className={classNames(
                  isShared ? 'bg-indigo-600' : 'bg-slate-600',
                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    isShared ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-slate-300 shadow ring-0 transition duration-200 ease-in-out'
                  )}
                />
              </Switch>
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-3">
          <Button type="submit" text={t('common.save')} onClick={(e) => handleSubmit(e)} style="primary" />
        </div>
      </form>
    </div>
  );
}
