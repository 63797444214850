import { NotificationsProvider } from '../../../providers/notifications';
import Notifications from '../Notifications';
import { OnboardingSignUp } from '../OnboardingSignUp/OnboardingSignUp';

export const SignUp = () => (
  <NotificationsProvider>
    <>
      <OnboardingSignUp />
      <Notifications />
    </>
  </NotificationsProvider>
);
