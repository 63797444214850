import { CheckIcon } from '@heroicons/react/24/outline';

export default function OnboardingSteps({ step }: { step: number }) {
  const steps = [
    {
      id: '01',
      name: 'Account creation',
      href: '#',
      status: step === 1 ? 'current' : step > 1 ? 'complete' : 'upcoming',
    },
    {
      id: '02',
      name: 'Email confirmation',
      href: '#',
      status: step === 2 ? 'current' : step > 2 ? 'complete' : 'upcoming',
    },
    {
      id: '03',
      name: 'Subscription selection',
      href: '#',
      status: step === 3 ? 'current' : step > 3 ? 'complete' : 'upcoming',
    },
    {
      id: '04',
      name: 'Project creation',
      href: '#',
      status: step === 4 ? 'current' : step > 4 ? 'complete' : 'upcoming',
    },
  ];

  return (
    <nav aria-label="Progress">
      <ol role="list" className="divide-y divide-slate-700 rounded-md border border-slate-700 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex md:flex-1">
            {step.status === 'complete' ? (
              <div className="flex w-full items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-slate-500">
                    <CheckIcon className="h-6 w-6 text-slate-500" aria-hidden="true" />
                  </span>
                  <span className="ml-4 text-sm font-medium text-slate-500">{step.name}</span>
                </span>
              </div>
            ) : step.status === 'current' ? (
              <div className="flex items-center px-6 py-4 text-sm font-medium" aria-current="step">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-200">
                  <span className="text-indigo-200">{step.id}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-indigo-200">{step.name}</span>
              </div>
            ) : (
              <div className="flex items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-slate-600">
                    <span className="text-slate-500">{step.id}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-slate-500">{step.name}</span>
                </span>
              </div>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                  <svg
                    className="h-full w-full text-slate-700"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
}
