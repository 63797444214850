import { Listbox, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronDownIcon,
  DocumentIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { DOC_COUNT_PER_PAGE } from '../../../constants';
import { useModals } from '../../../providers/modals';
import { useNotifications } from '../../../providers/notifications';
import { useActiveOrg } from '../../propelauth';
import { trpc } from '../../trpc';
import { classNames } from '../../utils/classNames';
import { Button } from '../Button';
import { DocTypeLabel } from '../DocTypeLabel/DocTypeLabel';
import { Dropdown, type DropdownAction } from '../Dropdown';
import { Loader } from '../Loader/Loader';
import { Pagination } from '../Pagination/Pagination';
import { Typo } from '../Typo';

export const DocumentsList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string }>();
  const { openModal } = useModals();
  const activeOrg = useActiveOrg();
  const orgId = activeOrg?.orgId ?? '';

  const [jiraSyncStatus, setJiraSyncStatus] = useState<string>();
  const [needJiraSync, setNeedJiraSync] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState('');
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const [scoreFilterValue, setScoreFilterValue] = useState<string>();
  const [testCasesFilter, setTestCasesFilter] = useState<string>();
  const [automatedTestsFilter, setAutomatedCasesFilter] = useState<string>();
  const [needFetchDocumentsFromDB, setNeedFetchDocumentsFromDB] = useState<boolean>(true);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = searchParams.get('page') || 1;
  const { sendNotification } = useNotifications();

  // 0- count number of documents in db
  const getDocumentsCountQuery = trpc.documents.getDocumentsCount.useQuery(
    {
      projectId: projectId!,
      orgId,
      searchKeyword,
      scoreFilterValue,
      testCasesFilter,
      automatedTestsFilter,
    },
    {
      enabled: !!orgId && !!projectId,
      staleTime: 1000,
      retry: (retry, error) => {
        return retry < 3 && !error.data?.code;
      },
    }
  );
  const { data: countDocuments } = getDocumentsCountQuery;

  const documentDeleteMutation = trpc.documents.deleteDocument.useMutation({
    onSuccess: () => {
      console.log('Document deleted successfully');
      documentsQuery.refetch();
      sendNotification({
        id: 'document-delete-success',
        text: t('documents.deleteSuccess'),
        type: 'success',
        autoDismiss: true,
      });
    },
    onError: (error) => {
      console.error("Couldn't delete document", error);
    },
  });

  // 1- load existing documents from DB
  const documentsQuery = trpc.documents.getDocuments.useQuery(
    {
      projectId: projectId ?? '',
      orgId,
      currentPage: Number(currentPage),
      searchKeyword,
      scoreFilterValue,
      testCasesFilter,
      automatedTestsFilter,
    },
    {
      enabled: !!orgId && !!projectId && needFetchDocumentsFromDB,
      onSuccess: (data: any) => {
        setNeedFetchDocumentsFromDB(false);
      },
      staleTime: 1000,
      retry: (retry, error) => {
        return retry < 3 && !error.data?.code;
      },
    }
  );
  const { data: documents, isLoading: isLoadingDocuments } = documentsQuery;

  // 2- sync with Jira, after user has clicked on sync button
  trpc.documents.fetchJiraDocsAndUpdate.useQuery(
    {
      projectId: projectId ?? '',
      orgId,
    },
    {
      // The query will not execute until the needJiraSync is true
      enabled: !!orgId && !!projectId && !!documents && needJiraSync,
      onSuccess: (data: any) => {
        documentsQuery.refetch();
        console.log('Jira synced done successfully');
        setNeedJiraSync(false);
        setJiraSyncStatus(data);
      },
    }
  );

  // 3- notify user that the sync is done
  useEffect(() => {
    if (jiraSyncStatus === 'success') {
      sendNotification({
        id: 'jira-sync-success',
        text: t('documents.jiraSyncSuccess'),
        subText: t('documents.jiraSyncSuccessSubtitle'),
        type: 'success',
        autoDismiss: true,
      });
    }
  }, [jiraSyncStatus]);

  // 4- fetch project infomation
  const projectQuery = trpc.projects.getProject.useQuery(
    { projectId: projectId!, orgId },
    { enabled: !!orgId && !!projectId }
  );
  const { data: project } = projectQuery;

  // 5- apply search and filters
  const displayStatus = (status: string) => {
    const trimmedStatus = status.trim().toLowerCase();
    if (trimmedStatus === 'todo') {
      return (
        <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
          {status}
        </span>
      );
    } else if (trimmedStatus === 'inprogress') {
      return (
        <span className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
          {status}
        </span>
      );
    } else if (trimmedStatus === 'done') {
      return (
        <span className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
          {status}
        </span>
      );
    } else {
      return (
        <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
          {status}
        </span>
      );
    }
  };

  const handleSyncWithJiraClick = () => {
    setNeedJiraSync(true);
  };

  const handleRowClick = (docId: string) => {
    navigate(`/app/projects/${projectId}/documents/${docId}`);
  };

  const handlePaginationClick = (currentPage: number) => {
    setSearchParams({ page: currentPage.toString() });
    setNeedFetchDocumentsFromDB(true);
  };

  const handleClickScoreFilter = (scoreFilter: string) => {
    setScoreFilterValue(scoreFilter);
    setNeedFetchDocumentsFromDB(true);
  };

  const handleClickTestCasesFilter = (testCasesFilter: string) => {
    setTestCasesFilter(testCasesFilter);
    setNeedFetchDocumentsFromDB(true);
  };

  const handleClickAutomatedTestsFilter = (automatedTestsFilter: string) => {
    setAutomatedCasesFilter(automatedTestsFilter);
    setNeedFetchDocumentsFromDB(true);
  };

  useEffect(() => {
    // Handle the actual setting of the search keyword after a debounce period
    const timeoutId = setTimeout(() => {
      setSearchKeyword(inputValue);
      setNeedFetchDocumentsFromDB(true);
    }, 800);

    // Clear the timeout if inputValue changes or the component unmounts
    return () => clearTimeout(timeoutId);
  }, [inputValue]);

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleSelectItem = (e: any) => {
    const newSelectedItems = selectedItems.includes(e.target.value)
      ? selectedItems.filter((item) => item !== e.target.value)
      : [...selectedItems, e.target.value];
    setSelectedItems(newSelectedItems);
  };

  const handleSelectAllClick = (e: any) => {
    e.stopPropagation();
    const newSelectedItems = selectedItems.length === documents.length ? [] : documents.map((d: any) => d.id);
    setSelectedItems(newSelectedItems);
  };

  const dropdownActions: DropdownAction[] = [
    {
      text: 'Delete',
      action: () => {
        openModal({
          id: 'delete-documents',
          type: 'danger',
          text: 'Delete documents',
          subText: 'Are you sure you want to delete the selected documents?',
          onConfirm: () => {
            selectedItems.forEach((id) => {
              documentDeleteMutation.mutate({ id, orgId: orgId! });
            });
            setSelectedItems([]);
          },
        });
      },
      icon: TrashIcon,
      danger: true,
    },
  ];

  return (
    <div>
      <div className="mx-auto">
        {isLoadingDocuments ? (
          <div className="grid grid-cols-1 min-h-full h-screen justify-items-center items-center">
            <Loader />
          </div>
        ) : (
          <>
            {documents?.length === 0 ? (
              <div className="mt-6 flex flex-col items-center text-center">
                <DocumentIcon className="h-14 w-14 text-slate-500" />
                <h3 className="mt-2 text-sm font-semibold text-white">No documents</h3>
                {project?.integration ? (
                  <>
                    <p className="mt-1 text-sm text-gray-400">There are no documents in this project for now.</p>
                    <p className="text-sm text-gray-400">
                      You can sync with Jira to create documents from your Jira issues.
                    </p>
                    <div className="mt-6">
                      <Button
                        text={t('documents.syncCTA')}
                        onClick={handleSyncWithJiraClick}
                        loading={needJiraSync}
                        style="primary"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <p className="mt-1 text-sm text-gray-400">Get started by creating a new document.</p>
                    <div className="mt-6">
                      <a
                        href={`/app/projects/${project?.id}/documents/create`}
                        className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                        New Document
                      </a>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className="py-10">
                <div className="px-4 sm:px-6 lg:px-8">
                  <div className="sm:flex sm:justify-between">
                    <div className="sm:flex sm:items-center space-x-4">
                      {/** Search */}
                      <div className="flex rounded-md shadow-sm">
                        <div className="relative flex flex-grow items-stretch focus-within:z-10">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </div>
                          <input
                            type="text"
                            name="search"
                            className="block w-full rounded-md border-0 bg-slate-900 py-2 pl-10 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                            placeholder={t('documents.searchAndFilters.search')}
                            value={inputValue}
                            onChange={handleInputChange}
                          />
                        </div>
                        {/* <button
                        type="button"
                        onClick={handleSearchChange}
                        className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md border-0 bg-slate-900 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                      >
                        Search
                      </button> */}
                      </div>
                      {/** Filters */}
                      <div className="sm:flex space-x-4">
                        <select
                          id="scoreFilter"
                          name="scoreFilter"
                          defaultValue=""
                          className="block w-full rounded-md border-0 bg-slate-900 pl-3 pr-10 text-white ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                          onChange={(e) => handleClickScoreFilter(e.target.value)}
                        >
                          <option value="" disabled>
                            {t('documents.searchAndFilters.score.default')}
                          </option>
                          <option key="no_score" value="no_score">
                            {t('documents.searchAndFilters.score.noScore')}
                          </option>
                          <option key="lower_than_20" value="bad">
                            {t('documents.searchAndFilters.score.lower20')}
                          </option>
                          <option key="between_20_and_80" value="medium">
                            {t('documents.searchAndFilters.score.between20And80')}
                          </option>
                          <option key="greater_than_80" value="good">
                            {t('documents.searchAndFilters.score.greater80')}
                          </option>
                        </select>
                        <select
                          id="withTestCasesFilter"
                          name="withTestCasesFilter"
                          defaultValue=""
                          className="block w-full rounded-md border-0 bg-slate-900 pl-3 pr-10 text-white ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                          onChange={(e) => handleClickTestCasesFilter(e.target.value)}
                        >
                          <option value="" disabled>
                            {t('documents.searchAndFilters.tests.default')}
                          </option>
                          <option key="no_test" value="no_test">
                            {t('documents.searchAndFilters.tests.without')}
                          </option>
                          <option key="with_tests" value="with_test">
                            {t('documents.searchAndFilters.tests.with')}
                          </option>
                        </select>
                        <select
                          id="withAutomatedTestsFilter"
                          name="withAutomatedTestsFilter"
                          defaultValue=""
                          className="block w-full rounded-md border-0 bg-slate-900 pl-3 pr-10 text-white ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                          onChange={(e) => handleClickAutomatedTestsFilter(e.target.value)}
                        >
                          <option value="" disabled>
                            {t('documents.searchAndFilters.automatedTests.default')}
                          </option>
                          <option key="no_test" value="no_test">
                            {t('documents.searchAndFilters.automatedTests.without')}
                          </option>
                          <option key="with_tests" value="with_test">
                            {t('documents.searchAndFilters.automatedTests.with')}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div className="flex items-center gap-4">
                      {selectedItems.length > 0 && <Dropdown text={t('common.actions')} actions={dropdownActions} />}

                      {project?.integration === 'Jira' && (
                        <div className="">
                          <Button text={t('documents.syncCTA')} onClick={handleSyncWithJiraClick} style="primary" />
                        </div>
                      )}
                      <div className="">
                        <Button
                          text={t('documents.createCTA')}
                          onClick={() => navigate(`/app/projects/${projectId}/documents/create`)}
                          style="secondary"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-8 flow-root">
                    {documentsQuery.isLoading ? (
                      <p className="mt-2 text-l text-gray-300">{t('common.loading')}</p>
                    ) : (
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table className="min-w-full divide-y divide-gray-700">
                            <thead>
                              <tr>
                                <th scope="col" className="text-left min-w-[40px] w-[40px]">
                                  <input
                                    type="checkbox"
                                    checked={documents.every((d: any) => selectedItems.includes(d.id))}
                                    className="h-4 w-4 bg-slate-700 rounded border-slate-600 text-indigo-500 focus:ring-indigo-500"
                                    onChange={handleSelectAllClick}
                                  />
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                                >
                                  {t('documents.list.headers.document')}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                                >
                                  {t('documents.list.headers.type')}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                                >
                                  {t('documents.list.headers.status')}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                                >
                                  {t('documents.list.headers.score')}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                                >
                                  {t('documents.list.headers.testCases')}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                                >
                                  {t('documents.list.headers.automatedTests')}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-800">
                              {documents?.map((document: any) => (
                                <tr
                                  key={document.id}
                                  className="cursor-pointer"
                                  onClick={() => handleRowClick(document.documentId)}
                                >
                                  <td>
                                    <input
                                      type="checkbox"
                                      value={document.id}
                                      checked={selectedItems.includes(document.id)}
                                      className="h-4 w-4 bg-slate-700 rounded border-slate-600 text-indigo-500 focus:ring-indigo-500"
                                      onChange={handleSelectItem}
                                      onClick={(e) => e.stopPropagation()}
                                    />
                                  </td>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                                    {document.key} <br />
                                    <span className="text-xs">{`${document.title?.substring(0, 80)}...`}</span>
                                  </td>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                                    <DocTypeLabel type={document.type} />
                                  </td>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                                    {document.status.toLowerCase().trim() === 'todo'}
                                    {displayStatus(document.status)}
                                  </td>
                                  <td
                                    className={`whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-0 text-${document.score ? 'white' : 'gray-400'}`}
                                  >
                                    {document.score ? `${document.score}/100` : t('common.none')}
                                  </td>
                                  <td
                                    className={`whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-0 text-${document.testCasesCount && document.testCasesCount != null ? 'white' : 'gray-400'}`}
                                  >
                                    {document.testCasesCount && document.testCasesCount != null
                                      ? `${document.testCasesCount} test cases`
                                      : t('common.none')}
                                  </td>
                                  <td
                                    className={`whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-0 text-${document.automatedTestsCount && document.automatedTestsCount != null ? 'white' : 'gray-400'}`}
                                  >
                                    {document.testCasesCount && document.automatedTestsCount != null
                                      ? `${document.automatedTestsCount} automated tests`
                                      : t('common.none')}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mt-8">
                    <Pagination
                      goToPage={handlePaginationClick}
                      currentPage={Number(currentPage)}
                      pageTotal={countDocuments ? Math.ceil(countDocuments / DOC_COUNT_PER_PAGE) : 0}
                      totalItem={countDocuments || 0}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
