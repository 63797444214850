import { PLANS_LIMITS } from '../constants';
import type { Plans } from '../types/types';

export const canPerformPlanLimitedAction = (
  planProductName: Plans,
  itemCount: number,
  limit: 'maxUsers' | 'maxProjects'
) => {
  const limits = PLANS_LIMITS[planProductName];

  if (!limits) {
    return false;
  }

  // Make sure the user is not trying to perform an action that exceeds the plan limit
  return itemCount < limits[limit];
};
