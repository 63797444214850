export const parseScoringResponse = (text: string) => {
  const lines = text.trim().split('\n');
  const variables: any = {};

  for (const line of lines) {
    const [key, value] = line.split(':').map((item) => item.trim());
    // Clean up the key by removing asterisks and trimming spaces
    const cleanKey = key?.replace(/\*/g, '').trim();

    if (cleanKey == 'Unique Identifier (ID)') variables['id'] = value === 'YES';
    if (cleanKey == 'Title') variables['title'] = value === 'YES';
    if (cleanKey == 'Title-assessment') variables['title-assessment'] = value;
    if (cleanKey == 'Status') variables['status'] = value === 'YES';
    if (cleanKey == 'Document Source') variables['documentSource'] = value === 'YES';
    if (cleanKey == 'Dependencies') variables['dependencies'] = value === 'YES';
    if (cleanKey == 'Publication date') variables['publicationDate'] = value === 'YES';
    if (cleanKey == 'User stories') variables['userStories'] = value === 'YES';
    if (cleanKey == 'User stories-assessment') variables['userStories-assessment'] = value;
    if (cleanKey == 'Description of typical user') variables['typicalUserDescription'] = value === 'YES';
    if (cleanKey == 'Description of typical user-assessment') variables['typicalUserDescription-assessment'] = value;
    if (cleanKey == 'Acceptance criteria') variables['accptanceCriteria'] = value === 'YES';
    if (cleanKey == 'Acceptance criteria-assessment') variables['accptanceCriteria-assessment'] = value;
    if (cleanKey == 'Contextualisation section') variables['contextSection'] = value === 'YES';
    if (cleanKey == 'Contextualisation section-assessment') variables['contextSection-assessment'] = value;
    if (cleanKey == 'Model') variables['model'] = value === 'YES';
    if (cleanKey == 'Technical implementation section') variables['techImplementationSection'] = value === 'YES';
    if (cleanKey == 'Graphic section showing visual rendering') variables['uiSection'] = value === 'YES';
    if (cleanKey == 'Development priority') variables['developmentPriority'] = value === 'YES';
    if (cleanKey == 'Effort or complexity estimation') variables['effortEstimation'] = value === 'YES';
    if (cleanKey == 'Risk') variables['risk'] = value === 'YES';
    if (cleanKey == 'Tracking section showing user behaviour') variables['trackingSection'] = value === 'YES';
    if (cleanKey == '3C') variables['3c'] = value === 'YES';
    if (cleanKey == 'INVEST') variables['invest'] = value === 'YES';
    if (cleanKey == 'Functionality') variables['functionality'] = value === 'YES';
    if (cleanKey == 'Performance') variables['performance'] = value === 'YES';
    if (cleanKey == 'Security') variables['security'] = value === 'YES';
    if (cleanKey == 'Interoperability') variables['interoperability'] = value === 'YES';
    if (cleanKey == 'Maintainability') variables['maintainability'] = value === 'YES';
    if (cleanKey == 'Compatibility') variables['compatibility'] = value === 'YES';
    if (cleanKey == 'Reliability') variables['reliability'] = value === 'YES';
    if (cleanKey == 'Portability') variables['portability'] = value === 'YES';
  }

  return variables;
};

export const computeScore = (criteria: any) => {
  let score = criteria.id ? 10 : 0;
  score += criteria['title-assessment'] === 'STRONG' ? 8 : criteria['title-assessment'] === 'MEDIUM' ? 4 : 0;
  score += criteria.status ? 8 : 0;
  score += criteria.documentSource ? 3 : 0;
  score += criteria.dependencies ? 3 : 0;
  score += criteria.publicationDate ? 1 : 0;
  score += criteria['userStories-assessment'] === 'STRONG' ? 10 : criteria['userStories-assessment'] === 'MEDIUM' ? 5 : 0;
  score += criteria['typicalUserDescription-assessment'] === 'STRONG' ? 5 : criteria['typicalUserDescription-assessment'] === 'MEDIUM' ? 2 : 0;
  score += criteria['accptanceCriteria-assessment'] === 'STRONG' ? 8 : criteria['accptanceCriteria-assessment'] === 'MEDIUM' ? 4 : 0;
  score += criteria['contextSection-assessment'] === 'STRONG' ? 4 : criteria['contextSection-assessment'] === 'MEDIUM' ? 2 : 0;
  score += criteria.model ? 3 : 0;
  score += criteria.techImplementationSection ? 5 : 0;
  score += criteria.uiSection ? 5 : 0;
  score += criteria.developmentPriority ? 4 : 0;
  score += criteria.effortEstimation ? 3 : 0;
  score += criteria.risk ? 3 : 0;
  score += criteria.trackingSection ? 1 : 0;
  score += criteria['3c'] ? 2 : 0;
  score += criteria.invest ? 2 : 0;
  score += criteria.functionality ? 2 : 0;
  score += criteria.performance ? 2 : 0;
  score += criteria.security ? 2 : 0;
  score += criteria.interoperability ? 2 : 0;
  score += criteria.maintainability ? 1 : 0;
  score += criteria.compatibility ? 1 : 0;
  score += criteria.reliability ? 1 : 0;
  score += criteria.portability ? 1 : 0;

  return score;
};
