// import { trpc } from '../trpc';
import { useEffect } from 'react';

// import { OnboardingProject } from './OnboardingProject/OnboardingProject';
// import { useTranslation } from 'react-i18next';
import { env } from '../../config';
import { useBillingGuard } from '../../providers/billingGuard';
// import { NotificationsProvider } from '../../providers/notifications';
import { saveOrgSelectionToLocalStorage, useActiveOrg, useAuthInfo, useLogoutFunction } from '../propelauth';
import { Layout } from './Layout';
import { Loader } from './Loader/Loader';
import { Logo } from './Logo/Logo';
import { Typo } from './Typo';
import { useNavigateToReturnUrl } from './utils';
import { WelcomeScreen } from './WelcomeScreen/WelcomeScreen';
import { useNavigate } from 'react-router-dom';

export function App() {
  // const { t } = useTranslation();
  const navigate = useNavigate();
  const activeOrg = useActiveOrg();
  const { isLoading: isBillingGuardLoading, setOrgId } = useBillingGuard();
  const orgId = activeOrg?.orgId ?? '';
  const auth = useAuthInfo();

  // @ts-ignore
  // const isOwner = auth?.accessHelper?.isRole?.(orgId, 'Owner') ?? false;

  const logout = useLogoutFunction();

  useNavigateToReturnUrl();

  // const getProjectsQuery = trpc.projects.getProjects.useQuery({ orgId });
  // const { data, isLoading: areProjectsLoading } = getProjectsQuery;
  // const projects = data ?? [];
  // const hasProjects = projects.length > 0;

  const handleChooseOrg = (orgId: string) => () => {
    saveOrgSelectionToLocalStorage(orgId);
    window.location.reload();
  };

  useEffect(() => {
    setOrgId(orgId);
  }, [orgId]);

  useEffect(() => {
    // If current auth data is loaded and user is still logged out, redirect to login
    if (!auth.loading && !auth.isLoggedIn) {
      window.location.href = `${env.PUBLIC_AUTH_URL}/login`;
    }
  }, [
    auth.loading,
    // @ts-ignore
    auth.isLoggedIn,
  ]);

  if (auth.loading === false && auth.orgHelper && !activeOrg) {
    const orgs = auth.orgHelper.getOrgs();
    if (orgs.length === 0) {
      navigate('/app/signup');
    }

    if (orgs.length === 1 && orgs[0]) {
      // If there is only one org, we can just select it
      handleChooseOrg(orgs[0].orgId)();
    }

    return (
      <div className="grid grid-cols-1 min-h-full h-screen justify-items-center items-center relative">
        <div className="flex flex-col items-center gap-12">
          <Logo className="h-8" />

          <div className="flex flex-col items-center gap-2">
            <Typo size="lg">Welcome to OpenTestCase</Typo>
            <Typo colour="slate-400" size="sm">
              Select one of your organizations
            </Typo>
          </div>

          <div className="flex flex-col gap-4 max-w-lg text-center">
            {orgs.map((org) => {
              return (
                <div
                  key={org.orgId}
                  className="rounded-lg bg-slate-800 px-4 py-2 cursor-pointer hover:bg-slate-700"
                  onClick={handleChooseOrg(org.orgId)}
                >
                  <Typo fw="semibold">{org.orgName}</Typo>
                </div>
              );
            })}
          </div>
        </div>

        <div className="absolute bottom-10 left-50vw">
          <a href="#" className="text-base text-indigo-300 hover:text-indigo-200" onClick={() => logout(true)}>
            Log out
          </a>
        </div>
      </div>
    );
  }

  // if (isOwner) {
  //   if (auth.loading === false && activeOrg && !isBillingGuardLoading && !hasSubscription) {
  //     // If we have an active org, but for which no plan is selected, we should redirect to the Onboarding plan selection page
  //     navigate('/app/onboarding');
  //   }

  //   if (auth.loading === false && activeOrg && !areProjectsLoading && !isBillingGuardLoading && !hasProjects) {
  //     // If we have an active org, with a plan selected, but no projects, we should redirect to the Onboarding project creation page
  //     return (
  //       <NotificationsProvider>
  //         <OnboardingProject />
  //       </NotificationsProvider>
  //     );
  //   }
  // }

  return (
    <Layout title="Overview">
      {auth.loading || isBillingGuardLoading ? (
        <div className="grid grid-cols-1 min-h-full h-screen justify-items-center items-center">
          <Loader />
        </div>
      ) : (
        <WelcomeScreen />
      )}
    </Layout>
  );
}
