import { useMatch } from 'react-router-dom';

import { MainNavigation } from './MainNavigation';
import { ProjectNavigation } from './ProjectNavigation';

export const Navigation = () => {
  const doesMatchProjectDetails =
    useMatch({ path: '/app/projects/:projectId', end: false }) && !useMatch({ path: '/app/projects/create' });

  if (doesMatchProjectDetails) {
    return <ProjectNavigation />;
  }

  return <MainNavigation />;
};
