import { BeakerIcon, MagnifyingGlassIcon, PlayCircleIcon } from '@heroicons/react/24/outline';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import { useActiveOrg } from '../../propelauth';
import { trpc } from '../../trpc';
import { Button } from '../Button';
import RecordingPreview from '../RecordingPreview/RecordingPreview.tsx';
import { Typo } from '../Typo.tsx';

export function RecordingsList() {
  const { t } = useTranslation();
  const [needFetchDocumentsFromDB, setNeedFetchDocumentsFromDB] = useState<boolean>(true);
  const [inputValue, setInputValue] = useState('');
  const [testCasesFilter, setTestCasesFilter] = useState<string>();
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const [, setSearchParams] = useSearchParams();
  const { projectId } = useParams<{ projectId: string }>();
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const activeOrg = useActiveOrg();
  const orgId = activeOrg?.orgId ?? '';

  const { data: recordings = [], isLoading: areRecordingsLoading } = trpc.flows.getFlows.useQuery(
    {
      projectId: projectId!,
      orgId,
      testCasesFilter,
      searchKeyword,
    },
    {
      enabled: !!projectId,
      onSuccess: () => setNeedFetchDocumentsFromDB(false),
    }
  );

  const onOpen = (recording: any) => {
    setSearchParams({ recordingId: recording.id });
  };

  const handleClickTestsFilter = useCallback((value: string) => {
    setTestCasesFilter(value);
  }, []);

  useEffect(() => {
    // Handle the actual setting of the search keyword after a debounce period
    const timeoutId = setTimeout(() => {
      setSearchKeyword(inputValue);
      setNeedFetchDocumentsFromDB(true);
    }, 800);

    // Clear the timeout if inputValue changes or the component unmounts
    return () => clearTimeout(timeoutId);
  }, [inputValue]);

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  return (
    <div className="">
      <div className="mx-auto">
        <div className=" py-10">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex space-x-4">
              <div className="flex rounded-md shadow-sm">
                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    type="text"
                    name="search"
                    className="block w-[270px] rounded-md border-0 bg-slate-900 py-2 pl-10 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    placeholder={t('recordings.searchAndFilters.search')}
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <select
                id="withAutomatedTestsFilter"
                name="withAutomatedTestsFilter"
                defaultValue=""
                className="block w-full rounded-md border-0 bg-slate-900 pl-3 pr-10 text-white ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                onChange={(e) => handleClickTestsFilter(e.target.value)}
              >
                <option value="" disabled>
                  {t('recordings.searchAndFilters.tests.default')}
                </option>
                <option key="no_test" value="no_test">
                  {t('recordings.searchAndFilters.tests.without')}
                </option>
                <option key="with_tests" value="with_test">
                  {t('recordings.searchAndFilters.tests.with')}
                </option>
              </select>
            </div>

            {!areRecordingsLoading && recordings?.length === 0 && (
              <div className="mt-6 flex flex-col items-center text-center mt-[80px]">
                <BeakerIcon className="h-14 w-14 text-slate-500" />
                <h3 className="mt-2 text-sm font-semibold text-white">{t('recordings.emptyState.title')}</h3>
                <p className="mt-1 text-sm text-gray-400">{t('recordings.emptyState.subtitle')}</p>
              </div>
            )}

            <div className="mt-8 flow-root">
              {areRecordingsLoading ? (
                <p className="mt-2 text-l text-gray-300">{t('common.loading')}</p>
              ) : (
                areRecordingsLoading ||
                (recordings?.length > 0 && (
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-700">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                            >
                              {t('recordings.list.headers.title')}
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                            >
                              {t('recordings.list.headers.testCasesCount')}
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                            >
                              {t('recordings.list.headers.actions')}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-800">
                          {recordings?.map((recording) => (
                            <tr key={recording.id} className="group">
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                                <div className="group flex gap-3 items-center">
                                  <Typo fw="bold">{recording.title}</Typo>

                                  {recording && recording.recordingUrl && (
                                    <>
                                      <Button
                                        size="xs"
                                        className="opacity-0 group-hover:opacity-100"
                                        text={t('testCase.htmlFlowPreviewCTA')}
                                        onClick={() => setIsPreviewModalOpen(true)}
                                        icon={<PlayCircleIcon className="text-white h-5 w-5" />}
                                      />
                                      <RecordingPreview
                                        open={isPreviewModalOpen}
                                        setOpen={setIsPreviewModalOpen}
                                        recording={recording}
                                      />
                                    </>
                                  )}
                                </div>
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                                <Typo size="sm" colour="slate-300">
                                  {t('recordings.list.body.testCasesCount', { count: recording.testCasesCount ?? 0 })}
                                </Typo>
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                                <Button text={t('testCases.list.content.viewCTA')} onClick={() => onOpen(recording)} />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
