import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import type { UserMetadata } from '@propelauth/node';
import { Fragment, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import { useBillingGuard } from '../../providers/billingGuard';
import { useModals } from '../../providers/modals';
import { useNotifications } from '../../providers/notifications';
import { useActiveOrg, useAuthInfo } from '../propelauth';
import { trpc } from '../trpc';
import { Button } from './Button';
import RoleSelect from './Settings/RoleSelect/RoleSelect';
import { SettingsOrganisationLayout } from './SettingsOrganisationLayout';
import { Typo } from './Typo';
import { useNavigate } from 'react-router-dom';

const newTeammate = { email: '', role: 'Member' };

export function SettingsOrganisationTeam() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [teammateToDelete, setTeammateToDelete] = useState<UserMetadata | undefined>(undefined);
  const cancelButtonRef = useRef(null);

  const activeOrg = useActiveOrg();
  const orgId = activeOrg?.orgId ?? '';
  const { sendNotification } = useNotifications();
  const { hasReachedUsersCountLimit } = useBillingGuard();
  const auth = useAuthInfo();
  // @ts-ignore
  const { loading, accessHelper } = auth;

  const [isAddingTeammate, setIsAddingTeammate] = useState(false);
  const [teammate, setTeammate] = useState(newTeammate);

  const { openModal } = useModals();

  const teammatesQuery = trpc.auth.fetchOrgUsers.useQuery(
    {
      orgId,
    },
    { enabled: !!orgId }
  );
  const { data: teammates, isLoading: isLoadingTeammates } = teammatesQuery;

  const isCurrentUserOwner = useMemo(() => {
    if (!accessHelper || !orgId) {
      return false;
    }

    return accessHelper.isRole(orgId, 'Owner');
  }, [accessHelper, orgId]);

  const canInvite = useMemo(() => {
    if (!accessHelper || !orgId) {
      return false;
    }

    return accessHelper.isAtLeastRole(orgId, 'Admin');
  }, [accessHelper, orgId]);

  const canUpdateRoleFor = (roleInOrg: string) => {
    if (!accessHelper || !orgId) {
      return false;
    }

    if (roleInOrg === 'Owner') {
      return false;
    }

    if (accessHelper.isAtLeastRole(orgId, 'Admin')) {
      return true;
    }

    return false;
  };

  const inviteTeammateMutation = trpc.auth.inviteTeammate.useMutation({
    onSuccess: () => {
      setIsAddingTeammate(false);
      setTeammate(newTeammate);
      teammatesQuery.refetch();
      sendNotification({
        id: 'invite-teammate-success',
        text: t('settings.team.inviteSuccess'),
        subText: t('settings.team.inviteSuccessSubtitle'),
        type: 'success',
        autoDismiss: true,
      });
    },
    onError: (err) => {
      console.log('err:', err);
      sendNotification({
        id: 'invite-teammate-error',
        text: t('settings.team.inviteFailure'),
        type: 'error',
      });
    },
  });

  const changeRoleMutation = trpc.auth.changeUserRole.useMutation({
    onSuccess: () => {
      sendNotification({
        id: 'teammate-role-updated-success',
        text: t('settings.team.roleUpdateSuccess'),
        subText: t('settings.team.roleUpdateSuccessSubtitle'),
        type: 'success',
        autoDismiss: true,
      });
      window.location.reload();
    },
    onError: (err) => {
      console.log('err:', err);
      sendNotification({
        id: 'teammate-role-updated-error',
        text: t('settings.team.roleUpdateFailure'),
        type: 'error',
      });
    },
  });

  const deleteUserMutation = trpc.auth.deleteUser.useMutation({
    onSuccess: () => {
      teammatesQuery.refetch();

      sendNotification({
        id: 'teammate-removal-success',
        text: t('settings.team.userRemovalSuccess'),
        subText: t('settings.team.userRemovalSuccessSubtitle'),
        type: 'success',
        autoDismiss: true,
      });
    },
    onError: (err) => {
      console.log('err:', err);
      sendNotification({
        id: 'teammate-removal-error',
        text: t('settings.team.userRemovalFailure'),
        type: 'error',
      });
    },
  });

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, key: string) => {
    setTeammate((prev) => ({ ...prev, [key]: e.target.value }));
  };

  const handleCancelTeammateCreation = () => {
    setIsAddingTeammate(false);
    setTeammate(newTeammate);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!orgId) return;

    if (teammate?.email && teammate?.role) {
      inviteTeammateMutation.mutate({
        orgId,
        ...teammate,
      });
    }
  };

  const handleRoleChange = (teammate: UserMetadata, role: string) => {
    if (!orgId) return;

    openModal({
      id: 'change-role',
      text: 'Change role',
      // @ts-ignore
      subText: `Are you sure you want to change the role of ${teammate.firstName} ${teammate.lastName} from ${teammate.roleInOrg} to ${role} ?`,
      type: 'danger',
      onConfirm: () => {
        changeRoleMutation.mutate({
          orgId,
          userId: teammate.userId,
          role,
        });
      },
      onClose: () => {
        teammatesQuery.refetch();
      },
    });
  };

  const handleDeleteUser = (teammateUserId: string) => {
    setIsDeleteModalOpen(false);
    deleteUserMutation.mutate({
      userId: teammateUserId,
    });
  };

  const handleOpenDeleteModal = (teammate: any) => {
    setTeammateToDelete(teammate);
    setIsDeleteModalOpen(true);
  };

  return (
    <SettingsOrganisationLayout>
      {loading ? (
        <p className="text-white">{t('common.loading')}</p>
      ) : (
        <div className="bg-slate-900 p-4 rounded-lg">
          <Tooltip
            id="role-limitation-tooltip"
            place="bottom-start"
            style={{ backgroundColor: 'rgb(100, 116, 139)', color: '#fff', maxWidth: '360px' }}
            opacity={1}
            clickable
            className="clickable-tooltip"
          />

          <div className="flex items-center justify-between border-b border-slate-800 pb-3 mb-6">
            <h3 className="text-base font-semibold leading-6 text-white">{t('settings.team.title')}</h3>
            <div
              data-tooltip-id="role-limitation-tooltip"
              data-tooltip-html={
                !canInvite
                  ? t('settings.team.cannotInviteTooltip')
                  : hasReachedUsersCountLimit
                    ? t('settings.team.rachedLimitTooltip')
                    : ''
              }
            >
              <Button
                text={t('settings.team.inviteCTA')}
                style="primary"
                onClick={() => setIsAddingTeammate(true)}
                disabled={!canInvite || hasReachedUsersCountLimit}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 gap-6">
            {isLoadingTeammates ? (
              <p className="text-white">{t('common.loading')}</p>
            ) : (
              <div className="flex flex-col text-sm">
                {teammates?.map((teammate) => (
                  <div
                    key={teammate.userId}
                    className="group transition-all hover:bg-slate-900 py-3 -mx-4 px-4 grid grid-cols-3 items-center gap-4"
                  >
                    <div className="flex items-center gap-x-3">
                      <img src={teammate.pictureUrl} alt={teammate.firstName} className="h-8 w-8 rounded-full" />
                      <div className="flex flex-col">
                        <p className="text-white font-semibold">
                          {teammate.firstName} {teammate.lastName}
                        </p>
                        <p className="text-sm text-slate-500">{teammate.email}</p>
                      </div>
                    </div>
                    <div className="justify-self-start">
                      {/* @ts-ignore */}
                      {canUpdateRoleFor(teammate.roleInOrg) ? (
                        <RoleSelect teammate={teammate} onChange={(role) => handleRoleChange(teammate, role)} />
                      ) : (
                        <Typo size="sm" colour="slate-100" fw="bold">
                          {/* @ts-ignore */}
                          {teammate.roleInOrg ? t(`roles.${teammate.roleInOrg.toLowerCase()}`) : t('roles.member')}
                        </Typo>
                      )}
                    </div>
                    {teammate.userId !== auth.user?.userId &&
                      // @ts-ignore
                      canUpdateRoleFor(teammate.roleInOrg) && (
                        <div className="opacity-0 transition-all group-hover:opacity-100 justify-self-end">
                          <Button text={t('common.remove')} onClick={() => handleOpenDeleteModal(teammate)} />
                        </div>
                      )}
                    {teammate.userId === auth.user?.userId && (
                      <div className="opacity-0 transition-all group-hover:opacity-100 justify-self-end">
                        <Button
                          text={t('menu.personalPreferences')}
                          onClick={() => navigate('/app/settings/account')}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}

            {teammateToDelete && (
              <Transition.Root show={isDeleteModalOpen} as={Fragment}>
                <Dialog
                  as="div"
                  className="relative z-10"
                  initialFocus={cancelButtonRef}
                  onClose={setIsDeleteModalOpen}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                          <div className="sm:flex sm:items-start">
                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                              <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                              <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                Remove teammate
                              </Dialog.Title>
                              <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                  Are you sure you want to remove {teammateToDelete.firstName}{' '}
                                  {teammateToDelete.lastName}?
                                </p>
                                <p className="text-sm text-gray-500">This action cannot be undone.</p>
                              </div>
                            </div>
                          </div>
                          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                            <button
                              type="button"
                              className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                              onClick={() => handleDeleteUser(teammateToDelete.userId)}
                            >
                              Remove
                            </button>
                            <button
                              type="button"
                              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                              onClick={() => setIsDeleteModalOpen(false)}
                              ref={cancelButtonRef}
                            >
                              Cancel
                            </button>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>
            )}

            {isAddingTeammate && (
              <div className="flex flex-col gap-y-3 border-t border-slate-700 pt-6">
                <h3 className="text-base font-semibold leading-6 text-white">{t('settings.team.inviteTitle')}</h3>

                <form className="flex h-full flex-col">
                  <div className="flex-1">
                    <div className="space-y-2 py-2 sm:space-y-0 sm:py-0">
                      <div className="space-y-2 px-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-3">
                        <div>
                          <label htmlFor="email" className="block text-sm font-medium leading-6 text-white sm:mt-1.5">
                            {t('settings.team.email')}
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className="block w-full rounded-md border-0 bg-slate-900 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                            onChange={(e) => handleValueChange(e, 'email')}
                          />
                        </div>
                      </div>

                      {/* Integration */}
                      <div className="space-y-2 px-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-3">
                        <div>
                          <label htmlFor="role" className="block text-sm font-medium leading-6 text-white sm:mt-1.5">
                            {t('settings.team.role')}
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <select
                            id="role"
                            name="role"
                            className="block w-full rounded-md border-0 bg-slate-900 pl-3 pr-10 text-white ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                            defaultValue="Member"
                            onChange={(e) => handleValueChange(e, 'role')}
                          >
                            <option value="Member">{t('roles.member')}</option>
                            <option value="Admin">{t('roles.admin')}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Action buttons */}
                  <div className="flex-shrink-0 pt-5 pb-3 px-4">
                    <div className="flex justify-end space-x-3">
                      <Button text={t('common.cancel')} onClick={handleCancelTeammateCreation} />
                      <Button
                        type="submit"
                        text={t('settings.team.submitCTA')}
                        onClick={handleSubmit}
                        style="primary"
                      />
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      )}
    </SettingsOrganisationLayout>
  );
}
