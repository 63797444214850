import { Layout } from './Layout';
import { RecordingsList } from './RecordingsList/RecordingsList';

export function Recordings() {
  return (
    <Layout title={`Projects / Project / Recordings`}>
      <RecordingsList />
    </Layout>
  );
}
