import { CloudArrowUpIcon } from '@heroicons/react/24/outline';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { parseAndUploadDocument } from '../../../api/document/docUpload';
import type { DocType } from '../../../declaration/docType';
import { useNotifications } from '../../../providers/notifications';
import { useActiveOrg, useAuthInfo } from '../../propelauth';
import { trpc } from '../../trpc';
import { SectionHeader } from '../SectionHeader/SectionHeader';
import { Typo } from '../Typo';

const meaningfulError = (error: any) => {
  const { t } = useTranslation();

  switch (error?.message) {
    case 'FILE_TYPE_INCORRECT':
      return t('documents.create.incorrectType');
    case 'XML_INVALID':
      return t('documents.create.invalidXML');
    default:
      return undefined;
  }
};

// export const MyDropzone = ({ onDrop }: { onDrop: (files: File[]) => void }) => {
//   const navigate = useNavigate();
//   const { t } = useTranslation();
//   const { getRootProps, getInputProps, isDragActive } = ReactDropzone.useDropzone({ onDrop });

//   return (
//     <div className="flex items-center justify-center w-full" {...getRootProps()}>
//       <label
//         htmlFor="dropzone-file"
//         className="flex flex-col items-center justify-center w-full h-64 border-4 border-dashed rounded-xl cursor-pointer bg-slate-900 border-slate-600 hover:border-slate-500"
//       >
//         <div className="flex flex-col items-center justify-center pt-5 pb-6">
//           <CloudArrowUpIcon className="h-10 w-10 text-white mb-4" />
//           <Typo>
//             {isDragActive ? (
//               <>{t('documents.create.dropFileHere')}</>
//             ) : (
//               <>
//                 <Typo as="span" fw="semibold">
//                   {t('documents.create.clickToUpload')}
//                 </Typo>{' '}
//                 {t('documents.create.orDragDrop')}
//               </>
//             )}
//           </Typo>
//           <Typo size="sm">{t('documents.create.xmlCsv')}</Typo>
//         </div>
//         <input {...getInputProps()} />
//       </label>
//     </div>
//   );
// };

export const CreateDocument = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string }>();
  const { sendNotification } = useNotifications();
  const [docType, setDocType] = useState<DocType>('user-story');
  const orgId = useActiveOrg()?.orgId || '';
  const auth = useAuthInfo();
  // @ts-ignore
  const { user, isLoggedIn } = auth;

  const createDocumentMutation = trpc.documents.createDocument.useMutation({
    onError(error) {
      sendNotification({
        id: 'doc-upload-failed',
        type: 'error',
        text: t('documents.create.creationFailure'),
      });
    },
    onSuccess(docId: string) {
      console.log('doc created - docId:', docId);
      sendNotification({
        id: `doc-upload-success-${docId}`,
        type: 'success',
        text: t('documents.create.creationSuccess', { docId }),
      });

      navigate(`/app/projects/${projectId}/documents`);
    },
  });

  const onDrop = useCallback(
    async (files: File[]) => {
      if (!files[0]) {
        return;
      }

      try {
        const documentsToCreate = await parseAndUploadDocument(files[0], orgId, user?.userId);

        documentsToCreate.forEach((doc) => {
          createDocumentMutation.mutate({
            orgId,
            documentId: doc.id,
            title: doc.title,
            description: doc.description,
            status: doc.status,
            key: doc.key,
            filePath: doc.filePath,
            fileName: doc.fileName,
            projectId,
            version: 1,
            type: docType,
          });
        });
      } catch (error: any) {
        sendNotification({
          id: 'doc-upload-failed',
          type: 'error',
          text: t('documents.create.error'),
          subText: meaningfulError(error),
        });
      }
    },
    [orgId, user?.userId, projectId, createDocumentMutation, sendNotification, docType]
  );

  return (
    <div className="mx-auto max-w-4xl mt-6">
      <SectionHeader title="Create a new document" />

      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <label htmlFor="docType" className="text-sm semibold text-white">
            {t('documents.create.documentType')}
          </label>
          <select
            id="docType"
            className="block rounded-md border-0 bg-slate-900 pl-3 pr-10 text-white ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
            onChange={(e) => setDocType(e.target.value as DocType)}
            value={docType}
          >
            <option value="user-story">{t('documents.create.documentTypeUserStory')}</option>
            <option value="requirement">{t('documents.create.documentTypeRequirements')}</option>
          </select>
        </div>

        <div className="flex flex-col gap-2">
          <Typo size="sm" fw="semibold">
            {t('documents.create.upload')}
          </Typo>
          {/* <MyDropzone onDrop={onDrop} /> */}
        </div>
      </div>
    </div>
  );
};
