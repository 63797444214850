import { Typo } from '../Typo';

export const Loader = ({ size = 14, text = 'Loading...' }: { size?: number; text?: string }) => {
  const duration = 0.9;

  return (
    <div className=" flex items-center gap-5">
      <div className={`flex items-center h-6 w-${size}`}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
          <circle fill="#cbd5e1" stroke="#cbd5e1" strokeWidth="12" r="15" cx="30" cy="100">
            <animate
              attributeName="opacity"
              calcMode="spline"
              dur={duration}
              values="1;.3;1;"
              keySplines=".5 0 .5 1;.5 0 .5 1"
              repeatCount="indefinite"
              begin="-.4"
            ></animate>
          </circle>
          <circle fill="#cbd5e1" stroke="#cbd5e1" strokeWidth="12" r="15" cx="100" cy="100">
            <animate
              attributeName="opacity"
              calcMode="spline"
              dur={duration}
              values="1;.3;1;"
              keySplines=".5 0 .5 1;.5 0 .5 1"
              repeatCount="indefinite"
              begin="-.2"
            ></animate>
          </circle>
          <circle fill="#cbd5e1" stroke="#cbd5e1" strokeWidth="12" r="15" cx="170" cy="100">
            <animate
              attributeName="opacity"
              calcMode="spline"
              dur={duration}
              values="1;.3;1;"
              keySplines=".5 0 .5 1;.5 0 .5 1"
              repeatCount="indefinite"
              begin="0"
            ></animate>
          </circle>
        </svg>
      </div>

      <Typo size="lg" colour="slate-300">
        {text}
      </Typo>
    </div>
  );
};
