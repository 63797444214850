import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';

import { getFileUrl } from '../../../api/files/files.ts';

export default function RecordingPreview({
  open,
  setOpen,
  recording,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  recording: {
    actions: string;
    projectId: string;
    id: string;
    createdAt: Date;
    updatedAt: Date;
    title: string;
    recordingUrl: string | null;
  };
}) {
  const { recordingUrl, title } = recording;
  const [videoUrl, setVideoUrl] = useState('');

  const fetchVideo = async (recordingUrl: string) => {
    const url = await getFileUrl(recordingUrl);
    setVideoUrl(url);
  };

  useEffect(() => {
    if (open && recordingUrl) {
      fetchVideo(recordingUrl);
    }
  }, [open, recordingUrl]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6 sm:max-w-lg md:max-w-3xl xl:max-w-5xl 2xl:max-w-7xl">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-slate-700 text-slate-200 hover:text-white"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start p-5">{videoUrl !== '' && <video src={videoUrl} controls />}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
