import { Dialog, Transition } from '@headlessui/react';
import {
  BeakerIcon,
  CalendarDaysIcon,
  CheckCircleIcon,
  CursorArrowRaysIcon,
  DocumentTextIcon,
  LinkIcon,
  PencilSquareIcon,
  PlayCircleIcon,
  PlusCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import { getFileUrl } from '../../api/files/files';
import { getRelativeDateTime } from '../../helpers/dates';
import { useActiveOrg } from '../propelauth';
import { trpc } from '../trpc';
import { Button } from './Button';
import { LinkArrow } from './LinkArrow/LinkArrow';
import { Typo } from './Typo';

type Recording = {
  actions: string;
  projectId: string;
  id: string;
  createdAt: Date;
  updatedAt: Date;
  title: string;
  description: string | null;
  recordingUrl: string | null;
};

type TestCase = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  documentId: string;
  title: string;
  flowId: string | null;
  criticality: string | null;
  prerequisites: unknown;
  steps: unknown;
  lowLevelSteps: unknown;
};

export default function RecordingSidePanel() {
  const { t } = useTranslation();
  const [response, setResponse] = useState<{ flow?: Recording; testCases?: TestCase[] | undefined } | undefined>();
  const { projectId } = useParams<{ projectId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [recordingUrl, setRecordingUrl] = useState('');
  const [shouldShowForm, setShouldShowForm] = useState(false);
  const [shouldShowTestCasesSelect, setShouldShowTestCasesSelect] = useState(false);
  const [testCaseIdToAdd, setTestCaseIdToAdd] = useState<string | undefined>();
  const activeOrg = useActiveOrg();
  const orgId = activeOrg?.orgId ?? '';

  const recordingId = searchParams.get('recordingId');

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const getFlowsQuery = trpc.flows.getFlows.useQuery(
    {
      projectId: projectId!,
      orgId,
    },
    { enabled: !!projectId }
  );

  const getFlowQuery = trpc.flows.getFlow.useQuery({ flowId: recordingId ?? '' }, { enabled: !!recordingId });
  const { isLoading, data: flow } = getFlowQuery;

  const { data: testCases } = trpc.testCases.getTestCasesByProject.useQuery(
    { projectId: projectId ?? '', orgId },
    { enabled: !!projectId && !!orgId }
  );

  const updateTestCaseMutation = trpc.testCases.updateTestCase.useMutation({
    onSuccess: () => {
      getFlowsQuery.refetch();
      getFlowQuery.refetch();
      setShouldShowTestCasesSelect(false);
      setTestCaseIdToAdd(undefined);
    },
    onError: (error) => {
      console.error('updateTestCaseMutation error:', error);
    },
  });

  const updateFlowMutation = trpc.flows.updateFlow.useMutation({
    onSuccess: (data) => {
      console.log('flow updated', data);
      getFlowsQuery.refetch();
      getFlowQuery.refetch();
    },
    onError: (error) => {
      console.error('error updating flow', error);
    },
  });

  const fetchVideo = async (recordingUrl: string) => {
    const url = await getFileUrl(recordingUrl);
    setRecordingUrl(url);
  };

  const close = () => {
    setSearchParams({});
  };

  const onClose = () => {
    setShouldShowForm(false);
    reset();
    setSearchParams({});
    setShouldShowTestCasesSelect(false);
    setTestCaseIdToAdd(undefined);
  };

  const onSubmit = (data: any) => {
    updateFlowMutation.mutate({ ...response?.flow, ...data });
    setShouldShowForm(false);
    reset();
  };

  const onEditClick = () => {
    setShouldShowForm(true);
  };

  const onCancelClick = () => {
    setShouldShowForm(false);
    reset();
  };

  const open = useMemo(() => !!recordingId, [recordingId]);

  const handleSaveTestCase = () => {
    updateTestCaseMutation.mutate({
      id: testCaseIdToAdd ?? '',
      flowId: flow?.flow?.id,
    });
  };

  const mappedActions = useMemo(() => {
    if (response?.flow?.actions) {
      return JSON.parse(response.flow.actions)
        .map((action: any) => {
          switch (action.type) {
            case 'load':
              return {
                type: action.type,
                value: action.url,
              };
            case 'click':
              return {
                type: action.type,
                value: action.tagName,
              };
            case 'type':
              return {
                type: action.type,
                selector: action.selector,
                value: action.value,
              };
            case 'input':
              return {
                type: action.type,
                value: action.value,
              };
            default:
              return undefined;
          }
        })
        .filter(Boolean);
    }

    return [];
  }, [response?.flow?.actions]);

  useEffect(() => {
    if (response?.flow) {
      if (response.flow.recordingUrl) {
        fetchVideo(response.flow.recordingUrl);
      }

      setValue('description', response.flow.description);
    }
  }, [response]);

  useEffect(() => {
    if (flow) {
      setResponse(flow);
    }
  }, [flow]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 top-12 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-3xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-slate-900 py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <div className="flex items-center gap-4">
                          <div className="mx-auto flex h-8 w-8 items-center justify-center rounded-full bg-indigo-500">
                            <PlayCircleIcon className="h-6 w-6 text-indigo-100" aria-hidden="true" />
                          </div>
                          <Dialog.Title className="text-base font-semibold leading-6 text-slate-200">
                            {response?.flow?.title}
                          </Dialog.Title>
                        </div>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-slate-900 text-slate-400 hover:text-slate-100 focus:outline-none focus:ring-2 focus:ring-slate-600"
                            onClick={close}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      <div className="flex flex-col gap-12">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="flex flex-col gap-2">
                            {response?.flow?.createdAt && (
                              <div className="flex items-center gap-2">
                                <CalendarDaysIcon className="h-5 w-5 text-slate-400" aria-hidden="true" />
                                <Typo size="sm" colour="slate-300">
                                  {getRelativeDateTime(new Date(response.flow.createdAt))}
                                </Typo>
                              </div>
                            )}

                            <div className="flex items-center justify-between">
                              <div className="flex items-center gap-2">
                                <DocumentTextIcon className="h-5 w-5 text-slate-400" aria-hidden="true" />
                                {response?.flow?.description ? (
                                  <Typo>{response.flow.description}</Typo>
                                ) : (
                                  <Typo className="italic" colour="slate-400" size="sm">
                                    {t('recordings.details.noDescription')}
                                  </Typo>
                                )}
                              </div>

                              {!shouldShowForm ? (
                                <Button
                                  text={t('common.edit')}
                                  type="button"
                                  size="sm"
                                  onClick={onEditClick}
                                  icon={<PencilSquareIcon className="text-white h-5 w-5" />}
                                />
                              ) : (
                                <div className="flex gap-3">
                                  <Button
                                    text={t('common.save')}
                                    type="submit"
                                    size="sm"
                                    style="primary"
                                    icon={<CheckCircleIcon className="text-white h-5 w-5" />}
                                    onClick={() => {}}
                                  />
                                  <Button type="button" size="sm" text={t('common.cancel')} onClick={onCancelClick} />
                                </div>
                              )}
                            </div>

                            {shouldShowForm && (
                              <>
                                <input
                                  {...register('description', { required: true })}
                                  type="text"
                                  placeholder={t('recordings.form.descriptionPlaceholder')}
                                  className="block w-full rounded-md border-0 bg-slate-900 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                />
                                {errors.description && (
                                  <>
                                    {errors.description.type === 'required' && (
                                      <Typo size="xs" colour="red-400">
                                        {t('recordings.form.descriptionRequired')}
                                      </Typo>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </form>

                        <div>
                          <video src={recordingUrl} controls style={{ minHeight: '460px' }} />
                        </div>

                        <div className="flex flex-col gap-3">
                          <div className="flex items-center gap-3">
                            <CursorArrowRaysIcon className="h-5 w-5 text-slate-200" aria-hidden="true" />
                            <Typo size="lg" fw="semibold">
                              Actions
                            </Typo>
                          </div>
                          <div className="flex flex-col gap-2">
                            {mappedActions.map((action: any, index: number) => (
                              <div key={index} className="flex gap-2 items-center">
                                <Typo size="xs" colour="slate-500">
                                  {index + 1}
                                </Typo>
                                <Typo size="sm" fw="semibold" colour="slate-300" className="capitalize">
                                  {action.type}
                                </Typo>
                                <Typo size="sm" colour="slate-300">
                                  {action.value}
                                </Typo>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="flex flex-col gap-3">
                          <div className="flex items-center justify-between">
                            <div className="flex items-center gap-3">
                              <BeakerIcon className="h-5 w-5 text-slate-200" aria-hidden="true" />
                              <Typo size="lg" fw="semibold">
                                {t('recordings.details.linkedTitle')}
                              </Typo>
                            </div>

                            {!shouldShowTestCasesSelect ? (
                              <Button
                                text={t('recordings.details.assignToTestCase')}
                                icon={<LinkIcon className="text-white h-4 w-4" />}
                                size="sm"
                                onClick={() => setShouldShowTestCasesSelect(true)}
                              />
                            ) : (
                              <div className="flex gap-3">
                                <Button
                                  text={t('common.save')}
                                  type="submit"
                                  size="sm"
                                  style="primary"
                                  icon={<CheckCircleIcon className="text-white h-5 w-5" />}
                                  onClick={handleSaveTestCase}
                                />
                                <Button
                                  type="button"
                                  size="sm"
                                  text={t('common.cancel')}
                                  onClick={() => setShouldShowTestCasesSelect(false)}
                                />
                              </div>
                            )}
                          </div>

                          {shouldShowTestCasesSelect && (
                            <select
                              id="integrationProjectId"
                              name="integrationProjectId"
                              className="block w-full rounded-md border-0 bg-slate-900 pl-3 pr-10 text-white ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                              onChange={(e) => setTestCaseIdToAdd(e.target.value)}
                            >
                              {testCases?.map((tc) => (
                                <option key={tc.id} value={tc.id}>
                                  {tc.title}
                                </option>
                              ))}
                            </select>
                          )}

                          {response?.testCases && response?.testCases?.length > 0 ? (
                            <div className="flex flex-col gap-3">
                              {response?.testCases.map((testCase) => (
                                <LinkArrow
                                  key={testCase.id}
                                  text={testCase.title}
                                  arrowSide="right"
                                  to={`/app/projects/${projectId}/test-cases/${testCase.id}`}
                                  size="base"
                                />
                              ))}
                            </div>
                          ) : (
                            <Typo className="italic" colour="slate-400" size="sm">
                              {t('recordings.details.noLinkedTestCases')}
                            </Typo>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
