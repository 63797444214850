import React from 'react';
import { Typo } from './Typo';
import { Button } from './Button';

export type ErrorComponent = React.ComponentType<{
  error: Error;
  reset: () => void;
}>;

export interface ErrorBoundaryProps {
  errorComponent?: ErrorComponent;
  onError?: (error: Error, errorInfo: React.ErrorInfo) => void;
  children?: React.ReactNode;
}

export interface ErrorBoundaryState {
  error: Error | null;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  override componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    if (this.props.onError) {
      this.props.onError(error, errorInfo);
    }
  }

  reset = () => {
    this.setState({ error: null });
    window.location.reload();
  };

  // Explicit type is needed to avoid the generated `.d.ts` having a wide return type that could be specific the the `@types/react` version.
  override render(): React.ReactNode {
    if (this.state.error) {
      const ErrorComponent = this.props.errorComponent ?? ErrorBoundaryFallback;

      return (
        <>
          <ErrorComponent error={this.state.error} reset={this.reset} />
        </>
      );
    }

    return this.props.children;
  }
}

export const ErrorBoundaryFallback = ({ error, reset }: { error: Error; reset: () => void }) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen gap-8">
      <div className="flex flex-col items-center justify-center gap-2">
        <Typo colour="slate-200" size="xl">
          Something went wrong...
        </Typo>

        <Typo colour="slate-500" size="sm">
          {error.message}
        </Typo>
      </div>

      <Button onClick={reset} text="Reload" style="primary" size="lg" />
    </div>
  );
};
