import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useActiveOrg } from '../propelauth';
import { trpc } from '../trpc';
import { LinkArrow } from './LinkArrow/LinkArrow';
import { Loader } from './Loader/Loader';
import { Typo } from './Typo';

export const SettingsProjectLayout = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();
  const { projectId } = useParams<{ projectId: string }>();
  const activeOrg = useActiveOrg();
  const orgId = activeOrg?.orgId ?? '';

  const projectQuery = trpc.projects.getProject.useQuery(
    { projectId: projectId!, orgId },
    { enabled: !!orgId && !!projectId }
  );
  const { data: project, isLoading: isLoadingProject } = projectQuery;

  return isLoadingProject ? (
    <div className="grid grid-cols-1 min-h-full h-screen justify-items-center items-center">
      <Loader />
    </div>
  ) : (
    <div className="grid grid-cols-1 gap-6 max-w-screen-md mx-auto">
      <div>{children}</div>
    </div>
  );
};
