import { useTranslation } from 'react-i18next';

export const displayCriticality = (criticality: string | null) => {
  const { t } = useTranslation();

  if (!criticality || criticality === '') {
    return null;
  }

  const uppercaseCriticality = criticality.toUpperCase();

  if (uppercaseCriticality === 'MEDIUM') {
    return (
      <span className="inline-flex items-center rounded-md bg-yellow-400/10 px-2 py-1 text-xs font-medium text-yellow-500 ring-1 ring-inset ring-yellow-400/20">
        {t('documents.documentCriticalityMedium')}
      </span>
    );
  } else if (uppercaseCriticality === 'LOW') {
    return (
      <span className="inline-flex items-center rounded-md bg-blue-400/10 px-2 py-1 text-xs font-medium text-blue-400 ring-1 ring-inset ring-blue-400/30">
        {t('documents.documentCriticalityLow')}
      </span>
    );
  }

  return (
    <span className="inline-flex items-center rounded-md bg-red-400/10 px-2 py-1 text-xs font-medium text-red-400 ring-1 ring-inset ring-red-400/20">
      {t('documents.documentCriticalityHigh')}
    </span>
  );
};
