import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

export default function Alert({ children, title }: { title: string; children: React.ReactNode }) {
  return (
    <div className="rounded-md bg-yellow-800 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-100">{title}</h3>
          <div className="mt-2 text-sm text-yellow-500">{children}</div>
        </div>
      </div>
    </div>
  );
}
