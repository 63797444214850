import { API_FETCH_FILE } from '../../constants';

export const getFileUrl = async (fileName: string) => {
  const apiPath = `${API_FETCH_FILE}${fileName}`;

  const myHeaders = new Headers();
  myHeaders.append('Accept', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow' as RequestRedirect,
  };

  const req = await fetch(apiPath, requestOptions);
  const { url } = await req.json();

  return url;
};
