import { BanknotesIcon, CreditCardIcon, DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { env } from '../../config';
import { getDayAndMonth, getRelativeDateTime } from '../../helpers/dates';
import { formatAmountForDisplay, groupInvoiceItemsByDate } from '../../helpers/stripe';
import { useURLQueryParams } from '../../hooks/useURLQueryParams';
import { useActiveOrg, useAuthInfo } from '../propelauth';
import { trpc } from '../trpc';
import Alert from './Alert/Alert';
import { Button } from './Button';
import { Loader } from './Loader/Loader';
import { SectionHeader } from './SectionHeader/SectionHeader';
import { SettingsOrganisationLayout } from './SettingsOrganisationLayout';
import StripePanel from './StripePanel';
import { Typo } from './Typo';
import { useNavigate } from 'react-router-dom';

export function SettingsOrganisationBilling() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useAuthInfo();
  // @ts-ignore
  const { accessHelper } = auth;
  const activeOrg = useActiveOrg();
  const orgId = activeOrg?.orgId ?? '';

  const [isPlanPanelOpen, setIsPlanPanelOpen] = useState(false);

  const urlParams = useURLQueryParams();
  const stripeCheckoutId = urlParams.get('stripe_checkout_id');
  const stripeCreditsCheckoutId = urlParams.get('stripe_credits_checkout_id');

  const canManageBilling = useMemo(() => {
    return !accessHelper ? false : accessHelper.isRole(orgId, 'Owner');
  }, [accessHelper, orgId]);

  const getSubscriptionInfo = trpc.subscriptions.getCheckoutInfo.useQuery(
    {
      orgId,
    },
    { enabled: !!orgId }
  );
  const { data: subscriptionInfo, isLoading } = getSubscriptionInfo;
  const { session: subscription, invoices } = subscriptionInfo ?? {};
  const { past: pastInvoices, upcoming: upcomingInvoice } = invoices ?? {};
  const hasInvoices = (!!pastInvoices && pastInvoices.length > 0) || !!upcomingInvoice;

  if (typeof subscription === 'string') {
    // Stopping here because session.subscription is a string. And that is not expected.
    return null;
  }

  // Find the active plan based on the subscription
  // @ts-ignore
  const activePlan = subscription?.plan?.product?.name;
  // @ts-ignore
  const activePlanInterval = subscription?.plan?.interval;
  const activePlanReadableInterval = activePlanInterval === 'month' ? 'Monthly' : 'Yearly';
  // @ts-ignore
  const subscriptionStatus = subscription?.status;
  const isSubscriptionActive = subscriptionStatus === 'active';
  const willBeCancelled = subscription?.cancel_at_period_end;

  if (!orgId) {
    return null;
  }

  const updateOrganisationMutation = trpc.auth.updateOrganisation.useMutation({
    onSuccess: () => {
      const subscribedFromOnboarding = window.localStorage.getItem('hasCompletedOnboarding') === 'false';

      window.localStorage.setItem('hasCompletedOnboarding', 'true');

      // If user subscribed from the onboarding, redirect to the app's homepage to continue the onboarding process.
      // Otherwise, redirect to the billing page.
      const redirectUrl = subscribedFromOnboarding ? '/app' : '/app/settings/organisation/billing';
      // (We can't use `navigate` here because we need to reload the page to fetch the new org data)
      window.location.replace(redirectUrl);
    },
    onError: () => {
      console.error('Could not update organisation. Please try again later.');
    },
  });

  const createBillingMutation = trpc.subscriptions.createOrUpdateBilling.useMutation({
    onSuccess: () => {
      const updatePayload = {
        orgId,
      };

      updateOrganisationMutation.mutate(updatePayload);
    },
    onError: (error) => {
      console.error('createBillingMutation error:', error);
    },
  });

  const createCreditTopUpMutation = trpc.creditsTopUp.createCreditTopUp.useMutation({
    onSuccess: () => {
      navigate(`/app/settings/organisation/usage`);
    },
    onError: (error) => {
      console.error('createCreditTopUpMutation error:', error);
    },
  });

  useEffect(() => {
    if (!activeOrg?.orgId || !(stripeCheckoutId || stripeCreditsCheckoutId)) {
      return;
    }

    if (stripeCheckoutId) {
      createBillingMutation.mutate({
        orgId: activeOrg.orgId,
        stripeCheckoutId,
      });
    } else if (stripeCreditsCheckoutId) {
      createCreditTopUpMutation.mutate({
        orgId: activeOrg.orgId,
        stripeCheckoutId: stripeCreditsCheckoutId,
      });
    }
  }, [activeOrg?.orgId, stripeCheckoutId, stripeCreditsCheckoutId]);

  if (stripeCheckoutId || stripeCreditsCheckoutId) {
    return (
      <div className="mx-auto max-w-lg">
        <div className="grid grid-cols-1 min-h-full h-screen justify-items-center items-center">
          <Loader text="Processing your payment..." />
        </div>
      </div>
    );
  }

  const handleManageSubscription = () => {
    window.open(env.PUBLIC_STRIPE_SUBSCRIPTION_MGT_URL, '_blank');
  };

  const groupedInvoiceItemsByDate = groupInvoiceItemsByDate(upcomingInvoice?.lines.data);

  return (
    <SettingsOrganisationLayout>
      {isLoading && (
        <div className="grid grid-cols-1 min-h-full h-screen justify-items-center items-center">
          <Loader />
        </div>
      )}

      {!isLoading && !canManageBilling && (
        <Alert title={t('common.forbidden')}>{t('settings.billing.forbiddenAccess')}</Alert>
      )}

      {!isLoading && canManageBilling && (
        <div className={`${hasInvoices ? 'grid grid-cols-1 lg:grid-cols-2 items-start' : ''} gap-6`}>
          <div className={` bg-slate-900 p-4 rounded-lg`}>
            <SectionHeader
              title={t('settings.billing.activeSubscription')}
              component={<CreditCardIcon className="h-5 w-5 text-white" />}
            />

            <div className="grid grid-cols-1 gap-6">
              {!activePlan && (
                <div className="flex flex-col items-start gap-4">
                  <Typo>{t('settings.billing.noSubscription')}</Typo>
                  <Button
                    onClick={() => setIsPlanPanelOpen(true)}
                    text={t('settings.billing.choosePlan')}
                    style="primary"
                  />
                </div>
              )}

              {activePlan && (
                <div className="grid grid-cols-3 items-start gap-6 text-sm">
                  <div className="flex flex-col col-span-2">
                    <div className="flex items-center gap-1.5">
                      <Typo as="span" fw="semibold">
                        {activePlan}
                      </Typo>
                      <Typo colour="slate-500"> • </Typo>
                      <Typo colour="slate-400">{t(`billing.${activePlanReadableInterval.toLowerCase()}`)}</Typo>
                    </div>

                    <div>
                      <Typo size="xs" colour={`${isSubscriptionActive ? 'green-600' : 'red-400'}`}>
                        {isSubscriptionActive ? t('billing.active') : t('billing.inactive')}{' '}
                        {subscriptionStatus === 'canceled' && t('billing.canceled')}
                      </Typo>
                    </div>

                    <div className="flex flex-col items-start gap-2 mt-4">
                      {willBeCancelled && subscription.cancel_at && (
                        <div>
                          <Typo size="sm" colour="red-400">
                            {t('settings.billing.subscriptionWillBePaused', {
                              date: getDayAndMonth(new Date(subscription.cancel_at * 1000)),
                            })}
                          </Typo>
                        </div>
                      )}

                      <div className="flex gap-3">
                        <Button onClick={handleManageSubscription} text={t('settings.billing.manageCTA')} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {hasInvoices && (
            <div className="col-span-1">
              <div className="col-span-2 bg-slate-900 p-4 rounded-lg">
                <SectionHeader
                  title={t('settings.billing.invoicesTitle')}
                  component={<BanknotesIcon className="h-5 w-5 text-white" />}
                />

                <div className="flex flex-col divide-y divide-slate-800">
                  {upcomingInvoice && (
                    <div className="flex flex-col pb-4 gap-4">
                      <div className="flex flex-col gap-1">
                        <Typo fw="semibold">{t('settings.billing.upcoming')}</Typo>
                        {upcomingInvoice.next_payment_attempt && (
                          <Typo size="sm" colour="slate-400">
                            {t('settings.billing.willBeBilledOn')}{' '}
                            <span className="text-slate-300 font-bold">
                              {getDayAndMonth(new Date(upcomingInvoice.next_payment_attempt * 1000))}
                            </span>
                            . {t('settings.billing.mayChange')}
                          </Typo>
                        )}
                      </div>

                      <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-4">
                          {groupedInvoiceItemsByDate.map(({ period, items }) => (
                            <div key={period.start} className="flex flex-col gap-1">
                              <Typo className="uppercase" fw="semibold" size="xs" colour="slate-400">
                                {getDayAndMonth(new Date(period.start * 1000))} -{' '}
                                {getDayAndMonth(new Date(period.end * 1000))}
                              </Typo>
                              {items?.map((line) => (
                                <div key={line.id} className="flex items-center justify-between gap-3 text-sm">
                                  <span className="text-slate-300">{line.description}</span>
                                  <span className="text-slate-300">{formatAmountForDisplay(line.amount)}</span>
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>

                        <Typo as="span" size="sm" className="self-end" fw="semibold">
                          {t('settings.billing.due')} {formatAmountForDisplay(upcomingInvoice.amount_due)}
                        </Typo>
                      </div>
                    </div>
                  )}

                  {pastInvoices && pastInvoices?.length > 0 && (
                    <div className="flex flex-col pt-4">
                      <Typo fw="semibold">{t('settings.billing.pastInvoices')}</Typo>
                      <div className="flex flex-col">
                        {pastInvoices?.map((invoice) => (
                          <div key={invoice.id} className="group flex justify-between items-center gap-4 py-1.5">
                            <div className="flex items-center gap-1.5 text-sm text-slate-400 group-hover:text-slate-200">
                              <span>{invoice.number}</span>
                              <span>•</span>
                              <span className="text-slate-500 group-hover:text-slate-400">
                                {getRelativeDateTime(new Date(invoice.created * 1000))}
                              </span>
                              {invoice.status === 'paid' && (
                                <>
                                  <span>•</span>
                                  <span className="text-slate-500 group-hover:text-slate-400">
                                    {t('settings.billing.paid')} ({formatAmountForDisplay(invoice.amount_paid)})
                                  </span>
                                </>
                              )}
                            </div>
                            <a
                              href={`${invoice.invoice_pdf}`}
                              target="_blank"
                              rel="noreferrer"
                              className="hidden group-hover:flex items-center gap-1.5 text-slate-400 hover:text-slate-100"
                            >
                              <span className="text-sm font-semibold hidden md:inline lg:hidden 2xl:inline">
                                {t('common.download')}
                              </span>
                              <DocumentArrowDownIcon className="w-4 h-4" />
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <StripePanel open={isPlanPanelOpen} setOpen={setIsPlanPanelOpen} />
    </SettingsOrganisationLayout>
  );
}
