import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { Tooltip } from 'react-tooltip';

import { classNames } from '../utils/classNames';

export type DropdownAction = {
  text: string;
  action: () => void;
  icon?: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
      title?: string | undefined;
      titleId?: string | undefined;
    } & React.RefAttributes<SVGSVGElement>
  >;
  danger?: boolean;
  disabled?: boolean;
  tooltip?: string;
  dots?: boolean;
};

type DropdownProps = {
  actions: DropdownAction[];
  text?: string;
  dots?: boolean;
  primary?: boolean;
};

export const Dropdown = ({ text, actions, primary, dots }: DropdownProps) => {
  const colour = primary ? 'bg-indigo-500' : 'bg-slate-800';
  const hoverColour = primary ? 'bg-indigo-600' : 'bg-slate-700';

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        {dots ? (
          <Menu.Button className="flex items-center rounded-full bg-slate-800 text-slate-300 hover:text-white focus:bg-slate-600">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
          </Menu.Button>
        ) : (
          <Menu.Button
            className={`inline-flex w-full justify-center gap-x-1.5 rounded-md ${colour} px-3 py-2 text-sm font-semibold text-white shadow-sm hover:${hoverColour}`}
          >
            {text}
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-slate-100" aria-hidden="true" />
          </Menu.Button>
        )}
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-80 origin-top-right divide-y divide-slate-100 rounded-md bg-slate-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {actions.map((action, index) => {
              const textColour = action.danger ? 'text-red-300' : 'text-white';

              return (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <a
                      onClick={!action.disabled ? action.action : () => {}}
                      href="#"
                      className={classNames(
                        active ? `bg-slate-700 ${textColour}` : `${textColour}`,
                        'block px-4 py-2 text-sm flex items-center gap-2',
                        action.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                      )}
                      data-tooltip-id="dropdown-tooltip"
                      data-tooltip-content={action.disabled ? action.tooltip : ''}
                    >
                      {action.icon && <action.icon className="h-4 w-4" />}
                      {action.text}
                    </a>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>

      <Tooltip
        id="dropdown-tooltip"
        place="left"
        style={{
          backgroundColor: 'rgb(100, 116, 139)',
          color: '#fff',
          maxWidth: '450px',
          zIndex: 9999,
        }}
        opacity={1}
      />
    </Menu>
  );
};
