import { Listbox, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronDownIcon,
  DocumentMagnifyingGlassIcon,
  DocumentTextIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { SparklesIcon } from '@heroicons/react/24/solid';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { HashScroll } from 'react-hash-scroll';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { formatMarkdown } from '../../../helpers';
import { useModals } from '../../../providers/modals';
import { useNotifications } from '../../../providers/notifications';
import { usePrompts } from '../../../providers/prompts';
import { useActiveOrg, useAuthInfo } from '../../propelauth';
import { trpc } from '../../trpc';
import { classNames } from '../../utils/classNames';
import { ActivityFeed } from '../ActivityFeed/ActivityFeed';
import { TestCase } from '../ActivityFeed/TestCase/TestCase';
import { Dropdown, type DropdownAction } from '../Dropdown';
import { InfoSection } from '../InfoSection/InfoSection';
import { Label } from '../Label/Label';
import { SectionHeader } from '../SectionHeader/SectionHeader';
import { Typo } from '../Typo';
import { displaySuggestions } from './ReviewSuggestions';

function DocumentVersionsListDropdown({
  documentVersions,
  selectedDocumentVersion,
  setSelectedDocumentVersion,
}: {
  documentVersions: any[] | undefined;
  setSelectedDocumentVersion: any;
  selectedDocumentVersion?: any;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!documentVersions) {
    return null;
  }

  return (
    <Listbox value={selectedDocumentVersion} onChange={setSelectedDocumentVersion}>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">{t('document.versionsDropdown.label')}</Listbox.Label>
          <div className="relative mr-4">
            <div className="inline-flex divide-x divide-slate-800 rounded-md shadow-sm ">
              <div className="inline-flex items-center gap-x-1.5 rounded-l-md bg-slate-600 px-3 py-2 text-white shadow-sm">
                <p className="text-sm font-semibold">
                  {selectedDocumentVersion?.version
                    ? `v${selectedDocumentVersion?.version}`
                    : t('document.versionsDropdown.all')}
                </p>
              </div>
              <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md bg-slate-600 p-2 hover:bg-slate-500 focus:outline-none focus:ring-1 focus:ring-slate-500 focus:ring-offset-1 focus:ring-offset-slate-500">
                <span className="sr-only">{t('document.versionsDropdown.label')}</span>
                <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
              </Listbox.Button>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-slate-800 overflow-hidden rounded-md bg-slate-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {documentVersions.map((document) => (
                  <Listbox.Option
                    key={document.version}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-slate-600 text-white' : 'text-slate-100',
                        'cursor-pointer select-none p-4 text-sm'
                      )
                    }
                    value={document}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p className={selected ? 'font-semibold' : 'font-normal'}>{document.version}</p>
                          {selected ? (
                            <span className="text-white">
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

export const DocumentDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { sendNotification } = useNotifications();
  const { performAction } = usePrompts();
  const { openModal } = useModals();
  const { documentId, projectId } = useParams<{ documentId: string; projectId: string }>();
  const auth = useAuthInfo();
  // @ts-ignore
  const { user, loading } = auth;
  const orgId = useActiveOrg()?.orgId || '';
  const userId = auth.loading ? '' : auth.user?.userId ?? '';
  const [extendedItems, setExtendedItems] = useState<string[]>(['review', 'testCases']);
  const [language, setLanguage] = useState('');
  const [selectedDocumentVersion, setSelectedDocumentVersion] = useState<any>();
  const [selectedScore, setSelectedScore] = useState<any>();
  const [selectedTestCases, setSelectedTestCases] = useState<any>();

  useEffect(() => {
    setLanguage(user?.metadata?.language || 'English');
  }, [user]);

  const documentQuery = trpc.documents.getDocument.useQuery(
    {
      documentId: documentId!,
      projectId: projectId!,
    },
    {
      enabled: !!documentId && !!projectId,
    }
  );

  const documentDeleteMutation = trpc.documents.deleteDocument.useMutation({
    onSuccess: () => {
      sendNotification({
        id: 'document-deleted-success',
        type: 'success',
        text: t('document.deleteSuccess'),
        subText: t('document.deleteSuccessSubtitle'),
      });
      navigate(`/app/projects/${projectId}/documents`);
    },
    onError: (error) => {
      console.error("Couldn't delete document", error);
    },
  });

  const { data } = documentQuery;
  const { document, testCases, scores, documentVersions } = data || {};

  useEffect(() => {
    // set the corret version and score once we get the documents list
    if (documentVersions) {
      setSelectedDocumentVersion(documentVersions[0]);
    }
  }, [document, documentVersions]);

  if (!document || !document.id || !data) {
    return null;
  }

  useEffect(() => {
    // each time the scores change, we need to update the selected score
    if (scores) {
      setSelectedScore(scores.filter((score: any) => score.documentVersionId === selectedDocumentVersion?.id)[0]);
    }
    if (testCases) {
      setSelectedTestCases(
        testCases.filter((testCase: any) => testCase.documentVersionId === selectedDocumentVersion?.id)
      );
    }
  }, [selectedDocumentVersion, scores, testCases]);

  const colorCSS = !selectedScore
    ? ''
    : selectedScore.score > 60
      ? 'bg-green-900 text-green-300'
      : selectedScore.score > 30
        ? 'bg-yellow-900 text-yellow-300'
        : 'bg-red-900 text-red-300';

  const projectQuery = trpc.projects.getProject.useQuery(
    {
      projectId: document.projectId,
      orgId: document.orgId,
    },
    { enabled: !!document.projectId && !!document.orgId }
  );
  const { data: project } = projectQuery;

  const handleDelete = () => {
    openModal({
      id: 'delete-document',
      text: 'Delete document',
      subText: 'Are you sure you want to delete this document?',
      type: 'danger',
      onConfirm: () => {
        documentDeleteMutation.mutate({ id: document.id, orgId });
      },
    });
  };

  const readableSuggestions = JSON.parse(selectedScore?.reviewSuggestions || '[]');

  const dropdownActions = useMemo(() => {
    const actions: DropdownAction[] = [
      {
        text: t('document.actions.scoreAndReview'),
        action: () =>
          performAction(orgId, userId, 'scoreAndReview', { ...data, document: selectedDocumentVersion, language }),
        icon: SparklesIcon,
        disabled: !!selectedScore,
        tooltip: t('document.actions.scoreAndReviewDisabledTooltip'),
      },
      {
        text: t('document.actions.generateTestCases'),
        action: () =>
          performAction(orgId, userId, 'generateTestCases', { ...data, document: selectedDocumentVersion, language }),
        icon: SparklesIcon,
        disabled: selectedTestCases?.length > 0,
        tooltip: t('document.actions.generateTestCasesDisabledTooltip'),
      },
    ];

    if (project?.integrationNamespace) {
      actions.push({
        text: t('document.actions.openInJira'),
        action: () => {
          window.open(
            `https://${project?.integrationNamespace}.atlassian.net/browse/${selectedDocumentVersion?.key}`,
            '_blank'
          );
        },
        icon: DocumentMagnifyingGlassIcon,
      });
    }

    actions.push({
      text: t('document.actions.delete'),
      action: handleDelete,
      icon: TrashIcon,
      danger: true,
    });

    return actions;
  }, [handleDelete, document, orgId, userId, selectedDocumentVersion]);

  const isCollasped = useCallback(
    (id: string) => {
      return !extendedItems.includes(id);
    },
    [extendedItems]
  );

  const toggleSection = useCallback(
    (id: string) => {
      setExtendedItems((prev) => {
        if (prev.includes(id)) {
          return prev.filter((i) => i !== id);
        } else {
          return [...prev, id];
        }
      });
    },
    [setExtendedItems]
  );

  const toggleTestCasesSection = () => {
    if (!selectedTestCases?.length) return;

    let newItems = [...extendedItems];

    if (areAllTestCasesCollapsed) {
      newItems = [...newItems, ...selectedTestCases.map((testCase: any) => testCase.id)];
    } else {
      newItems = newItems.filter((item) => !selectedTestCases.some((testCase: any) => testCase.id === item));
    }

    setExtendedItems(newItems);
  };

  const sortTestsByCriticalityAndTitle = (tests: any) => {
    const priorityMap: { [key: string]: number } = {
      High: 1,
      Medium: 2,
      Low: 3,
    };

    return tests?.sort((a: any, b: any) => {
      const criticalityComparison = (priorityMap[a?.criticality] || 0) - (priorityMap[b?.criticality] || 0);
      if (criticalityComparison !== 0) {
        return criticalityComparison;
      }
      return a.title.localeCompare(b.title);
    });
  };

  const sortedTestCases = useMemo(() => {
    return sortTestsByCriticalityAndTitle(selectedTestCases);
  }, [selectedTestCases]);

  const areAllTestCasesCollapsed = useMemo(
    () => selectedTestCases?.every((testCase: any) => isCollasped(testCase.id)),
    [selectedTestCases, extendedItems, isCollasped]
  );

  return (
    <div className="grid grid-cols-1 gap-6">
      <div className="grid grid-cols-1 gap-4">
        <div className="grid grid-cols-1 gap-2">
          <div className="flex flex-col gap-3">
            <div className="flex items-start justify-between gap-x-10">
              <div className="flex items-start gap-x-4 text-base font-semibold leading-2 text-white">
                <div className="flex items-center gap-4">
                  {project?.integrationNamespace ? (
                    <a
                      href={`https://${project?.integrationNamespace}.atlassian.net/browse/${selectedDocumentVersion?.key}`}
                      target="_blank"
                      rel="noreferrer"
                      className="shrink-0 text-gray-400 text-xl"
                    >
                      {selectedDocumentVersion?.key}
                    </a>
                  ) : (
                    <Typo size="xl" colour="gray-400" className="shrink-0">
                      {selectedDocumentVersion?.key}
                    </Typo>
                  )}
                </div>
                <p className="text-white text-xl">{selectedDocumentVersion?.title}</p>
              </div>
              <div className="grid grid-cols-2">
                <DocumentVersionsListDropdown
                  documentVersions={documentVersions}
                  selectedDocumentVersion={selectedDocumentVersion}
                  setSelectedDocumentVersion={setSelectedDocumentVersion}
                />
                <Dropdown text={t('common.actions')} actions={dropdownActions} primary />
              </div>
            </div>

            <div>
              <p className="text-slate-400 font-semibold">
                <a href="#score">{t('document.header.score', { count: selectedScore?.score ?? 0 })}</a> •{' '}
                <a href="#test-cases">{t('document.header.testCases', { count: selectedTestCases?.length })}</a>
              </p>
            </div>
          </div>
        </div>

        {selectedDocumentVersion?.fileName && selectedDocumentVersion?.filePath && (
          <div className="text-gray-300 text-sm">
            <a
              href={selectedDocumentVersion.filePath}
              target="_blank"
              rel="noreferrer"
              className="bg-slate-800 hover:bg-slate-700 rounded-lg flex flex-row items-center gap-x-2 p-2 pr-3 w-max"
            >
              <DocumentTextIcon className="h-6 w-6 text-white" />
              <span className="text-white font-bold text-sm">{selectedDocumentVersion?.fileName}</span>
            </a>
          </div>
        )}
      </div>

      <div className={`grid grid-cols-4 gap-12 ${isCollasped('description') ? '' : 'mb-12'}`}>
        <div className="col-span-3">
          <SectionHeader
            title={t('document.sectionHeaders.description')}
            collapsible
            collapsed={isCollasped('description')}
            onCollapse={() => toggleSection('description')}
          />
          {!isCollasped('description') && (
            <div
              className="description-content text-gray-300 text-sm leading-loose pl-4"
              dangerouslySetInnerHTML={{
                __html: formatMarkdown(selectedDocumentVersion?.description || ''),
              }}
            />
          )}
        </div>

        {/* <div>
          <SectionHeader title="Fields" />
          <div className="text-gray-300 text-sm">Fields go there</div>
        </div> */}
      </div>

      <HashScroll hash="#score">
        <div className={`${isCollasped('review') ? '' : 'mb-12'}`}>
          <SectionHeader
            title={t('document.sectionHeaders.scoreAndReview')}
            component={
              selectedScore?.score ? (
                <Label text={`${selectedScore?.score.toString()} / 100`} colorCSS={colorCSS} />
              ) : null
            }
            collapsible
            collapsed={isCollasped('review')}
            onCollapse={() => toggleSection('review')}
          />
          {!isCollasped('review') && (
            <div className="text-gray-300 text-sm pl-4">
              <div className="gap-6">
                {readableSuggestions.length === 0 && !readableSuggestions.missingElements && (
                  <div className="flex flex-col items-start gap-3">
                    <InfoSection
                      text={t('document.scoreEmptyState.text')}
                      actionText={t('document.scoreEmptyState.CTA')}
                      action={() =>
                        performAction(orgId, userId, 'scoreAndReview', {
                          ...data,
                          document: selectedDocumentVersion,
                          language,
                        })
                      }
                    />
                  </div>
                )}

                {(!!readableSuggestions.length || !!readableSuggestions.missingElements) &&
                  displaySuggestions(readableSuggestions)}
              </div>
            </div>
          )}
        </div>
      </HashScroll>

      <HashScroll hash="#test-cases">
        <div className={`${isCollasped('testCases') ? '' : 'mb-12'}`}>
          <SectionHeader
            title={t('document.sectionHeaders.testCases')}
            collapsible
            collapsed={isCollasped('testCases')}
            onCollapse={() => toggleSection('testCases')}
            action={
              selectedTestCases?.length ? (
                <button onClick={toggleTestCasesSection} className="text-sm font-semibold text-slate-400">
                  {areAllTestCasesCollapsed ? t('common.expand') : t('common.collapse')}
                </button>
              ) : null
            }
            actionPosition="left"
          />
          {!isCollasped('testCases') &&
            (selectedTestCases?.length ? (
              <div className="grid grid-cols-1 gap-10 pl-4">
                {sortedTestCases.map((testCaseItem: any) => {
                  const automatedTest = data?.automatedTests?.find((aTest) => aTest.testCaseId === testCaseItem.id);

                  return (
                    <TestCase
                      key={testCaseItem.id}
                      test={testCaseItem}
                      automatedTest={automatedTest}
                      isCollasped={isCollasped(testCaseItem.id)}
                      toggle={toggleSection}
                    />
                  );
                })}
              </div>
            ) : (
              <div className="flex pl-4">
                <InfoSection
                  text={t('document.testsEmptyState.text')}
                  actionText={t('document.testsEmptyState.CTA')}
                  action={() =>
                    performAction(orgId, userId, 'generateTestCases', {
                      ...data,
                      document: selectedDocumentVersion,
                      language,
                    })
                  }
                />
              </div>
            ))}
        </div>
      </HashScroll>

      <div>
        <SectionHeader
          title={t('document.sectionHeaders.history')}
          collapsible
          collapsed={isCollasped('history')}
          onCollapse={() => toggleSection('history')}
        />
        {!isCollasped('history') && (
          <div className="pl-4">
            <ActivityFeed />
          </div>
        )}
      </div>

      <Tooltip
        id="suggestion-label-tooltip"
        place="bottom-start"
        style={{ backgroundColor: 'rgb(100, 116, 139)', color: '#fff', maxWidth: '450px' }}
        opacity={1}
      />
    </div>
  );
};
