import {
  AdjustmentsVerticalIcon,
  ArrowLeftEndOnRectangleIcon,
  ChartBarSquareIcon,
  Cog6ToothIcon,
  CreditCardIcon,
  EyeIcon,
  FolderIcon,
  InformationCircleIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';

import { useActiveOrg, useAuthInfo, useLogoutFunction } from '../../propelauth';
import { Typo } from '../Typo';

export const MainNavigation = () => {
  const { t } = useTranslation();
  const auth = useAuthInfo();
  const navigate = useNavigate();
  // @ts-ignore
  const { user } = auth;
  const activeOrg = useActiveOrg();

  const logout = useLogoutFunction();

  const isOnSettingsView = useMatch({ path: '/app/settings', end: false });

  const items = [
    {
      title: t('menu.overview'),
      href: '/app',
      icon: EyeIcon,
      current: useMatch({ path: '/app', end: true }),
    },
    {
      title: t('menu.projects'),
      href: '/app/projects',
      icon: FolderIcon,
      current: useMatch({ path: '/app/projects', end: false }),
    },
    {
      title: t('menu.settings'),
      href: `/app/settings/organisation`,
      icon: AdjustmentsVerticalIcon,
      current: false,
      subItems: isOnSettingsView
        ? [
            {
              title: t('menu.settingsGeneral'),
              href: '/app/settings/organisation',
              icon: InformationCircleIcon,
              current: useMatch({ path: '/app/settings/organisation', end: true }),
            },
            {
              title: t('menu.settingsTeam'),
              href: '/app/settings/organisation/team',
              icon: UsersIcon,
              current: useMatch({ path: '/app/settings/organisation/team', end: true }),
            },
            {
              title: t('menu.settingsBilling'),
              href: '/app/settings/organisation/billing',
              icon: CreditCardIcon,
              current: useMatch({ path: '/app/settings/organisation/billing', end: true }),
            },
            {
              title: t('menu.settingsUsage'),
              href: '/app/settings/organisation/usage',
              icon: ChartBarSquareIcon,
              current: useMatch({ path: '/app/settings/organisation/usage', end: true }),
            },
          ]
        : [],
    },
  ];

  return (
    <nav className="flex flex-1 flex-col">
      <div className="mb-6">
        <Typo size="lg">{activeOrg?.orgName ?? 'Organisation'}</Typo>
      </div>

      <ul role="list" className="flex flex-1 flex-col gap-y-7">
        <li className="-mx-6">
          {items.map((item) => (
            <div key={item.href} className={`flex flex-col w-full`}>
              <a href={item.href}>
                <div
                  className={`group flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 hover:text-white cursor-pointer ${item.current ? 'bg-gray-900 text-slate-100' : 'text-slate-300'}`}
                >
                  <item.icon
                    className={`h-6 w-6 shrink-0 ${item.current ? 'text-slate-100' : 'text-slate-400'} group-hover:text-white`}
                    aria-hidden="true"
                  />
                  <span className="sr-only">{item.title}</span>
                  <span aria-hidden="true">{item.title}</span>
                </div>
              </a>

              {item.subItems && item.subItems.length > 0 && (
                <ul>
                  {item.subItems.map((subItem) => (
                    <li key={subItem.href}>
                      <a
                        href={subItem.href}
                        className={`group flex items-center gap-x-3 px-14 py-2 text-sm font-semibold leading-6 hover:text-white cursor-pointer ${subItem.current ? 'bg-gray-900 text-slate-100' : 'text-slate-300'}`}
                      >
                        <subItem.icon
                          className={`h-5 w-5 shrink-0 ${subItem.current ? 'text-slate-100' : 'text-slate-400'} group-hover:text-white`}
                          aria-hidden="true"
                        />
                        <span className="sr-only">{subItem.title}</span>
                        <span aria-hidden="true">{subItem.title}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </li>

        <li className="-mx-6 mt-auto">
          <a
            onClick={() => navigate('/app/settings/account')}
            className={`group flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 hover:text-white cursor-pointer ${useMatch({ path: '/app/settings/account', end: true }) ? 'bg-gray-900 text-slate-100' : 'text-slate-300'}`}
          >
            <Cog6ToothIcon
              className={`h-6 w-6 shrink-0 ${useMatch({ path: '/app/settings/account', end: true }) ? 'text-slate-100' : 'text-slate-400'} group-hover:text-white`}
              aria-hidden="true"
            />
            <span className="sr-only">{t('menu.personalPreferences')}</span>
            <span aria-hidden="true">{t('menu.personalPreferences')}</span>
          </a>
          <a
            onClick={() => logout(true)}
            className="group flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-slate-300 hover:text-white cursor-pointer"
          >
            <ArrowLeftEndOnRectangleIcon
              className="h-6 w-6 shrink-0 text-slate-400 group-hover:text-white"
              aria-hidden="true"
            />
            <span className="sr-only">{t('menu.logout')}</span>
            <span aria-hidden="true">{t('menu.logout')}</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};
