import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import { DOC_COUNT_PER_PAGE } from '../../../constants';

export const Pagination = ({
  goToPage,
  currentPage,
  pageTotal,
  totalItem,
}: {
  goToPage: any;
  currentPage: number;
  pageTotal: number;
  totalItem: number;
}) => {
  const { t } = useTranslation();

  const pageArray = [];
  if (currentPage > 2) pageArray.push(currentPage - 2);
  if (currentPage > 1) pageArray.push(currentPage - 1);
  pageArray.push(currentPage);
  if (pageTotal - currentPage > 0) pageArray.push(currentPage + 1);
  if (pageTotal - currentPage > 1) pageArray.push(currentPage + 2);

  const itemClassname = `cursor-pointer relative inline-flex items-center px-4 py-2 text-sm font-semibold text-slate-200 ring-1 ring-inset ring-slate-700 focus:z-20 focus:outline-offset-0 hover:text-white`;

  const firstItem = (currentPage - 1) * DOC_COUNT_PER_PAGE + 1;
  const lastItem = Math.min(currentPage * DOC_COUNT_PER_PAGE, totalItem);

  const goToPageFn = (targetedPage: number) => {
    const page = targetedPage === 0 ? 1 : targetedPage > pageTotal ? pageTotal : targetedPage;
    goToPage(page);
  };

  return (
    <div className="flex items-center justify-between py-3">
      <div className="flex flex-1 justify-between sm:hidden">
        <a
          onClick={() => goToPageFn(currentPage - 1)}
          className="cursor-pointer relative inline-flex items-center rounded-md border border-slate-300 bg-slate-200 px-4 py-2 text-sm font-medium text-white hover:bg-slate-600 hover:text-white"
        >
          {t('pagination.previous')}
        </a>
        <a
          onClick={() => goToPageFn(currentPage + 1)}
          className="cursor-pointer relative ml-3 inline-flex items-center rounded-md border border-slate-300 bg-slate-200 px-4 py-2 text-sm font-medium text-white hover:bg-slate-600 hover:text-white"
        >
          {t('pagination.next')}
        </a>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-slate-400">
            {t('pagination.showing', { from: firstItem, to: lastItem, total: totalItem })}
          </p>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <a
              onClick={() => goToPageFn(currentPage - 1)}
              className="cursor-pointer relative inline-flex items-center rounded-l-md px-2 py-2 text-white ring-1 ring-inset ring-slate-700 bg-slate-900 hover:bg-slate-800 hover:text-white focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">{t('pagination.previous')}</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </a>

            {pageArray.map((page) => {
              return (
                <a
                  key={page}
                  onClick={() => goToPage(page)}
                  className={
                    page === currentPage
                      ? itemClassname + ' bg-slate-700 hover:bg-slate-700 text-white'
                      : itemClassname + ' bg-slate-900 hover:bg-slate-800'
                  }
                >
                  {page}
                </a>
              );
            })}

            <a
              onClick={() => goToPageFn(currentPage + 1)}
              className="cursor-pointer relative inline-flex items-center rounded-r-md px-2 py-2 text-white ring-1 ring-inset ring-slate-700 bg-slate-900 hover:bg-slate-800 hover:text-white focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">{t('pagination.next')}</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
};
