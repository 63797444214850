import { Dialog, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useState } from 'react';
import { I18nextProvider } from 'react-i18next';

import { BillingGuardProvider } from '../../providers/billingGuard';
import { ModalsProvider } from '../../providers/modals';
import { NotificationsProvider } from '../../providers/notifications';
import { PromptsProvider } from '../../providers/prompts';
import { useAuthInfo } from '../propelauth';
import i18n from './i18n';
import { Loader } from './Loader/Loader';
import { Logo } from './Logo/Logo';
import { Modals } from './Modals';
import Breadcrumb from './Navigation/Breadcrumb';
import { Navigation } from './Navigation/Navigation';
import Notifications from './Notifications';
import PromptSidePanel from './PromptSidePanel';
import RecordingSidePanel from './RecordingSidePanel';
import { ErrorBoundary } from './ErrorBoundary';

export const Layout = (props: { title?: string; children: React.ReactNode }) => {
  const auth = useAuthInfo();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // set language based on user's metadata, if any
  // @ts-ignore
  const { user } = auth;
  const language = user?.metadata?.language || 'English';
  const locale = language === 'Francais' ? 'fr' : 'en';
  i18n.changeLanguage(locale);

  return (
    <I18nextProvider i18n={i18n}>
      <ErrorBoundary>
        <BillingGuardProvider>
          <NotificationsProvider>
            <ModalsProvider>
              <PromptsProvider>
                {auth.loading === true ? (
                  <div className="grid grid-cols-1 min-h-full h-screen justify-items-center items-center">
                    <Loader />
                  </div>
                ) : (
                  <div>
                    <Transition.Root show={sidebarOpen} as={Fragment}>
                      <Dialog as="div" className="relative z-50 xl:hidden" onClose={setSidebarOpen}>
                        <Transition.Child
                          as={Fragment}
                          enter="transition-opacity ease-linear duration-300"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition-opacity ease-linear duration-300"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="fixed inset-0 bg-gray-900/80" />
                        </Transition.Child>

                        <div className="fixed inset-0 flex">
                          <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                          >
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                              <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                  <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                                    <span className="sr-only">Close sidebar</span>
                                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                  </button>
                                </div>
                              </Transition.Child>
                              {/* Sidebar component, swap this element with another sidebar if you like */}
                              <div className="flex grow flex-col gap-y-4 overflow-y-auto bg-gray-900 px-6 ring-1 ring-white/10">
                                <div className="flex h-8 w-8 mt-4 items-center">
                                  <Logo className="h-8" />
                                </div>

                                <Navigation />
                              </div>
                            </Dialog.Panel>
                          </Transition.Child>
                        </div>
                      </Dialog>
                    </Transition.Root>

                    {/* Static sidebar for desktop */}
                    <div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col">
                      {/* Sidebar component, swap this element with another sidebar if you like */}
                      <div className="flex grow flex-col gap-y-4 overflow-y-auto bg-black/10 px-6 ring-1 ring-white/5">
                        <a href="/app" className="flex h-8 w-8 mt-4 items-center">
                          <Logo className="h-8" />
                        </a>

                        <Navigation />
                      </div>
                    </div>

                    <div className="xl:pl-72">
                      {/* <BillingGuardBanner /> */}

                      {/* Sticky search header */}
                      <div className="sticky top-0 z-20 flex h-12 shrink-0 items-center gap-x-6 border-b border-white/5 pr-5 shadow-sm">
                        <button
                          type="button"
                          className="pl-5 text-white xl:hidden"
                          onClick={() => setSidebarOpen(true)}
                        >
                          <span className="sr-only">Open sidebar</span>
                          <Bars3Icon className="h-5 w-5" aria-hidden="true" />
                        </button>

                        <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 bg-slate-950">
                          <Breadcrumb />
                        </div>
                      </div>

                      <main>
                        <div className="px-4 py-4 sm:px-6 sm:py-6 lg:px-8">{props.children}</div>

                        <Notifications />
                        <Modals />
                        <PromptSidePanel />
                        <RecordingSidePanel />
                      </main>
                    </div>
                  </div>
                )}
              </PromptsProvider>
            </ModalsProvider>
          </NotificationsProvider>
        </BillingGuardProvider>
      </ErrorBoundary>
    </I18nextProvider>
  );
};
