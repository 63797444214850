import { useParams } from 'react-router-dom';

import { useActiveOrg } from '../propelauth';
import { trpc } from '../trpc';
import { Layout } from './Layout';
import { Loader } from './Loader/Loader';
import { TestCaseDetails } from './TestCaseDetails/TestCaseDetails';

export const TestCaseDetailsPage = () => {
  const { testCaseId, projectId } = useParams<{ testCaseId: string; projectId: string }>();
  const activeOrg = useActiveOrg();
  const orgId = activeOrg?.orgId ?? '';

  const projectQuery = trpc.projects.getProject.useQuery(
    {
      projectId: projectId!,
      orgId,
    },
    { enabled: !!orgId && !!projectId }
  );
  const { data: project } = projectQuery;

  const testCaseQuery = trpc.testCases.getTestCase.useQuery(
    {
      testCaseId: testCaseId!,
      projectId: projectId!,
      orgId,
    },
    { enabled: !!orgId && !!projectId && !!testCaseId }
  );
  const { data, isLoading } = testCaseQuery;

  return (
    <Layout title={`${project?.name} / Test Case / ${data?.testCase?.title}`}>
      <div>
        {isLoading ? (
          <div className="grid grid-cols-1 min-h-full h-screen justify-items-center items-center">
            <Loader />
          </div>
        ) : (
          <TestCaseDetails />
        )}
      </div>
    </Layout>
  );
};
