import { useParams } from 'react-router-dom';

import { useActiveOrg } from '../propelauth';
import { trpc } from '../trpc';
import { Layout } from './Layout';
import WelcomeScreen from './Project/WelcomeScreen/WelcomeScreen';

export function Project() {
  const { projectId } = useParams<{ projectId: string }>();
  const activeOrg = useActiveOrg();
  const orgId = activeOrg?.orgId ?? '';

  const projectQuery = trpc.projects.getProject.useQuery(
    {
      projectId: projectId!,
      orgId,
    },
    {
      enabled: !!orgId && !!projectId,
      staleTime: 1000,
      retry: (retry, error) => {
        return retry < 3 && !error.data?.code;
      },
    }
  );
  const project = projectQuery.data;

  return (
    <Layout title={`Projects / ${project?.name}`}>
      <WelcomeScreen />
    </Layout>
  );
}
