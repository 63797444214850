import { useForm } from 'react-hook-form';

import { useNotifications } from '../../../providers/notifications';
import { trpc } from '../../trpc';
import { Button } from '../Button';
import { isJsonString } from '../helpers/json';
import { Logo } from '../Logo/Logo';
import OnboardingSteps from '../OnboardingSteps/OnboardingSteps';
import { Typo } from '../Typo';
import { useNavigate } from 'react-router-dom';

export const OnboardingSignUp = () => {
  const navigate = useNavigate();
  const { sendNotification } = useNotifications();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const signUpMutation = trpc.auth.signUp.useMutation({
    onSuccess: () => {
      navigate('/app/signup/confirm');
    },
    onError: (error) => {
      const { message } = error;
      const parsedMessage = isJsonString(message) ? JSON.parse(message) : {};

      if (parsedMessage.email) {
        setError('email', { type: 'server', message: parsedMessage.email });
      } else if (parsedMessage.name) {
        setError('orgName', { type: 'server', message: parsedMessage.name });
      } else {
        // @ts-ignore
        sendNotification({
          id: 'signup-error',
          type: 'error',
          text: message,
          autoDismiss: true,
        });
      }
    },
  });

  const onSubmit = (data: any) => {
    signUpMutation.mutate(data);
  };

  return (
    <div className="grid grid-cols-1 min-h-full h-full justify-items-center items-center">
      <div className="flex flex-col items-center gap-20">
        <div className="flex flex-col items-center gap-10">
          <div className="flex flex-col items-center gap-4">
            <Logo className="h-8" />

            <div className="flex flex-col items-center gap-2">
              <Typo size="lg">Welcome to OpenTestCase!</Typo>
            </div>
          </div>

          <OnboardingSteps step={1} />
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="flex h-full flex-col gap-6 w-full">
          <div className="flex flex-col gap-6 bg-slate-900 rounded-lg p-6">
            <div className="flex flex-col gap-4">
              <div className="grid grid-cols-2 gap-4">
                <div className="flex flex-col gap-1.5">
                  <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-white">
                    First name
                  </label>
                  <input
                    {...register('firstName', { required: true })}
                    type="text"
                    placeholder="John"
                    className="block w-full rounded-md border-0 bg-slate-900 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                  {errors.firstName && (
                    <>
                      {errors.firstName.type === 'required' && (
                        <Typo size="xs" colour="red-400">
                          First name is required
                        </Typo>
                      )}
                    </>
                  )}
                </div>

                <div className="flex flex-col gap-1.5">
                  <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-white">
                    Last name
                  </label>
                  <input
                    {...register('lastName', { required: true })}
                    type="text"
                    placeholder="Doe"
                    className="block w-full rounded-md border-0 bg-slate-900 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                  {errors.lastName && (
                    <>
                      {errors.lastName.type === 'required' && (
                        <Typo size="xs" colour="red-400">
                          Last name is required
                        </Typo>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="flex flex-col gap-1.5">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                  Email
                </label>
                <input
                  {...register('email', { required: true })}
                  type="email"
                  placeholder="john.doe@doecorp.com"
                  className="block w-full rounded-md border-0 bg-slate-900 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
                {errors.email && (
                  <>
                    {errors.email.type === 'required' && (
                      <Typo size="xs" colour="red-400">
                        Email is required
                      </Typo>
                    )}
                    {errors.email.type === 'server' && (
                      <Typo size="xs" colour="red-400">
                        {errors.email.message?.toString()}
                      </Typo>
                    )}
                  </>
                )}
              </div>

              <div className="flex flex-col gap-1.5">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-white">
                  Password
                </label>
                <input
                  {...register('password', { required: true, maxLength: 64, minLength: 8 })}
                  type="password"
                  name="password"
                  id="password"
                  className="block w-full rounded-md border-0 bg-slate-900 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  placeholder="Not your cat's name!"
                />
                {errors.password && (
                  <>
                    {errors.password.type === 'minLength' && (
                      <Typo size="xs" colour="red-400">
                        Password must be at least 8 characters
                      </Typo>
                    )}
                    {errors.password.type === 'required' && (
                      <Typo size="xs" colour="red-400">
                        Password is required
                      </Typo>
                    )}
                  </>
                )}
              </div>

              <div className="flex flex-col gap-1.5 pt-6 mt-3 border-t border-slate-800">
                <label htmlFor="orgName" className="block text-sm font-medium leading-6 text-white">
                  Organisation Name
                </label>
                <input
                  {...register('orgName', { required: true })}
                  id="orgName"
                  className="block w-full rounded-md border-0 bg-slate-900 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  placeholder="Doe Corp"
                />
                {errors.orgName && (
                  <>
                    {errors.orgName.type === 'required' && (
                      <Typo size="xs" colour="red-400">
                        Organisation name is required
                      </Typo>
                    )}
                    {errors.orgName.type === 'server' && (
                      <Typo size="xs" colour="red-400">
                        {errors.orgName.message?.toString()}
                      </Typo>
                    )}
                  </>
                )}
              </div>

              <Button
                type="submit"
                text="Create account"
                style="primary"
                size="lg"
                className="mt-5"
                onClick={() => {}}
                loading={signUpMutation.isLoading}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
