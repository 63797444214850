import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { displayCriticality } from '../../../../helpers';
import { LinkArrow } from '../../LinkArrow/LinkArrow';
import Toggle from '../../Toggle/Toggle';
import { Typo } from '../../Typo';

export const TestCase: React.FC<{
  test: any;
  automatedTest?: any | undefined;
  isCollasped?: boolean;
  toggle?: (id: string) => void;
  noHeader?: boolean;
}> = ({ test, isCollasped = false, toggle = () => {}, automatedTest, noHeader }) => {
  const { t } = useTranslation();
  const { projectId } = useParams<{ projectId: string }>();
  const [toggleEnabled, setToggleEnabled] = useState(false);

  const handleToggleChange = (enabled: boolean) => {
    setToggleEnabled(enabled);
  };

  const stepsToDisplay = toggleEnabled ? test.lowLevelSteps : test.steps;
  const hasLowLevelSteps = !!test.lowLevelSteps;

  return (
    <div key={`${test.id}-${test.title}`} className="">
      {!noHeader && (
        <div className="flex justify-between items-center mb-2 gap-6">
          <div className="flex items-center gap-3">
            <button
              onClick={() => toggle(test.id)}
              className="rounded bg-white/10 px-1 py-1 text-xs font-semibold text-white shadow-sm hover:bg-white/20"
            >
              <ChevronDownIcon className={`text-white h-5 w-5 ${!isCollasped ? 'rotate-180' : ''}`} />
            </button>
            <div className="flex flex-col items-start">
              <div className="flex items-center gap-3">
                <span onClick={() => toggle(test.id)} className="text-white font-bold cursor-pointer">
                  {test.title}
                </span>

                <LinkArrow text={t('common.view')} to={`/app/projects/${projectId}/test-cases/${test.id}`} fw="bold" />
              </div>
              <a
                href="#"
                onClick={() => toggle(test.id)}
                className="inline-flex items-center gap-1 text-slate-500 text-xs"
              >
                {displayCriticality(test.criticality)}
                <span>•</span>
                {automatedTest && <span className="inline-flex items-center">{t('documents.automated')}</span>}
                {!automatedTest && (
                  <span className="inline-flex items-center gap-1">{t('documents.notAutomated')}</span>
                )}
                <span>•</span>
                {test.flowId && (
                  <>
                    <span className="inline-flex items-center gap-1">{t('documents.linkedToFlow')}</span>
                    <span>•</span>
                  </>
                )}
                <span>{t('documents.stepsCount', { count: stepsToDisplay.length })}</span>
              </a>
            </div>
          </div>
        </div>
      )}

      {!isCollasped && (
        <>
          {hasLowLevelSteps && (
            <div className="mb-5">
              <Toggle
                title={toggleEnabled ? t('testCase.toggleConcrete') : t('testCase.toggleAbstract')}
                onChange={handleToggleChange}
              />
            </div>
          )}
          <div className="border border-slate-700 rounded-lg">
            <div className="mb-6">
              <div className="pt-2 px-4 rounded-t-lg mb-2">
                <span className="text-sm text-slate-500 font-bold uppercase">{t('documents.prerequisites')}</span>
              </div>
              <pre className="language-text text-sm text-white overflow-x-auto bg-slate-950 px-4">
                <code className="language-text">
                  {test.prerequisites.map((pre: any) => (
                    <Typo
                      key={`${test.id}-${test.title}-${pre.id}-${pre.action}`}
                      as="span"
                      size="sm"
                      colour="slate-200"
                      className="font-mono leading-tight whitespace-nowrap"
                    >
                      {pre.action}
                    </Typo>
                  ))}
                </code>
              </pre>
            </div>
            <div className="grid grid-cols-2 pt-2 px-4">
              <span className="text-sm text-slate-500 font-bold uppercase">{t('documents.steps')}</span>
              <span className="text-sm text-slate-500 font-bold uppercase ml-2">{t('documents.expectedResults')}</span>
            </div>
            <div className="language-text text-sm text-white overflow-x-auto rounded-b-lg">
              {stepsToDisplay.map((step: any, index: number) => (
                <div
                  key={`${step.id}-${step.action}-${step.expectedResult}`}
                  className={`grid grid-cols-2 gap-4 px-4 py-2.5 ${index % 2 === 0 ? 'bg-slate-950' : 'bg-white/[.03]'}`}
                >
                  <Typo as="span" size="sm" colour="slate-200" className="font-mono leading-tight">
                    {step.action}
                  </Typo>
                  <Typo as="span" size="sm" colour="slate-200" className="font-mono leading-tight">
                    {step.expectedResult}
                  </Typo>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
