import ReactDiffViewer from 'react-diff-viewer';
import { useTranslation } from 'react-i18next';

export const JiraUpdate = ({
  item,
  previousItem,
}: {
  item: { key: string; value: string; previousValue: string };
  previousItem: any;
}) => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-1 gap-3">
      <p className="text-sm font-semibold text-white">
        <span className="font-bold">{item.key.charAt(0).toUpperCase() + item.key.slice(1).replace(/-/g, ' ')}</span>{' '}
        {t('documents.jiraUpdateSuccess')}
      </p>

      <ReactDiffViewer
        oldValue={item.previousValue}
        newValue={item.value}
        splitView={true}
        useDarkTheme={true}
        hideLineNumbers={true}
        styles={{
          line: {
            fontSize: '0.8rem',
            background: 'rgb(15, 23, 42)',
          },
          variables: {
            dark: {
              diffViewerColor: 'rgb(148, 163, 184)',
              addedColor: 'rgb(187, 247, 208)',
              removedColor: 'rgb(254, 202, 202)',
              addedBackground: 'rgb(15, 23, 42)',
              removedBackground: 'rgb(15, 23, 42)',
              codeFoldBackground: 'rgb(15, 23, 42)',
            },
          },
        }}
      />
    </div>
  );
};
