import { websiteTitle } from '../../constants';
import { Layout } from './Layout';
import { CreateProject } from './Projects/CreateProject/CreateProject';

export function ProjectCreate() {
  return (
    <Layout title={`${websiteTitle} / Projects / Create`}>
      <CreateProject />
    </Layout>
  );
}
