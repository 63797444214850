import { FolderIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import { getQueryKey } from '@trpc/react-query';
import { useMemo, useState } from 'react';

import { getProjects } from '../../../../api';
import { useActiveOrg, useRequireActiveOrg } from '../../../propelauth';
import { trpc } from '../../../trpc';
import { Button } from '../../Button';
import { SectionHeader } from '../../SectionHeader/SectionHeader';
import { useNavigate } from 'react-router-dom';

export const CreateProject = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [isLoadingJiraProjects, setIsLoadingJiraProjects] = useState(false);
  const [name, setName] = useState('');
  const { auth } = useRequireActiveOrg();
  const [integration, setIntegration] = useState('No integration');
  const [integrationNamespace, setIntegrationNamespace] = useState('');
  const [integrationEmail, setIntegrationEmail] = useState(!auth.loading ? auth.user?.email || '' : '');
  const [integrationAPIKey, setIntegrationAPIKey] = useState('');
  const [integrationProjects, setIntegrationProjects] = useState<any[]>([]);
  const [integrationProjectId, setIntegrationProjectId] = useState('');

  const queryClient = useQueryClient();
  const activeOrg = useActiveOrg();
  const orgId = activeOrg?.orgId;

  const canCreate = useMemo(() => {
    console.log('integration:', integration);
    if (integration === 'No integration') {
      return name !== '';
    } else if (integration === 'Jira') {
      return (
        name !== '' &&
        integrationNamespace !== '' &&
        integrationEmail !== '' &&
        integrationAPIKey !== '' &&
        integrationProjectId !== ''
      );
    }

    return false;
  }, [name, integration, integrationNamespace, integrationEmail, integrationAPIKey, integrationProjectId]);

  const token = useMemo(() => {
    const creds = integrationEmail + ':' + integrationAPIKey;
    return btoa(creds);
  }, [integrationEmail, integrationAPIKey]);

  const addProjectMutation = trpc.projects.createProject.useMutation({
    onSettled: () => {
      queryClient.invalidateQueries(getQueryKey(trpc.projects.getProjects));
    },
    onSuccess: (id) => {
      navigate(`/app/projects/${id}`);
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    console.log('Creating project...');

    addProjectMutation.mutate({
      orgId: orgId!,
      name,
      integration: integration === 'No integration' ? undefined : integration,
      integrationNamespace: integrationNamespace.toLowerCase(),
      integrationProjectId,
      integrationAPIKey,
      integrationEmail,
      integrationToken: token,
    });
  };

  const handleFetchProjectsFromIntegration = async () => {
    const projects: any[] | undefined = await getProjects({
      jiraDomain: integrationNamespace,
      token,
    });

    const mappedProjects =
      projects?.map((p: any) => ({
        name: p.name,
        id: p.id,
      })) || [];

    setIntegrationProjects(mappedProjects);
    setIntegrationProjectId(mappedProjects[0]?.id ?? '');
    setStep(1);
    setIsLoadingJiraProjects(false);
  };

  const handleNext = () => {
    if (integration === 'Jira' && integrationNamespace !== '') {
      setIsLoadingJiraProjects(true);
      setIntegrationProjects([]);
      setIntegrationProjectId('');

      handleFetchProjectsFromIntegration();
    }
  };

  const handleBack = () => {
    setStep(0);
  };

  const hasManySteps = useMemo(() => {
    return integration === 'Jira';
  }, [integration]);

  const canContinue = useMemo(() => {
    return name !== '' && integrationAPIKey !== '' && integrationEmail !== '' && integrationNamespace !== '';
  }, [name, integrationAPIKey, integrationEmail, integrationNamespace]);

  const canSubmit = useMemo(() => {
    if (hasManySteps) {
      return canContinue && integrationProjectId !== '';
    }

    return name !== '';
  }, [canContinue, integrationProjectId, name, hasManySteps]);

  return (
    <div className="mx-auto max-w-5xl min-w-full">
      <form className="flex flex-col ">
        <div className="flex-1">
          <SectionHeader
            title="New project"
            subTitle="Get started by filling in the information below to create your new project."
            component={<FolderIcon className="h-5 w-5 text-white" />}
          />

          {step === 0 && (
            <div>
              <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-5">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium leading-6 text-white sm:mt-1.5">
                    Project name
                  </label>
                </div>
                <div className="sm:col-span-2">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="block w-full rounded-md border-0 bg-slate-900 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>

              <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-5">
                <div>
                  <label
                    htmlFor="project-description"
                    className="block text-sm font-medium leading-6 text-white sm:mt-1.5"
                  >
                    Integration
                  </label>
                </div>
                <div className="sm:col-span-2">
                  <select
                    id="integration"
                    name="integration"
                    className="block w-full rounded-md border-0 bg-slate-900 pl-3 pr-10 text-white ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    defaultValue="No integration"
                    onChange={(e) => setIntegration(e.target.value)}
                  >
                    <option>No integration</option>
                    <option>Jira</option>
                  </select>
                </div>
              </div>

              {integration === 'Jira' && (
                <>
                  <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-5">
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium leading-6 text-white sm:mt-1.5">
                        Jira email
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="block w-full rounded-md border-0 bg-slate-900 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        placeholder="Jira email address"
                        value={integrationEmail}
                        onChange={(e) => setIntegrationEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-5">
                    <div className="grid grid-cols-1 gap-2">
                      <label htmlFor="apiKey" className="block text-sm font-medium leading-6 text-white sm:mt-1.5">
                        API Key
                      </label>
                      <p className="text-sm text-slate-500">
                        You can generate an API key in Jira. <br />
                        Please follow{' '}
                        <a
                          href="https://support.atlassian.com/atlassian-account/docs/manage-api-tokens-for-your-atlassian-account/"
                          target="_blank"
                          rel="noreferrer"
                          className="text-cyan-600 hover:text-cyan-500"
                        >
                          Atlassian's guide
                        </a>{' '}
                        to learn how.
                      </p>
                    </div>
                    <div className="sm:col-span-2">
                      <input
                        type="text"
                        name="apiKey"
                        id="apiKey"
                        className="block w-full rounded-md border-0 bg-slate-900 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        placeholder="API Key"
                        value={integrationAPIKey}
                        onChange={(e) => setIntegrationAPIKey(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-5">
                    <div className="grid grid-cols-1 gap-2">
                      <label
                        htmlFor="integrationNamespace"
                        className="block text-sm font-medium leading-6 text-white sm:mt-1.5"
                      >
                        Jira namespace
                      </label>
                      <p className="text-sm text-slate-500">
                        For example, if your Jira domain is https://mycompany.atlassian.net, then your namespace is{' '}
                        <span className="font-semibold">mycompany</span>.
                      </p>
                    </div>
                    <div className="sm:col-span-2">
                      <input
                        type="text"
                        name="integrationNamespace"
                        id="integrationNamespace"
                        placeholder="Integration namespace"
                        className="block w-full rounded-md border-0 bg-slate-900 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        value={integrationNamespace}
                        onChange={(e) => setIntegrationNamespace(e.target.value)}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          )}

          {step === 1 && (
            <div className="space-y-2 sm:grid sm:grid-cols-4 sm:gap-8 sm:space-y-0 sm:py-5">
              <div className="grid grid-cols-1 gap-2 sm:col-span-2">
                <label htmlFor="integrationProjectId" className="block text-sm font-medium leading-6 text-white">
                  Jira project
                </label>
                <p className="text-sm text-slate-500">
                  Among your Jira projects, select the one you would like to link to your "{name}" project.
                </p>
              </div>
              <div className="sm:col-span-2">
                {integrationProjects.length > 0 ? (
                  <select
                    id="integrationProjectId"
                    name="integrationProjectId"
                    className="block w-full rounded-md border-0 bg-slate-900 pl-3 pr-10 text-white ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    onChange={(e) => setIntegrationProjectId(e.target.value)}
                  >
                    {integrationProjects.map((p) => (
                      <option key={p.id} value={p.id}>
                        {p.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  <p className="text-sm text-gray-400 mt-2">No Jira project found</p>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="flex justify-end space-x-3 mt-4">
          <div className="flex gap-3">
            {hasManySteps ? (
              <>
                {step === 0 ? (
                  <Button
                    type="button"
                    text="Next"
                    onClick={handleNext}
                    style="primary"
                    loading={isLoadingJiraProjects}
                    disabled={!canContinue}
                  />
                ) : (
                  <>
                    <Button type="button" text="Back" onClick={handleBack} style="secondary" />
                    <Button
                      type="submit"
                      text="Create project"
                      onClick={(e) => handleSubmit(e)}
                      style="primary"
                      loading={addProjectMutation.isLoading}
                      disabled={!canSubmit}
                    />
                  </>
                )}
              </>
            ) : (
              <Button
                type="submit"
                text="Create project"
                onClick={(e) => handleSubmit(e)}
                style="primary"
                loading={addProjectMutation.isLoading}
                disabled={!canSubmit}
              />
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
