import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useActiveOrg, useAuthInfo } from '../propelauth';
import { trpc } from '../trpc';
import Alert from './Alert/Alert';
import { SettingsProjectDangerZone } from './SettingsProjectDangerZone';
import { SettingsProjectGeneral } from './SettingsProjectGeneral';
import { SettingsProjectIntegration } from './SettingsProjectIntegration';
import { SettingsProjectLayout } from './SettingsProjectLayout';
import { useMemo } from 'react';

export function SettingsProject() {
  const { t } = useTranslation();
  const { projectId } = useParams<{ projectId: string }>();
  const auth = useAuthInfo();
  // @ts-ignore
  const { user, isLoggedIn } = auth;
  const activeOrg = useActiveOrg();
  const orgId = activeOrg?.orgId ?? '';
  // @ts-ignore
  const { accessHelper } = auth;

  const projectQuery = trpc.projects.getProject.useQuery(
    { projectId: projectId!, orgId },
    { enabled: !!orgId && !!projectId }
  );
  const { data: project, isLoading: isLoadingProject } = projectQuery;

  const isOrganisationOwner = useMemo(() => {
    return !accessHelper ? false : accessHelper.isRole(orgId, 'Owner');
  }, [accessHelper, orgId]);

  const canManageProject = useMemo(() => {
    const isProjectOwner = project?.userId === user?.userId;
    return isOrganisationOwner || isProjectOwner;
  }, [isOrganisationOwner, project, user]);

  if (project?.shared === true && isLoggedIn && !canManageProject) {
    return (
      <SettingsProjectLayout>
        <Alert title={t('common.forbidden')}>{t('settings.projects.notEnoughAccess')}</Alert>
      </SettingsProjectLayout>
    );
  }

  return (
    <SettingsProjectLayout>
      {isLoadingProject ? (
        <p className="text-white">{t('common.loading')}</p>
      ) : (
        <div className="flex h-full flex-col gap-6">
          <SettingsProjectGeneral />
          <SettingsProjectIntegration />
          <SettingsProjectDangerZone />
        </div>
      )}
    </SettingsProjectLayout>
  );
}
