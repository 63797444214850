import { BeakerIcon, DocumentIcon } from '@heroicons/react/24/outline';
import { SparklesIcon } from '@heroicons/react/24/solid';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { useActiveOrg } from '../../../propelauth';
import { trpc } from '../../../trpc';
import { LinkArrow } from '../../LinkArrow/LinkArrow';
import { SectionHeader } from '../../SectionHeader/SectionHeader';
import { Typo } from '../../Typo';

const ProjectCardLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={400}
      height={202}
      viewBox="0 0 400 202"
      backgroundColor="#334155"
      foregroundColor="#64748B"
    >
      <rect x="0" y="8" rx="3" ry="3" width="88" height="12" />
      <rect x="0" y="38" rx="3" ry="3" width="400" height="1" />
      <rect x="0" y="68" rx="3" ry="3" width="380" height="12" />
      <rect x="0" y="110" rx="6" ry="6" width="380" height="94" />
    </ContentLoader>
  );
};

export const ProjectCard = ({ project }: { project: any }) => {
  const { t } = useTranslation();
  const orgId = useActiveOrg()?.orgId ?? '';

  const projectQuery = trpc.projects.getProject.useQuery(
    {
      projectId: project.id,
      orgId,
    },
    {
      enabled: !!orgId,
    }
  );
  const { isLoading: isProjectLoading, data: projectData } = projectQuery;
  const { automatedTestsCount, testCasesCount, documentsCount } = projectData || {
    automatedTestsCount: 0,
    testCasesCount: 0,
  };

  const getCoverageQuery = trpc.stats.getProjectCoverage.useQuery(
    {
      projectId: project.id,
    },
    { enabled: !!project.id }
  );
  const { isLoading: areStatsLoading, data: stats } = getCoverageQuery;
  const { testsCoverage, automatedTestsCoverage } = stats || {
    testsCoverage: 0,
    automatedTestsCoverage: 0,
  };

  return (
    <div className="group bg-slate-900 p-4 rounded-lg">
      {isProjectLoading || areStatsLoading ? (
        <ProjectCardLoader />
      ) : (
        <>
          <div className="">
            <SectionHeader
              title={project.name}
              actionPosition="left"
              action={
                <LinkArrow
                  text={t('common.view')}
                  to={`/app/projects/${project.id}`}
                  fw="bold"
                  className="transition-all opacity-0 group-hover:opacity-100"
                />
              }
            />
          </div>

          <div className="flex flex-col gap-6">
            <div className="flex flex-col">
              <div className="flex items-center gap-5">
                <a
                  href={`/app/projects/${project.id}/documents`}
                  className="transition-all flex items-center group/item gap-1.5 text-slate-300 hover:text-blue-300"
                >
                  <DocumentIcon className="h-4 w-4 text-slate-400 group-hover/item:text-blue-400 transition-all" />
                  <Typo size="sm" fw="semibold" colour="inherit" className="transition-all">
                    {t('stats.projects.documentsCount', { count: documentsCount })}
                  </Typo>
                </a>

                <a
                  href={`/app/projects/${project.id}/test-cases`}
                  className="transition-all flex items-center group/item gap-1.5 text-slate-300 hover:text-blue-300"
                >
                  <BeakerIcon className="h-4 w-4 text-slate-400 group-hover/item:text-blue-400 transition-all" />
                  <Typo size="sm" fw="semibold" colour="inherit" className="transition-all">
                    {t('stats.projects.testCasesCount', { count: testCasesCount })}
                  </Typo>
                </a>

                <a
                  href={`/app/projects/${project.id}/automated-tests`}
                  className="transition-all flex items-center group/item gap-1.5 text-slate-300 hover:text-blue-300"
                >
                  <SparklesIcon className="h-4 w-4 text-slate-400 group-hover/item:text-blue-400 transition-all" />
                  <Typo size="sm" fw="semibold" colour="inherit" className="transition-all">
                    {t('stats.projects.automatedTestsCount', { count: automatedTestsCount })}
                  </Typo>
                </a>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col gap-1 border rounded-lg px-5 py-4 border-slate-800">
                <Typo size="3xl" colour="slate-300">
                  {testsCoverage}%
                </Typo>
                <Typo size="sm" colour="slate-400">
                  {t('stats.projects.manualTestsCoverage')}
                </Typo>
              </div>
              <div className="flex flex-col gap-1 border rounded-lg px-5 py-4 border-slate-800">
                <Typo size="3xl" colour="slate-300">
                  {automatedTestsCoverage}%
                </Typo>
                <Typo size="sm" colour="slate-400">
                  {t('stats.projects.automatedTestsCoverage')}
                </Typo>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
