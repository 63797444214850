import { navigate } from 'astro:transitions/client';
import { t } from 'i18next';
import { createContext, createElement, type ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { postAction } from '../api';
import { trpc } from '../components/trpc';
import { computeScore, parseScoringResponse } from '../helpers/prompts';
import type { GetDocumentResponse } from '../lib/trpc/routers/documents';
import { useBillingGuard } from './billingGuard';
import { useNotifications } from './notifications';
import { useActiveOrg, useAuthInfo } from '../components/propelauth';

export type PromptAction = 'scoreAndReview' | 'generateTestCases' | 'generateAutomatedTests';

type ContextType = {
  action: PromptAction | undefined;
  result: string;
  performAction: (
    orgId: string,
    userId: string,
    action: PromptAction,
    data: Partial<GetDocumentResponse> | undefined
  ) => void;
};

const PromptsContext = createContext<ContextType | undefined>(undefined);

const PromptsProvider = ({ children }: { children: ReactNode }) => {
  const { documentId, projectId, testCaseId } = useParams<{
    documentId: string;
    projectId: string;
    testCaseId: string;
  }>();
  const { sendNotification } = useNotifications();
  const { hasEnoughCredits } = useBillingGuard();
  const activeOrg = useActiveOrg();
  const orgId = activeOrg?.orgId ?? '';
  const auth = useAuthInfo();
  // @ts-ignore
  const { accessHelper } = auth;

  const [action, setAction] = useState<PromptAction | undefined>(undefined);
  const [result, setResult] = useState('');

  const documentQuery = trpc.documents.getDocument.useQuery(
    {
      documentId: documentId || '',
      projectId: projectId ?? '',
    },
    { enabled: !!documentId && !!projectId }
  );
  const testCaseQuery = trpc.testCases.getTestCase.useQuery(
    {
      testCaseId: testCaseId ?? '',
      projectId: projectId ?? '',
    },
    { enabled: !!testCaseId && !!projectId }
  );

  const canManageBilling = useMemo(() => {
    return !accessHelper ? false : accessHelper.isRole(orgId, 'Owner');
  }, [accessHelper, orgId]);

  const resetLocalStates = () => {
    setAction(undefined);
    setResult('');
  };

  const postPromptRequest = async (
    orgId: string,
    userId: string,
    action: PromptAction,
    data: Partial<GetDocumentResponse>,
    setResult: React.Dispatch<React.SetStateAction<string>>,
    callback?: (response?: string) => void
  ) => {
    switch (action) {
      case 'scoreAndReview': {
        const doc: any = data.document;

        return await postAction(
          [
            {
              name: 'score',
              startCb: () => {
                setResult("We're reviewing your document...");
              },
              finalCb: (response) => {
                const parsedScoringResponse = parseScoringResponse(response);
                console.log('ParsedScoringResponse:', parsedScoringResponse);
                const score = computeScore(parsedScoringResponse);
                console.log('Score:', score);
                setResult(`**✨ Score**: ${score}\n\nGenerating suggestions to improve your document...\n\n`);
              },
            },
            {
              name: 'review',
              chunkCb: (chunk: string) => {
                setResult((oldValue) => oldValue + chunk);
              },
            },
          ],
          { doc, projectId: projectId ?? '', orgId, userId, language: data.language },
          () => {},
          async () => {
            callback?.();
          }
        );
      }

      case 'generateTestCases': {
        const doc: any = data.document;
        const language = data.language;
        return await postAction(
          [
            {
              name: 'generateTestCases',
              chunkCb: (chunk: string) => {
                setResult((oldValue) => oldValue + chunk);
              },
            },
          ],
          {
            doc,
            projectId: projectId ?? '',
            orgId,
            userId,
            language,
          },
          () => {},
          async (response: string) => {
            callback?.();
          }
        );
      }

      case 'generateAutomatedTests': {
        // clean HTML actions, then generate low level test case then automated test
        return await postAction(
          [
            {
              name: 'cleanUpHTMLActions',
              startCb: () => {
                setResult("We're improving your test case...");
              },
            },
            { name: 'generateTestCasesWithHTMLFlow' },
            {
              name: 'generateAutomatedTests',
              startCb: () => {
                setResult(`**✨ We've improved your test case, and we're going to automate it now!** \n\n`);
              },
              chunkCb: (chunk: string) => {
                setResult((oldValue) => oldValue + chunk);
              },
            },
          ],
          {
            doc: data.document,
            testCases: data.testCases,
            flowActions: data.flowActions,
            language: data.language,
            programmingLanguage: data.programmingLanguage,
            projectId: projectId ?? '',
            orgId,
            userId,
            testCaseId,
          },
          () => {},
          async () => {
            callback?.();
          }
        );
      }

      default:
        return;
    }
  };

  const performAction = useCallback(
    async (orgId: string, userId: string, action: PromptAction, data: Partial<GetDocumentResponse> | undefined) => {
      if (!data) {
        return;
      }

      // Check if the user has enough credits to perform the action
      if (!hasEnoughCredits) {
        if (canManageBilling) {
          sendNotification({
            id: 'prompt-error',
            type: 'warning',
            text: t('promptPanels.notEnoughCreditsAdmin.title'),
            action: {
              text: t('promptPanels.notEnoughCreditsAdmin.action'),
              onClick: () => {
                navigate(`/app/settings/organisation/usage`);
              },
            },
          });
        } else {
          sendNotification({
            id: 'prompt-error',
            type: 'warning',
            text: t('promptPanels.notEnoughCredits.title'),
          });
        }

        return;
      }

      resetLocalStates();

      setAction(action);

      await postPromptRequest(orgId, userId, action, data, setResult, async () => {
        // Refetch the document
        await documentQuery.refetch();
        // Refecth the test case
        await testCaseQuery.refetch();

        sendNotification({
          id: 'prompt-success',
          type: 'success',
          text: t('common.genericSuccess'),
          autoDismiss: true,
        });
      });
    },
    [hasEnoughCredits, canManageBilling]
  );

  const value = useMemo(
    () => ({
      action,
      result,
      performAction,
    }),
    [action, result, performAction]
  );

  return createElement(PromptsContext.Provider, { value }, children);
};

const usePrompts = () => {
  const context = useContext(PromptsContext);

  if (context === undefined) {
    throw new Error('usePrompts must be used within a PromptsProvider');
  }

  return context;
};

export { PromptsProvider, usePrompts };
