import { useParams } from 'react-router-dom';

import { useActiveOrg } from '../propelauth';
import { trpc } from '../trpc';
import { DocumentDetails } from './DocumentDetails/DocumentDetails';
import { Layout } from './Layout';
import { Loader } from './Loader/Loader';

export const DocumentDetailsPage = () => {
  const { documentId, projectId } = useParams<{ documentId: string; projectId: string }>();
  const activeOrg = useActiveOrg();
  const orgId = activeOrg?.orgId ?? '';

  const projectQuery = trpc.projects.getProject.useQuery(
    {
      projectId: projectId!,
      orgId,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );
  const { data: project } = projectQuery;

  const documentQuery = trpc.documents.getDocument.useQuery({
    documentId: documentId!,
    projectId: projectId!,
  });
  const { data, isLoading } = documentQuery;
  const { document } = data || {};

  return (
    <Layout title={`${project?.name} / Documents / ${document?.title}`}>
      <div>
        {isLoading ? (
          <div className="grid grid-cols-1 min-h-full h-screen justify-items-center items-center">
            <Loader />
          </div>
        ) : (
          <DocumentDetails />
        )}
      </div>
    </Layout>
  );
};
