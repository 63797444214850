import groupBy from 'lodash.groupby';
import type Stripe from 'stripe';

export const formatAmountForDisplay = (amount: number, isCent = true, currency = 'EUR') => {
  const numberFormat = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
  });

  const amountToFormat = isCent ? amount / 100 : amount;

  return numberFormat.format(amountToFormat);
};

export const groupInvoiceItemsByDate = (invoiceLineItems: Stripe.InvoiceLineItem[] | undefined) => {
  const groupedItems = groupBy(invoiceLineItems, (item) => `${item.period.start}-${item.period.end}`);

  return Object.keys(groupedItems).map((key) => {
    return {
      period: {
        start: Number(key.split('-')[0]),
        end: Number(key.split('-')[1]),
      },
      items: groupedItems[key],
    };
  });
};
