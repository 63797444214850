import type { QueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { I18nextProvider } from 'react-i18next';
import { Outlet, type RouteObject } from 'react-router-dom';

import { env } from '../../config';
import { websiteTitle } from '../../constants';
import type { Helpers } from '../../lib/trpc/root';
import { BillingGuardProvider } from '../../providers/billingGuard';
import { AuthSync } from '../AuthSync';
import { AuthProvider } from '../propelauth';
import type { RouterUtils } from '../trpc';
import { App } from './App';
import { CreateDocumentPage } from './CreateDocumentPage';
import { DocumentDetailsPage } from './DocumentDetailsPage';
import { Documents } from './Documents';
import i18n from './i18n';
import { Layout } from './Layout';
import { Onboarding } from './Onboarding/Onboarding';
import { Project } from './Project';
import { ProjectCreate } from './ProjectCreate';
import { Projects } from './Projects';
import { Recordings } from './Recordings.tsx';
import { SettingsAccount } from './SettingsAccount';
import { SettingsOrganisationBilling } from './SettingsOrganisationBilling';
import { SettingsOrganisationGeneral } from './SettingsOrganisationGeneral';
import { SettingsOrganisationTeam } from './SettingsOrganisationTeam';
import SettingsOrganisationUsage from './SettingsOrganisationUsage';
import { SettingsProject } from './SettingsProject';
import { SettingsProjects } from './SettingsProjects';
import { SignUp } from './SignUp/SignUp';
import { SignUpConfirmation } from './SignUpConfirmation/SignUpConfirmation';
import { propelAuthAtom } from './store';
import { TestCaseDetailsPage } from './TestCaseDetailsPage';
import { TestCases } from './TestCases';

export const routes: RemixBrowserContext & RouteObject[] = [
  {
    path: '/app',
    Component() {
      const key = useAtomValue(propelAuthAtom);
      return (
        <AuthProvider key={key} authUrl={env.PUBLIC_AUTH_URL}>
          <I18nextProvider i18n={i18n}>
            <AuthSync />
            <Outlet />
          </I18nextProvider>
        </AuthProvider>
      );
    },
    children: [
      {
        index: true,
        Component() {
          return (
            <BillingGuardProvider>
              <App />
            </BillingGuardProvider>
          );
        },
      },
      {
        path: '/app/projects',
        Component() {
          return <Projects />;
        },
      },
      {
        path: '/app/projects/create',
        Component() {
          return <ProjectCreate />;
        },
      },
      {
        path: '/app/projects/:projectId',
        Component() {
          return <Project />;
        },
      },
      {
        path: '/app/projects/:projectId/documents',
        Component() {
          return <Documents />;
        },
      },
      {
        path: '/app/projects/:projectId/recordings',
        Component() {
          return <Recordings />;
        },
      },
      {
        path: '/app/projects/:projectId/test-cases',
        Component() {
          return <TestCases />;
        },
      },
      {
        path: '/app/projects/:projectId/test-cases/:testCaseId',
        Component() {
          return <TestCaseDetailsPage />;
        },
      },
      {
        path: '/app/projects/:projectId/documents/create',
        Component() {
          return <CreateDocumentPage />;
        },
      },
      {
        path: '/app/projects/:projectId/documents/:documentId',
        Component() {
          return <DocumentDetailsPage />;
        },
      },
      {
        path: '/app/settings/organisation',
        Component() {
          return (
            <Layout title={`${websiteTitle} / Settings / Organisation`}>
              <SettingsOrganisationGeneral />
            </Layout>
          );
        },
      },
      {
        path: '/app/settings/organisation/team',
        Component() {
          return (
            <Layout title={`${websiteTitle} / Settings / Organisation / Team`}>
              <SettingsOrganisationTeam />
            </Layout>
          );
        },
      },
      {
        path: '/app/settings/organisation/billing',
        Component() {
          return (
            <Layout title={`${websiteTitle} / Settings / Organisations`}>
              <SettingsOrganisationBilling />
            </Layout>
          );
        },
      },
      {
        path: '/app/settings/organisation/usage',
        Component() {
          return (
            <Layout title={`${websiteTitle} / Settings / Organisation / Usage`}>
              <SettingsOrganisationUsage />
            </Layout>
          );
        },
      },
      {
        path: '/app/settings/organisation/projects',
        Component() {
          return <SettingsProjects />;
        },
      },
      {
        path: '/app/projects/:projectId/settings',
        Component() {
          return (
            <Layout title={`${websiteTitle} / Settings / Project`}>
              <SettingsProject />
            </Layout>
          );
        },
      },
      {
        path: '/app/settings/account',
        Component() {
          return <SettingsAccount />;
        },
      },
      {
        path: '/app/onboarding',
        Component() {
          return <Onboarding />;
        },
      },
      {
        path: '/app/signup',
        Component() {
          return <SignUp />;
        },
      },
      {
        path: '/app/signup/confirm',
        Component() {
          return <SignUpConfirmation />;
        },
      },
    ],
  },
];

// browser-only context
export type RemixBrowserContext = {
  trpcUtils?: RouterUtils;
  queryClient?: QueryClient;
};

// server only context
export type RemixContext = {
  helpers: Helpers;
};

declare module 'react-router-dom' {
  interface LoaderFunctionArgs {
    context?: RemixContext;
  }

  interface ActionFunctionArgs {
    context?: RemixContext;
  }
}
