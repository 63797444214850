import markdownit from 'markdown-it';

export const formatMarkdown = (rawResponse: string) => {
  return (
    markdownit({ html: true })
      .render(rawResponse)
      .trim()
      // .replace(/\n/g, '<br>')
      .replace(/```(\w+)/g, '<pre><code class="$1">')
      .replace(/```/g, '</code></pre>')
  );
};
