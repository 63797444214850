import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

export const LinkArrow = ({
  to,
  text,
  arrowSide = 'right',
  colour = 'indigo',
  blank = false,
  size = 'sm',
  fw = 'medium',
  disabled = false,
  className = '',
}: {
  to: string;
  text: string;
  arrowSide?: 'right' | 'left';
  colour?: 'indigo' | 'slate' | 'white';
  blank?: boolean;
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';
  fw?: 'light' | 'normal' | 'medium' | 'semibold' | 'bold' | 'extrabold';
  disabled?: boolean;
  className?: string;
}) => {
  const computedColour = useMemo(() => {
    if (colour === 'indigo') {
      return 'text-indigo-300 hover:text-indigo-400';
    } else if (colour === 'slate') {
      return 'text-slate-400 hover:text-slate-200';
    } else {
      return 'text-white hover:text-white';
    }
  }, [colour]);

  const extraProps = blank ? { target: '_blank', rel: 'noreferrer' } : {};

  const actualTo = disabled ? '#' : to;
  const disabledClass = disabled ? 'cursor-not-allowed' : '';

  return (
    <a
      href={actualTo}
      className={`text-${size} font-${fw} ${computedColour} ${disabledClass} ${className}`}
      {...extraProps}
    >
      {arrowSide === 'left' && (
        <>
          <span aria-hidden="true">&larr; </span>{' '}
        </>
      )}
      {text}
      {arrowSide === 'right' && (
        <>
          {' '}
          <span aria-hidden="true">&rarr; </span>
        </>
      )}
    </a>
  );
};
