import { websiteTitle } from '../../constants';
import { Layout } from './Layout';
import { AccountSettings } from './Settings/AccountSettings/AccountSettings';

export function SettingsAccount() {
  return (
    <Layout title={`${websiteTitle} / Settings / Organisations`}>
      <div className="max-w-screen-xl mx-auto">
        <AccountSettings />
      </div>
    </Layout>
  );
}
