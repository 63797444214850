import { createContext, createElement, type ReactNode, useCallback, useContext, useMemo, useState } from 'react';

export type Modal = {
  id: string;
  text: string;
  type: 'confirm' | 'danger';
  onConfirm: () => void;
  onClose?: () => void;
  subText?: string;
};

type ContextType = {
  modal: Modal | undefined;
  isOpen: boolean;
  openModal: (modal: Modal) => void;
  closeModal: (id: Modal['id']) => void;
};

const ModalsContext = createContext<ContextType | undefined>(undefined);

const ModalsProvider = ({ children }: { children: ReactNode }) => {
  const [modal, setModal] = useState<Modal | undefined>(undefined);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openModal = useCallback((modal: Modal) => {
    setModal(modal);
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(
    (id: string) => {
      modal?.onClose?.();
      setIsOpen(false);
    },
    [modal, setIsOpen, setModal]
  );

  const value = useMemo(
    () => ({
      modal,
      isOpen,
      openModal,
      closeModal,
    }),
    [modal, openModal, closeModal, isOpen]
  );

  return createElement(ModalsContext.Provider, { value }, children);
};

const useModals = () => {
  const context = useContext(ModalsContext);

  if (context === undefined) {
    throw new Error('useModals must be used within a ModalsProvider');
  }

  return context;
};

export { ModalsProvider, useModals };
