import { InformationCircleIcon } from '@heroicons/react/24/outline';

export const InfoSection = ({
  text,
  actionText,
  action,
}: {
  text: string;
  actionText?: string;
  action?: () => void;
}) => {
  return (
    <div className="rounded-md bg-blue-950 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon className="h-5 w-5 text-blue-100" aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-blue-300">{text}</p>
          {actionText && action && (
            <p className="mt-3 text-sm md:ml-6 md:mt-0">
              <a href="#" onClick={action} className="whitespace-nowrap font-medium text-blue-100 hover:text-white">
                {actionText}
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
