import { env } from '../../../config';
import { useAuthInfo } from '../../propelauth';
import { Logo } from '../Logo/Logo';
import OnboardingSteps from '../OnboardingSteps/OnboardingSteps';
import { Typo } from '../Typo';

export const Onboarding = () => {
  const auth = useAuthInfo();
  // @ts-ignore
  const { user } = auth;

  return (
    <div className="md:grid md:grid-rows-4 md:min-h-screen">
      <div className="px-4 py-10 md:p-0 md:row-span-1 self-center flex flex-col items-center gap-6">
        <div className="flex flex-col gap-2 items-center">
          <Logo className="h-8" />

          <div className="flex flex-col items-center gap-2">
            <Typo size="lg">Welcome to OpenTestCase!</Typo>
          </div>
        </div>

        <OnboardingSteps step={3} />
      </div>

      <div className="h-full bg-slate-900 md:row-span-3 py-10 md:p-10">
        {/* @ts-ignore */}
        <stripe-pricing-table
          pricing-table-id={env.PUBLIC_STRIPE_PRICING_TABLE_ID}
          publishable-key={env.PUBLIC_STRIPE_PRICING_TABLE_KEY}
          customer-email={user?.email}
        >
          {/* @ts-ignore */}
        </stripe-pricing-table>
      </div>
    </div>
  );
};
