import { websiteTitle } from '../../constants';
import { Layout } from './Layout';
import ProjectsWelcomeScreen from './Settings/ProjectsWelcomeScreen/ProjectsWelcomeScreen';

export function SettingsProjects() {
  return (
    <Layout title={`${websiteTitle} / Settings / Organisations`}>
      <ProjectsWelcomeScreen />
    </Layout>
  );
}
