import { useEffect } from 'react';

import Alert from '../Alert/Alert';
import { Logo } from '../Logo/Logo';
import OnboardingSteps from '../OnboardingSteps/OnboardingSteps';
import { Typo } from '../Typo';

export const OnboardingSignUpConfirmation = () => {
  useEffect(() => {
    window.localStorage.setItem('hasCompletedOnboarding', 'false');
  }, []);

  return (
    <div className="grid grid-cols-1 min-h-full h-full justify-items-center h-screen items-center">
      <div className="flex flex-col items-center gap-20">
        <div className="flex flex-col items-center gap-10">
          <div className="flex flex-col items-center gap-4">
            <Logo className="h-8" />

            <div className="flex flex-col items-center gap-2">
              <Typo size="lg">Welcome to OpenTestCase!</Typo>
            </div>
          </div>

          <OnboardingSteps step={2} />
        </div>

        <div className="w-full">
          <Alert title="Please confirm your email address">
            Check your emails and click on the confirmation link to activate your account and continue with the
            onboarding process.
          </Alert>
        </div>
      </div>
    </div>
  );
};
