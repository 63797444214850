import { Listbox, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CreditCardIcon,
} from '@heroicons/react/24/outline';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { env } from '../../config';
import { getDayAndMonthAndYear, getDayAndMonthShorter, getMonth } from '../../helpers/dates';
import { formatAmountForDisplay } from '../../helpers/stripe';
import { useActiveOrg, useAuthInfo } from '../propelauth';
import { trpc } from '../trpc';
import { classNames } from '../utils/classNames';
import { Button } from './Button';
import { Loader } from './Loader/Loader';
import { SectionHeader } from './SectionHeader/SectionHeader';
import { SettingsOrganisationLayout } from './SettingsOrganisationLayout';
import { Typo } from './Typo';
import Alert from './Alert/Alert';

type ConsumptionRaw = {
  input: number;
  output: number;
  prompt: string;
};
interface ComputedCreditTopUp {
  id: string;
  amount: number;
  createdAt: Date;
  session: {
    id: string;
    invoice: {
      id: string;
      invoice_pdf: string;
    };
  };
}

const ChartWrapper = ({ sectionTitle, data }: { sectionTitle: string; data: any[] | undefined }) => {
  if (!data) return <></>;

  return (
    <>
      <SectionHeader title={sectionTitle} />
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{
            top: 10,
            right: 50,
            left: 10,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="1 20" vertical={false} />
          <XAxis dataKey="name" stroke="#9ca3af" />
          <YAxis
            type="number"
            stroke="#9ca3af"
            tickCount={4}
            tickFormatter={(value) => formatAmountForDisplay(value, false)}
            domain={['dataMin', 'auto']}
            allowDecimals={true}
          />
          <Tooltip
            formatter={(value: number) => formatAmountForDisplay(value, false)}
            contentStyle={{
              backgroundColor: '#1e293b',
              borderColor: '#4b5563',
              borderRadius: '10px',
            }}
          />
          <Legend />
          <Bar dataKey="input" stackId="a" fill="#6366f1" />
          <Bar dataKey="output" stackId="a" fill="#34d399" />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

const UsageActions = ({
  selectedMonth,
  setSelectedMonth,
  projects,
  selectedProject,
  setSelectedProject,
}: {
  selectedMonth: any;
  setSelectedMonth: any;
  projects: Project[] | null;
  selectedProject?: Project;
  setSelectedProject: any;
}) => {
  const { t } = useTranslation();
  const activeOrg = useActiveOrg();
  const orgId = activeOrg?.orgId ?? '';

  const topUpMutation = trpc.creditsTopUp.topUp.useMutation({
    onSuccess: (result) => {
      // Once Stripe Checkout is complete, redirect to the URL returned by the API
      if (result) {
        window.location.href = result;
      }
    },
  });

  const handleBuyCreditsClick = () => {
    topUpMutation.mutate({ orgId });
  };

  const previousMonthDate = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() - 1, 1);
  const hastNextMonth =
    selectedMonth.getFullYear() < new Date().getFullYear() ||
    (selectedMonth.getFullYear() === new Date().getFullYear() && selectedMonth.getMonth() < new Date().getMonth());
  const nextMonthDate = hastNextMonth
    ? new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 1)
    : undefined;

  return (
    <div className="flex items-center">
      {projects && (
        <ProjectsListDropdown
          projects={projects}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
        />
      )}
      <div className="mr-4 relative flex items-center rounded-md text-white bg-slate-600 shadow-sm md:items-stretch">
        <button
          type="button"
          className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-slate-600 pr-1 hover:bg-slate-500 focus:relative md:w-9 md:pr-0 md:hover:bg-slate-500"
          onClick={() => setSelectedMonth(previousMonthDate)}
        >
          <span className="sr-only">{getMonth(previousMonthDate)}</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          type="button"
          className="hidden border-y border-slate-600 px-3.5 text-sm font-semibold hover:bg-slate-500 focus:relative md:block"
        >
          {getMonth(selectedMonth)}
        </button>
        <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
        <button
          type="button"
          className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-slate-600 pl-1 focus:relative md:w-9 md:pl-0 md:hover:bg-slate-500"
          onClick={() => setSelectedMonth(nextMonthDate)}
          disabled={!hastNextMonth}
        >
          <span className="sr-only">{nextMonthDate && getMonth(nextMonthDate)}</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <Button
        onClick={handleBuyCreditsClick}
        text={t('settings.usage.buyCTA')}
        style="primary"
        size="lg"
        icon={<CreditCardIcon className="w-5 h-5" />}
      />
    </div>
  );
};

interface Project {
  id?: string;
  name: string;
}

function ProjectsListDropdown({
  projects,
  selectedProject,
  setSelectedProject,
}: {
  projects: Project[];
  setSelectedProject: any;
  selectedProject?: Project;
}) {
  const { t } = useTranslation();

  return (
    <Listbox value={setSelectedProject} onChange={setSelectedProject}>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">{t('projectsDropdown.label')}</Listbox.Label>
          <div className="relative mr-4">
            <div className="inline-flex divide-x divide-slate-800 rounded-md shadow-sm ">
              <div className="inline-flex items-center gap-x-1.5 rounded-l-md bg-slate-600 px-3 py-2 text-white shadow-sm">
                <p className="text-sm font-semibold">{selectedProject?.name || t('projectsDropdown.all')}</p>
              </div>
              <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md bg-slate-600 p-2 hover:bg-slate-500 focus:outline-none focus:ring-1 focus:ring-slate-500 focus:ring-offset-1 focus:ring-offset-slate-500">
                <span className="sr-only">{t('projectsDropdown.label')}</span>
                <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
              </Listbox.Button>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-slate-800 overflow-hidden rounded-md bg-slate-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {projects.map((project) => (
                  <Listbox.Option
                    key={project.name}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-slate-600 text-white' : 'text-slate-100',
                        'cursor-pointer select-none p-4 text-sm'
                      )
                    }
                    value={project}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p className={selected ? 'font-semibold' : 'font-normal'}>{project.name}</p>
                          {selected ? (
                            <span className="text-white">
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

export default function SettingsOrganisationUsage() {
  const { t } = useTranslation();

  const activeOrg = useActiveOrg();
  const orgId = activeOrg?.orgId ?? '';
  const auth = useAuthInfo();
  const [projects, setProjects] = useState<Project[] | null>(null);
  const [selectedProject, setSelectedProject] = useState<Project>();
  const [consumptionsFetched, setConsumptionsFetched] = useState(false);
  const [computedCreditsTopUps, setComputedCreditsTopUps] = useState<ComputedCreditTopUp[] | null>(null);
  const [creditsTopUpsFetched, setCreditsTopUpsFetched] = useState(false);
  const [creditsBalance, setCreditsBalance] = useState<number | null>(null);
  const [creditsBalanceFetched, setCreditsBalanceFetched] = useState(false);
  const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1));
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [monthlySpent, setMonthlySpent] = useState<number>(0);
  const [globalChartData, setGlobalChartData] = useState<any[] | undefined>();
  const [scoringChartData, setScoringChartData] = useState<any[] | undefined>();
  const [reviewChartData, setReviewChartData] = useState<any[] | undefined>();
  const [scoringReviewChartData, setScoringReviewChartData] = useState<any[] | undefined>();
  const [testCaseChartData, setTestCaseChartData] = useState<any[] | undefined>();
  const [automatedTestChartData, setAutomatedTestChartData] = useState<any[] | undefined>();

  const [selectedMonth, setSelectedMonth] = useState<any>(new Date());
  const [monthChanged, setMonthChanged] = useState(false);

  useEffect(() => {
    setStartDate(new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1));
    setEndDate(new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 1));
    setMonthChanged(true);
  }, [selectedMonth]);

  console.log('Active org ID', orgId);

  // @ts-ignore
  const { accessHelper } = auth;
  const canViewUsage = useMemo(() => {
    return !accessHelper ? false : accessHelper.isRole(orgId, 'Owner');
  }, [accessHelper, orgId]);

  const getProjectsQuery = trpc.projects.getProjects.useQuery(
    { orgId },
    {
      enabled: !!orgId && !!canViewUsage,
      onSuccess: (data: any) => {
        if (data) {
          setProjects(data);
        }
      },
      staleTime: 1000,
      retry: (retry, error) => {
        return retry < 3 && !error.data?.code;
      },
    }
  );

  const creditsBalanceQuery = trpc.creditsBalance.getCreditsBalance.useQuery(
    { orgId },
    {
      enabled: !!orgId && !!canViewUsage && !creditsBalanceFetched,
      onSuccess: (data: any) => {
        if (data) {
          setCreditsBalance(data.balance);
          setCreditsBalanceFetched(true);
        }
      },
      staleTime: 1000,
      retry: (retry, error) => {
        return retry < 3 && !error.data?.code;
      },
    }
  );

  // retrieve usage
  const consumptionsQuery = trpc.consumptions.getConsumptionsByOrg.useQuery(
    {
      projectId: selectedProject?.id,
      orgId,
      startDate,
      endDate,
    },
    {
      enabled: !!orgId && !!canViewUsage && (!consumptionsFetched || monthChanged),
      onSuccess: (data: ConsumptionRaw[]) => {
        if (data) {
          const totalCost = data.reduce((acc, { input, output }) => {
            return acc + (input * 10 + output * 30) / 1000000;
          }, 0);

          const daysInMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0).getDate();
          const chartData = [];
          const scoringData = [];
          const reviewData = [];
          const scoringReviewData = [];
          const testCaseData = [];
          const automatedTestData = [];

          // checking day after day for consumptions entries
          for (let i = 1; i <= daysInMonth; i++) {
            const correspondingData = data.filter((row: any) => row.createdAt.getDate() === i);
            const today = getDayAndMonthShorter(new Date(startDate.getFullYear(), startDate.getMonth(), i));
            const noDataObject = {
              name: today,
              input: 0,
              output: 0,
            };

            // no entry for today
            if (!correspondingData || correspondingData.length === 0) {
              chartData.push(noDataObject);
              scoringData.push(noDataObject);
              reviewData.push(noDataObject);
              scoringReviewData.push(noDataObject);
              testCaseData.push(noDataObject);
              automatedTestData.push(noDataObject);
            }

            if (correspondingData) {
              const totalInputCost = correspondingData.reduce((acc, { input }) => {
                return acc + (input * 10) / 1000000;
              }, 0);
              const totalOutputCost = correspondingData.reduce((acc, { output }) => {
                return acc + (output * 30) / 1000000;
              }, 0);

              chartData.push({
                name: today,
                input: totalInputCost,
                output: totalOutputCost,
              });

              const correspondingScoringData = correspondingData.filter((d) => d.prompt === 'score');
              const correspondingReviewData = correspondingData.filter((d) => d.prompt === 'review');

              const correspondingScoringReviewData = correspondingData.filter((d) =>
                ['score', 'review'].includes(d.prompt)
              );
              const correspondingTestCaseData = correspondingData.filter((d) => d.prompt === 'generateTestCases');
              const correspondingAutomatedTestData = correspondingData.filter((d) =>
                ['cleanUpHTMLActions', 'generateAutomatedTests', 'generateTestCasesWithHTMLFlow'].includes(d.prompt)
              );

              // Fill scoring data
              if (!correspondingScoringData || correspondingScoringData.length === 0) {
                scoringData.push(noDataObject);
              } else {
                const totalInputCost = correspondingScoringData.reduce((acc, { input }) => {
                  return acc + (input * 10) / 1000000;
                }, 0);
                const totalOutputCost = correspondingScoringData.reduce((acc, { output }) => {
                  return acc + (output * 30) / 1000000;
                }, 0);

                scoringData.push({
                  name: today,
                  input: totalInputCost,
                  output: totalOutputCost,
                });
              }

              // Fill review data
              if (!correspondingReviewData || correspondingReviewData.length === 0) {
                scoringData.push(noDataObject);
              } else {
                const totalInputCost = correspondingReviewData.reduce((acc, { input }) => {
                  return acc + (input * 10) / 1000000;
                }, 0);
                const totalOutputCost = correspondingReviewData.reduce((acc, { output }) => {
                  return acc + (output * 30) / 1000000;
                }, 0);

                reviewData.push({
                  name: today,
                  input: totalInputCost,
                  output: totalOutputCost,
                });
              }

              // Fill scoring / review data
              if (!correspondingScoringReviewData || correspondingScoringReviewData.length === 0) {
                scoringReviewData.push(noDataObject);
              } else {
                const totalInputCost = correspondingScoringReviewData.reduce((acc, { input }) => {
                  return acc + (input * 10) / 1000000;
                }, 0);
                const totalOutputCost = correspondingScoringReviewData.reduce((acc, { output }) => {
                  return acc + (output * 30) / 1000000;
                }, 0);

                scoringReviewData.push({
                  name: today,
                  input: totalInputCost,
                  output: totalOutputCost,
                });
              }

              // Fill test case data
              if (!correspondingTestCaseData || correspondingTestCaseData.length === 0) {
                scoringData.push(noDataObject);
              } else {
                const totalInputCost = correspondingTestCaseData.reduce((acc, { input }) => {
                  return acc + (input * 10) / 1000000;
                }, 0);
                const totalOutputCost = correspondingTestCaseData.reduce((acc, { output }) => {
                  return acc + (output * 30) / 1000000;
                }, 0);

                testCaseData.push({
                  name: today,
                  input: totalInputCost,
                  output: totalOutputCost,
                });
              }

              // Fill automated test data
              if (!correspondingAutomatedTestData || correspondingAutomatedTestData.length === 0) {
                scoringData.push(noDataObject);
              } else {
                const totalInputCost = correspondingAutomatedTestData.reduce((acc, { input }) => {
                  return acc + (input * 10) / 1000000;
                }, 0);
                const totalOutputCost = correspondingAutomatedTestData.reduce((acc, { output }) => {
                  return acc + (output * 30) / 1000000;
                }, 0);

                automatedTestData.push({
                  name: today,
                  input: totalInputCost,
                  output: totalOutputCost,
                });
              }
            }
          }

          setMonthlySpent(totalCost);
          setGlobalChartData(chartData);
          setScoringChartData(scoringData);
          setReviewChartData(reviewData);
          setScoringReviewChartData(scoringReviewData);
          setTestCaseChartData(testCaseData);
          setAutomatedTestChartData(automatedTestData);
          setConsumptionsFetched(true);
          setMonthChanged(true);
        }
      },
      staleTime: 1000,
      retry: (retry, error) => {
        return retry < 3 && !error.data?.code;
      },
    }
  );

  // retrieve credit top ups
  const creditsTopsQuery = trpc.creditsTopUp.getCredits.useQuery(
    {
      // projectId: projectId!,   //TODO no project ID in the URL, we might want to add a filter
      startDate,
      endDate,
      orgId,
    },
    {
      enabled: !!orgId && !!canViewUsage && !creditsTopUpsFetched,
      onSuccess: (data: any) => {
        if (data) {
          setComputedCreditsTopUps(data);
          setCreditsTopUpsFetched(true);
        }
      },
      staleTime: 1000,
      retry: (retry, error) => {
        return retry < 3 && !error.data?.code;
      },
    }
  );

  return (
    <SettingsOrganisationLayout>
      {!canViewUsage && <Alert title={t('common.forbidden')}>{t('settings.usage.forbiddenAccess')}</Alert>}

      {!consumptionsFetched && canViewUsage && (
        <div className="grid grid-cols-1 min-h-full h-screen justify-items-center items-center">
          <Loader />
        </div>
      )}

      {consumptionsFetched && canViewUsage && (
        <div className={`gap-6`}>
          <div className={` bg-slate-900 p-4 rounded-lg`}>
            <SectionHeader
              title={t('settings.usage.title')}
              component={<CreditCardIcon className="h-5 w-5 text-white" />}
              actionPosition="right"
              action={
                <UsageActions
                  selectedMonth={selectedMonth}
                  setSelectedMonth={setSelectedMonth}
                  setSelectedProject={setSelectedProject}
                  selectedProject={selectedProject}
                  projects={projects}
                />
              }
            />

            <div className="grid grid-cols-1 gap-6 text-white">
              <div className="grid grid-cols-1 gap-6">
                {globalChartData && (
                  <>
                    <div className="grid grid-cols-4 gap-4">
                      <div className="col-span-3"></div>
                    </div>
                    <div className="grid grid-cols-4 gap-4">
                      <div className="col-span-3">
                        <ChartWrapper
                          sectionTitle={t('settings.usage.spent', {
                            date: formatAmountForDisplay(monthlySpent, false),
                          })}
                          data={globalChartData}
                        />

                        <ChartWrapper sectionTitle={t('settings.usage.reviewPrompt')} data={scoringReviewChartData} />

                        <ChartWrapper sectionTitle={t('settings.usage.testCasePrompt')} data={testCaseChartData} />

                        <ChartWrapper
                          sectionTitle={t('settings.usage.automatedTestPrompt')}
                          data={automatedTestChartData}
                        />
                      </div>
                      <div className="flex flex-col gap-8">
                        <div>
                          <SectionHeader title={t('settings.usage.currentBalance')} />
                          {creditsBalance && (
                            <p className="font-bold text-2xl sm:text-3xl mb-4">
                              {formatAmountForDisplay(creditsBalance)}
                            </p>
                          )}
                        </div>

                        <div>
                          <SectionHeader title={t('settings.usage.creditGrants')} />
                          <table className="min-w-full divide-y divide-gray-700 -mt-5">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                                >
                                  {t('settings.usage.date')}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                                >
                                  {t('settings.usage.amount')}
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                                >
                                  {t('settings.usage.invoice')}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-800">
                              {computedCreditsTopUps?.map((creditTopUp) => (
                                <tr key={creditTopUp.id}>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                                    {getDayAndMonthAndYear(creditTopUp.createdAt)} <br />
                                  </td>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                                    {formatAmountForDisplay(creditTopUp.amount)}
                                  </td>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                                    {creditTopUp?.session?.invoice?.invoice_pdf && (
                                      <a href={creditTopUp.session.invoice.invoice_pdf}>{t('common.download')}</a>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {!globalChartData && <Typo>No usage data</Typo>}
              </div>
            </div>
          </div>
        </div>
      )}
    </SettingsOrganisationLayout>
  );
}
