import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useNotifications } from '../../providers/notifications';
import { useActiveOrg, useAuthInfo } from '../propelauth';
import { trpc } from '../trpc';
import { Button } from './Button';
import { SectionHeader } from './SectionHeader/SectionHeader';
import { Typo } from './Typo';

export function SettingsProjectDangerZone() {
  const { t } = useTranslation();
  const { projectId } = useParams<{ projectId: string }>();
  const { sendNotification } = useNotifications();
  const activeOrg = useActiveOrg();
  const orgId = activeOrg?.orgId ?? '';
  const auth = useAuthInfo();
  const navigate = useNavigate();
  // @ts-ignore
  const { user } = auth;

  // Fetch current project
  const projectQuery = trpc.projects.getProject.useQuery(
    { projectId: projectId!, orgId },
    { enabled: !!orgId && !!projectId }
  );
  const { data: project } = projectQuery;

  const deleteProjectMutation = trpc.projects.deleteProject.useMutation({
    onSuccess: () => {
      sendNotification({
        id: 'delete-project-success',
        text: t('settings.projects.deleteSuccess'),
        type: 'success',
        autoDismiss: true,
      });

      navigate('/app/projects');
    },
    onError: (err) => {
      sendNotification({
        id: 'delete-project-error',
        text: t('settings.projects.deleteFailure'),
        type: 'error',
      });
    },
  });

  const handleDelete = () => {
    if (!projectId) return;
    if (!orgId) return;

    deleteProjectMutation.mutate({
      projectId,
      orgId,
    });
  };

  return (
    <div className="flex h-full flex-col bg-slate-900 p-4 rounded-lg">
      <SectionHeader
        title={t('settings.projects.danger.title')}
        subTitle={t('settings.projects.danger.subtitle')}
        component={<ExclamationTriangleIcon className="text-white h-6 w-6 shrink-0" aria-hidden="true" />}
      />

      {project && (
        <div className="flex items-center gap-3">
          <div className="flex-1">
            <Typo colour="slate-300">{t('settings.projects.danger.deleteInfo')}</Typo>
          </div>

          <Button text={t('settings.projects.danger.delete')} onClick={() => handleDelete()} style="secondary" />
        </div>
      )}
    </div>
  );
}
