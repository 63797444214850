import { ChevronRightIcon, UserIcon } from '@heroicons/react/24/outline';
import { SparklesIcon } from '@heroicons/react/24/solid';
import { startOfMinute } from 'date-fns';
import groupBy from 'lodash.groupby';
import isEqual from 'lodash.isequal';
import omitBy from 'lodash.omitby';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getRelativeDateTime } from '../../../helpers/dates';
import { trpc } from '../../trpc';
import { classNames } from '../../utils/classNames';
import { displaySuggestions } from '../DocumentDetails/ReviewSuggestions';
import { AutomatedTest } from './AutomatedTest/AutomatedTest';
import { JiraUpdate } from './JiraUpdate/JiraUpdate';
import { TestCase } from './TestCase/TestCase';

type ActivityFeedItem = {
  id: string;
  type: 'score' | 'testCase' | 'automatedTest' | 'document';
  title: string;
  content: React.ReactElement | null;
  updatedAt: Date;
  icon: any;
  iconBackground: string;
};

// Function to find the difference between two objects
function objectDifference(obj1: object, obj2: object) {
  // @ts-ignore
  return omitBy(obj1, (value, key) => isEqual(value, obj2[key]));
}

const getDiffFromPreviousVersion = (current: object, previous: object) => {
  const diff = omitBy(objectDifference(current, previous), (_, k) =>
    ['id', 'updatedAt', 'version', 'createdAt'].includes(k)
  );
  return diff;
};

const buildActivityFeed = (data: any): ActivityFeedItem[] => {
  const { t } = useTranslation();
  const { document, scores, testCases, documentVersions, automatedTests } = data;

  const items: ActivityFeedItem[] = [];

  // Adding the doc's updates to the feed
  documentVersions.forEach((version: any, index: number) => {
    if (index === documentVersions.length - 1) {
      // Document created
      items.push({
        id: document.id,
        type: 'document',
        title: t('documents.createDocumentSuccess') + ' - v1',
        content: null,
        updatedAt: version.createdAt,
        icon: UserIcon,
        iconBackground: 'bg-gray-600',
      });
    } else {
      // Document updated
      const diffFromPrevious = getDiffFromPreviousVersion(version, documentVersions[index + 1]);

      const diffItems = Object.keys(diffFromPrevious).map((k) => ({
        key: k,
        value: version[k],
        previousValue: documentVersions[index + 1][k],
      }));

      if (diffItems.length > 0) {
        const content = (
          <div className="grid grid-cols-1 gap-8">
            {diffItems?.map((item) => (
              <JiraUpdate key={item.key} item={item} previousItem={documentVersions[index + 1]} />
            ))}
          </div>
        );

        items.push({
          id: `${version.id}-${version.updatedAt}`,
          type: 'document',
          title: t('documents.updateDocumentFromJiraSuccess') + ` - v${version.version}`,
          content,
          updatedAt: version.updatedAt,
          icon: UserIcon,
          iconBackground: 'bg-gray-600',
        });
      }
    }
  });

  // Adding the scores to the feed
  scores.forEach((score: any) => {
    let content = null;
    if (score?.reviewSuggestions) {
      const suggestions: { missingCriterion: string; suggestions: string; whyImportant?: string }[] = JSON.parse(
        score.reviewSuggestions
      );
      content = (
        <div className="grid grid-cols-1 gap-6">
          <p className="text-white font-bold">
            {t('documents.score')} {score.score}
          </p>
          {displaySuggestions(suggestions)}
        </div>
      );
    }

    items.push({
      id: score.id,
      type: 'score',
      title: `${t('documents.scoreChangedTo')} ${score.score}`,
      content,
      updatedAt: score.updatedAt,
      icon: SparklesIcon,
      iconBackground: 'bg-green-600',
    });
  });

  // Adding the test cases to the feed
  const grouppedCases = groupBy(testCases, (testCase) => startOfMinute(testCase.createdAt).toString());
  Object.keys(grouppedCases).forEach((key) => {
    const testCaseKeys = grouppedCases[key];
    const testCaseItems = testCaseKeys;

    const content = (
      <div className="grid grid-cols-1 gap-10">
        {testCaseItems?.map((testCaseItem) => <TestCase key={testCaseItem.id} test={testCaseItem} />)}
      </div>
    );

    items.push({
      id: testCaseItems?.map((i) => i.id).join('-') || key,
      type: 'testCase',
      title: t('documents.generatedTestCasesCount', { count: testCaseItems?.length }),
      content,
      updatedAt: testCaseItems?.[0].updatedAt,
      icon: SparklesIcon,
      iconBackground: 'bg-blue-600',
    });
  });

  // Adding the automated tests to the feed
  const grouppedAutomatedCases = groupBy(automatedTests, (testCase) => startOfMinute(testCase.updatedAt).toString());
  Object.keys(grouppedAutomatedCases).forEach((key) => {
    const automatedCasesValues = grouppedAutomatedCases[key];

    const content = (
      <div className="grid grid-cols-1 gap-10">
        {automatedCasesValues?.map((testCaseItem) => <AutomatedTest key={testCaseItem.id} test={testCaseItem} />)}
      </div>
    );

    items.push({
      id: automatedCasesValues?.map((i) => i.id).join('-') || key,
      type: 'automatedTest',
      title: t('documents.generatedAutomatedTestsCount', { count: automatedCasesValues?.length }),
      content,
      updatedAt: new Date(key),
      icon: SparklesIcon,
      iconBackground: 'bg-orange-600',
    });
  });

  return items.sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime());
};

export const ActivityFeed = () => {
  const [openItem, setOpenItem] = useState(-1);
  const { documentId, projectId } = useParams<{ documentId: string; projectId: string }>();
  const { t } = useTranslation();

  const documentQuery = trpc.documents.getDocument.useQuery(
    {
      documentId: documentId!,
      projectId: projectId!,
    },
    { enabled: !!documentId && !!projectId }
  );

  const { data } = documentQuery;
  const { document } = data || {};

  if (!document) {
    return <div>{t('documents.notFound')}</div>;
  }

  const handleHeaderClick = (index: number) => {
    if (openItem === index) {
      return setOpenItem(-1);
    }

    setOpenItem(index);
  };

  const feedItems = buildActivityFeed(data);

  return (
    <div className="flow-root">
      <ul role="list" className="-mb-8">
        {feedItems.map((item, index) => {
          const expendable = ['testCase', 'automatedTest', 'document', 'score'].includes(item.type) && item.content;
          const expended = openItem === index;

          if (!item) {
            return null;
          }

          return (
            <li key={`${item.id}-${item.content}`}>
              <div className="relative pb-8">
                {index !== feedItems.length - 1 ? (
                  <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-slate-700" aria-hidden="true" />
                ) : null}
                <div className="relative flex space-x-4" onClick={() => handleHeaderClick(index)}>
                  <div>
                    <span
                      className={classNames(
                        item.iconBackground,
                        'h-8 w-8 rounded-full flex items-center justify-center ring-4 ring-slate-700'
                      )}
                    >
                      {item.icon && <item.icon className="h-5 w-5 text-white" aria-hidden="true" />}
                    </span>
                  </div>
                  <div
                    className={`flex min-w-0 flex-1 justify-between space-x-4 ${expendable ? 'cursor-pointer' : ''}`}
                  >
                    <div className="grid grid-cols-2 gap-2 items-center">
                      <p className="text-white text-base font-bold">{item.title}</p>
                      {expendable && (
                        <ChevronRightIcon className={`h-5 w-5 text-white ${expended ? 'rotate-90' : ''}`} />
                      )}
                    </div>

                    <div className="whitespace-nowrap text-right text-sm text-gray-500 pt-1">
                      <span>{getRelativeDateTime(item.updatedAt)}</span>
                    </div>
                  </div>
                </div>

                {expended && expendable && <div className="mt-4 ml-14">{item.content}</div>}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
