import { Layout } from './Layout';
import { ProjectsList } from './Projects/ProjectsList/ProjectsList';

export function Projects() {
  return (
    <Layout title="Hardcoded organisation">
      <ProjectsList />
    </Layout>
  );
}
