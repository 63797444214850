import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNotifications } from '../../../../providers/notifications';
import { useAuthInfo } from '../../../propelauth';
import { trpc } from '../../../trpc';
import { Button } from '../../Button';

export const AccountSettings = () => {
  const { t, i18n } = useTranslation();
  const { sendNotification } = useNotifications();
  const auth = useAuthInfo();
  // @ts-ignore
  const { user, loading } = auth;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [language, setLanguage] = useState('');

  const updateUserMetadataMutation = trpc.auth.updateUserMetadata.useMutation();
  const updateUserEmailMutation = trpc.auth.updateUserEmail.useMutation({
    onSuccess: (res) => {
      setEmail(res);
      sendNotification({
        id: 'update-email-success',
        text: t('settings.account.updatedEmailSuccess'),
        type: 'success',
        autoDismiss: true,
      });
    },
    onError: (err) => {
      sendNotification({
        id: 'update-email-error',
        text: t('settings.account.updatedEmailError'),
        type: 'error',
      });
    },
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!user) return;

    updateUserMetadataMutation.mutate({
      userId: user.userId,
      firstName,
      lastName,
      language,
    });

    // update app language
    const locale = language === 'Francais' ? 'fr' : 'en';
    i18n.changeLanguage(locale);

    sendNotification({
      id: 'updated-user-info',
      text: t('settings.account.updatedPersonalInformationSuccess'),
      type: 'success',
      autoDismiss: true,
    });

    if (user.email !== email) {
      updateUserEmailMutation.mutate({
        userId: user.userId,
        email,
      });
    }
  };

  useEffect(() => {
    setFirstName(user?.firstName || '');
    setLastName(user?.lastName || '');
    setEmail(user?.email || '');
    setLanguage(user?.metadata?.language || 'English');
  }, [user]);

  if (loading) return <p className="text-sm text-gray-400">{t('common.loading')}</p>;

  return (
    <div className="flex h-full flex-col space-y-2">
      <div className="">
        <div className="space-y-1">
          <h3 className="text-base font-semibold leading-6 text-white">{t('settings.account.personalInformation')}</h3>
          <p className="text-sm text-gray-400">{t('settings.account.personalInformationSubtitle')}</p>
        </div>
      </div>

      <form className="flex h-full flex-col">
        <div className="flex-1">
          {/* Divider container */}
          <div className="">
            <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-5">
              <div>
                <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-white sm:mt-1.5">
                  {t('settings.account.firstName')}
                </label>
              </div>
              <div className="sm:col-span-2">
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  autoComplete="first-name"
                  className="block w-full rounded-md border-0 bg-slate-900 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  placeholder={t('settings.account.firstName')}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
            </div>

            <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-5">
              <div>
                <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-white sm:mt-1.5">
                  {t('settings.account.lastName')}
                </label>
              </div>
              <div className="sm:col-span-2">
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  autoComplete={t('settings.account.lastName')}
                  className="block w-full rounded-md border-0 bg-slate-900 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  placeholder="Last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>

            <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-5">
              <div>
                <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-white sm:mt-1.5">
                  {t('settings.account.preferredLanguage')}
                </label>
              </div>
              <div className="sm:col-span-2">
                <select
                  id="language"
                  name="language"
                  className="block w-full rounded-md border-0 bg-slate-900 pl-3 pr-10 text-white ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                >
                  <option>English</option>
                  <option>Francais</option>
                </select>
              </div>
            </div>

            <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-5">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-white sm:mt-1.5">
                  {t('settings.account.emailAddress')}
                </label>
              </div>
              <div className="sm:col-span-2">
                <input
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="last-name"
                  className="block w-full rounded-md border-0 bg-slate-900 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  placeholder="john.doe@mycorp.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Action buttons */}
        <div className="flex-shrink-0 border-t border-slate-900 py-5">
          <div className="flex justify-end space-x-3">
            <Button type="submit" text={t('common.save')} onClick={(e) => handleSubmit(e)} style="primary" />
          </div>
        </div>
      </form>
    </div>
  );
};
