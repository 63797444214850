import { DocumentIcon, PlayCircleIcon, QuestionMarkCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { displayCriticality } from '../../../helpers';
import { useModals } from '../../../providers/modals';
import { useNotifications } from '../../../providers/notifications';
import { usePrompts } from '../../../providers/prompts';
import { useActiveOrg, useAuthInfo } from '../../propelauth';
import { trpc } from '../../trpc';
import { AutomatedTest } from '../ActivityFeed/AutomatedTest/AutomatedTest';
import { TestCase } from '../ActivityFeed/TestCase/TestCase';
import { Button } from '../Button';
import { Dropdown, type DropdownAction } from '../Dropdown';
import { InfoSection } from '../InfoSection/InfoSection';
import { LinkArrow } from '../LinkArrow/LinkArrow.tsx';
import RecordingPreview from '../RecordingPreview/RecordingPreview.tsx';
import { SectionHeader } from '../SectionHeader/SectionHeader';
import { Typo } from '../Typo';

export const TestCaseDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { testCaseId, projectId } = useParams<{ testCaseId: string; projectId: string }>();
  const { performAction } = usePrompts();
  const { openModal } = useModals();
  const { sendNotification } = useNotifications();
  const auth = useAuthInfo();
  // @ts-ignore
  const { user, loading } = auth;

  const orgId = useActiveOrg()?.orgId || '';
  const userId = auth.loading ? '' : (auth.user?.userId ?? '');
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [language, setLanguage] = useState('');
  const [programmingLanguage, setProgrammingLanguage] = useState('Javascript');

  useEffect(() => {
    setLanguage(user?.metadata?.language || 'English');
  }, [user]);

  const testCaseQuery = trpc.testCases.getTestCase.useQuery(
    {
      testCaseId: testCaseId!,
      projectId: projectId!,
    },
    { enabled: !!testCaseId && !!projectId }
  );
  const { data, isLoading } = testCaseQuery;
  const { testCase, automatedTest } = data ?? {};

  const documentQuery = trpc.documents.getDocument.useQuery(
    {
      documentId: testCase?.documentId || '',
      projectId: projectId!,
    },
    { enabled: !!testCase?.documentId && !!projectId }
  );
  const { document } = documentQuery.data || {};

  const updateTestCaseMutation = trpc.testCases.updateTestCase.useMutation({
    onSuccess: () => {
      getFlowsQuery.refetch();
      testCaseQuery.refetch();
    },
    onError: (error) => {
      console.error('updateTestCaseMutation error:', error);
    },
  });
  const deleteTestCaseMutation = trpc.testCases.deleteTestCase.useMutation({
    onSuccess: () => {
      sendNotification({
        id: 'delete-test-case-success',
        type: 'success',
        text: t('testCase.deleteSuccess'),
        subText: t('testCase.deleteSuccessSubtitle'),
        autoDismiss: true,
      });
      navigate(`/app/projects/${projectId}/test-cases`);
    },
    onError: (error) => {
      console.error('deleteTestCaseMutation error:', error);
    },
  });

  const getFlowsQuery = trpc.flows.getFlows.useQuery(
    {
      projectId: projectId!,
    },
    { enabled: !!projectId }
  );
  const { data: flows = [], isLoading: areFlowsLoading } = getFlowsQuery;
  const selectedFlow = useMemo(() => {
    return flows.find((flow: any) => flow.id === testCase?.flowId);
  }, [flows, testCase]);

  const saveLastViewedItemMutation = trpc.lastViewedItems.saveLastViewedItem.useMutation();

  const handleFlowChange = (flowId: string) => {
    updateTestCaseMutation.mutate({
      id: testCase?.id || '',
      flowId: flowId === '' ? null : flowId,
    });
  };

  const dropdownActions: DropdownAction[] = [
    {
      text: t('testCase.actions.goToDoc'),
      action: () => navigate(`/app/projects/${projectId}/documents/${document?.documentId}`),
      icon: DocumentIcon,
    },
    {
      text: t('testCase.actions.delete'),
      action: () =>
        openModal({
          id: 'delete-test-case',
          text: 'Are you sure you want to delete this test case?',
          subText: 'The related automated tests will also be deleted. This action cannot be undone.',
          type: 'danger',
          onConfirm: () => {
            deleteTestCaseMutation.mutate({ id: testCase?.id ?? '' });
          },
        }),
      icon: TrashIcon,
      danger: true,
    },
  ];

  const handleGeneteAutomatedTests = () => {
    if (!document || !testCase) {
      return;
    }

    const selectedFlow = flows.find((flow) => flow.id === testCase.flowId);

    const data = {
      document,
      testCases: [testCase],
      flowActions: selectedFlow?.actions,
      programmingLanguage,
      language,
    };

    performAction(orgId, userId, 'generateAutomatedTests', data);
  };

  // Updating the last viewed item (used by the extension to fetch the last viewed project, document and test case)
  useEffect(() => {
    saveLastViewedItemMutation.mutate({
      orgId,
      lastViewedProjectId: projectId,
      lastViewedTestCaseId: testCaseId,
      lastViewedDocumentId: document?.documentId,
    });
  }, [projectId, testCaseId, document?.documentId]);

  if (!testCase) return null;

  return (
    <div className="grid grid-cols-1 gap-12">
      <div className="flex flex-col gap-3">
        <div className="flex items-center justify-between gap-x-10">
          <div className="flex items-start gap-x-4 text-base font-semibold leading-2 text-white">
            <Typo size="xl" colour="white" fw="semibold">
              {testCase.title}
            </Typo>
          </div>
          <div>
            <Dropdown text={t('common.actions')} actions={dropdownActions} primary />
          </div>
        </div>
        <div>{displayCriticality(testCase.criticality)}</div>

        <TestCase test={testCase} noHeader />
      </div>

      <div>
        <SectionHeader
          title={t('testCase.sectionHeaders.htmlFlow')}
          action={
            <QuestionMarkCircleIcon
              data-tooltip-id="html-flow-tooltip"
              data-tooltip-content={t('testCase.sectionHeaders.htmlFlowTooltip')}
              className="w-5 h-5 text-slate-400 hover:text-slate-200 cursor-pointer"
            />
          }
          actionPosition="left"
        />
        {areFlowsLoading && (
          <div className="flex min-h-9 items-center">
            <Typo colour="slate-400">{t('common.loading')}</Typo>
          </div>
        )}
        {!areFlowsLoading && (
          <>
            <div className="flex items-items gap-4">
              <select
                id="htmlFlowFilter"
                name="htmlFlowFilter"
                value={testCase.flowId ?? ''}
                className="rounded-md border-0 bg-white/5 pl-3 pr-10 text-slate-400 text-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onChange={(e) => handleFlowChange(e.target.value)}
              >
                <option value="">{t('testCase.noHtmlFlow')}</option>
                {flows.map((flow: any) => (
                  <option key={flow.id} value={flow.id}>
                    {flow.title}
                  </option>
                ))}
              </select>

              {selectedFlow && selectedFlow.recordingUrl && (
                <>
                  <Button
                    text={t('testCase.htmlFlowPreviewCTA')}
                    onClick={() => setIsPreviewModalOpen(true)}
                    icon={<PlayCircleIcon className="text-white h-5 w-5" />}
                  />
                  <RecordingPreview
                    open={isPreviewModalOpen}
                    setOpen={setIsPreviewModalOpen}
                    recording={selectedFlow}
                  />
                </>
              )}
            </div>
            <div className="flex items-items gap-2 mt-4 mr-4">
              <Typo colour="slate-400" size="sm">
                {t('testCase.recordFromExtensionHint')}
              </Typo>
              <LinkArrow to={t('testCase.recordFromExtensionLink')} blank text={t('testCase.recordFromExtensionCTA')} />
            </div>
          </>
        )}
      </div>

      <div>
        <SectionHeader title={t('testCase.sectionHeaders.automatedTest')} />

        {automatedTest && (
          <div className="flex flex-col gap-4">
            <AutomatedTest test={automatedTest} noHeader />
          </div>
        )}
        {!automatedTest && (
          <>
            <div className="flex items-items gap-4 mb-4">
              <select
                id="language"
                name="language"
                className="rounded-md border-0 bg-slate-900 pl-3 pr-10 text-slate-400 text-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
              >
                <option>English</option>
                <option>French</option>
              </select>
              <select
                id="programmingLanguage"
                name="programmingLanguage"
                className="rounded-md border-0 bg-slate-900 pl-3 pr-10 text-slate-400 text-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                value={programmingLanguage}
                onChange={(e) => setProgrammingLanguage(e.target.value)}
              >
                <option>Java</option>
                <option>Javascript</option>
                <option>Python</option>
                <option>Ruby</option>
              </select>
            </div>
            <div className="flex flex-col items-start gap-3">
              <InfoSection
                text={t('testCase.automatedTestCaseEmptyState.text')}
                actionText={t('testCase.automatedTestCaseEmptyState.CTA')}
                action={handleGeneteAutomatedTests}
              />
            </div>
          </>
        )}
      </div>

      <Tooltip
        id="html-flow-tooltip"
        place="bottom-start"
        style={{ backgroundColor: 'rgb(100, 116, 139)', color: '#fff', maxWidth: '360px' }}
        opacity={1}
      />
    </div>
  );
};
