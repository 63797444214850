// public file that is accessible from browser
import { env } from './config';

export const AUTH_COOKIE_NAME = 'js_b2b_auth';
export const HTTP_ONLY_AUTH_COOKIE_NAME = 'b2b_auth';

export const websiteTitle = 'Prompts with Friends';
export const websiteDescription =
  'Prompts with Friends is a new way to collaborate on GPT prompts with friends or colleagues';

export const DOC_COUNT_PER_PAGE = 10;
export const TEST_CASES_PER_PAGE = 10;
export const AUTOMATED_TESTS_PER_PAGE = 10;

// Stripe and billing
export const CREDITS_THRESHOLD = 0;
export const PLANS_LIMITS = {
  Discovery: {
    maxUsers: 1,
    maxProjects: 1,
  },
  Standard: {
    maxUsers: 4,
    maxProjects: 5,
  },
  Enterprise: {
    maxUsers: Infinity,
    maxProjects: Infinity,
  },
};

// API Endpoints
export const API_FILE_UPLOAD = 'https://yygycdn4wxjirqhtbzol763omq0vksaq.lambda-url.eu-west-3.on.aws';
export const API_JIRA_GET_PROJECTS = 'https://qcawulr50a.execute-api.eu-west-3.amazonaws.com/prod/projects-search';
export const API_LIST_STORIES = 'https://2e8f8zpy8f.execute-api.eu-west-3.amazonaws.com/prod/stories-fetch';

// Old lambda
// export const API_PROMPTS = 'https://m3jt6vqkziakuar2di7apwlyxu0rsqkb.lambda-url.eu-west-3.on.aws';
export const API_FETCH_FILE = 'https://3vtb7h2wxc.execute-api.eu-west-3.amazonaws.com/prod/file?fileName=';

// New lambda (OTC account)
// @TODO: make this dynamic depending on SST config/env
// export const API_PROMPTS = 'https://743p5vuyjt7cfh7x2lsyt5raai0prhqo.lambda-url.eu-west-3.on.aws';
export const API_PROMPTS = env.PUBLIC_AI_ACTIONS_URL;
