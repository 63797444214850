import {
  ChartBarSquareIcon,
  CreditCardIcon,
  FolderIcon,
  InformationCircleIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { useNotifications } from '../../providers/notifications';
import { useActiveOrg, useAuthInfo } from '../propelauth';
import { trpc } from '../trpc';
import { Button } from './Button';
import { SectionHeader } from './SectionHeader/SectionHeader';
import { SettingsOrganisationLayout } from './SettingsOrganisationLayout';
import { Typo } from './Typo';

export function SettingsOrganisationGeneral() {
  const { t } = useTranslation();
  const auth = useAuthInfo();
  // @ts-ignore
  const { loading } = auth;
  const activeOrg = useActiveOrg();
  const { sendNotification } = useNotifications();
  const orgId = activeOrg?.orgId ?? '';

  const [name, setName] = useState(activeOrg?.orgName ?? '');

  const updateOrganisationMutation = trpc.auth.updateOrganisation.useMutation({
    onSuccess: () => {
      sendNotification({
        id: 'update-org-success',
        text: t('settings.general.updateSuccess'),
        type: 'success',
        autoDismiss: true,
      });
      window.location.reload();
    },
    onError: () => {
      sendNotification({
        id: 'update-org-error',
        text: t('settings.general.updateFailure'),
        type: 'error',
      });
    },
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Update organisation
    updateOrganisationMutation.mutate({
      orgId,
      name,
      // @ts-ignore
      metadata: activeOrg?.org_metadata || {},
    });
  };

  const redirectLinks = [
    {
      title: t('menu.settingsTeam'),
      href: '/app/settings/organisation/team',
      icon: UsersIcon,
    },
    {
      title: t('menu.settingsBilling'),
      href: '/app/settings/organisation/billing',
      icon: CreditCardIcon,
    },
    {
      title: t('menu.settingsUsage'),
      href: '/app/settings/organisation/usage',
      icon: ChartBarSquareIcon,
    },
  ];

  useEffect(() => {
    activeOrg?.orgName && setName(activeOrg.orgName);
  }, [activeOrg?.orgName]);

  return (
    <SettingsOrganisationLayout>
      {loading ? (
        <p className="text-white">{t('common.loading')}</p>
      ) : (
        <div className="flex flex-col gap-12">
          <div className="flex flex-col bg-slate-900 p-4 rounded-lg">
            <SectionHeader
              title={t('settings.general.title')}
              subTitle={t('settings.general.subtitle')}
              component={<InformationCircleIcon className="text-white h-6 w-6 shrink-0" aria-hidden="true" />}
            />

            <form className="flex h-full flex-col gap-6">
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-2">
                  <div className="sm:col-span-2">
                    <label htmlFor="name" className="block text-sm font-medium leading-6 text-white sm:mt-1.5">
                      {t('settings.general.name')}
                    </label>
                  </div>
                  <div className="sm:col-span-1">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="block w-full rounded-md border-0 bg-slate-900 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                      placeholder={t('settings.general.name')}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-end space-x-3">
                <Button type="submit" text={t('common.save')} onClick={handleSubmit} style="primary" />
              </div>
            </form>
          </div>

          {/* <div className="grid grid-cols-4 gap-6">
            {redirectLinks.map((item) => (
              <NavLink
                key={item.href}
                to={item.href}
                className="flex flex-col bg-slate-900 hover:bg-slate-800 p-4 rounded-lg"
              >
                <div className="flex gap-3">
                  <item.icon className="h-6 w-6 shrink-0 text-slate-400" aria-hidden="true" />
                  <Typo fw="semibold">{item.title}</Typo>
                </div>
              </NavLink>
            ))}
          </div> */}
        </div>
      )}
    </SettingsOrganisationLayout>
  );
}
