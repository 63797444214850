import { classNames } from '../utils/classNames';

/**
 * Given Tailwind dynamically fetches the colours from our codebase, we need this comment for the colours to be picked up by the Tailwind JIT compiler.
 *
 * text-slate-100 text-slate-200 text-slate-300 text-slate-400 text-slate-500 text-slate-600 text-slate-700 text-slate-800 text-slate-900
 * text-white
 * text-green-100 text-green-200 text-green-300 text-green-400 text-green-500 text-green-600 text-green-700 text-green-800 text-green-900
 * text-red-100 text-red-200 text-red-300 text-red-400 text-red-500 text-red-600 text-red-700 text-red-800 text-red-900
 */

type TypoProps = {
  children: React.ReactNode;
  colour?: string;
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';
  fw?: 'light' | 'normal' | 'medium' | 'semibold' | 'bold';
  className?: string;
  as?: 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'label';
};

export const Typo = ({
  children,
  colour = 'slate-200',
  size = 'base',
  fw = 'normal',
  className = '',
  as = 'p',
  ...otherProps
}: TypoProps) => {
  const Component = as;

  return (
    <Component className={classNames(`text-${size} text-${colour} font-${fw}`, className)} {...otherProps}>
      {children}
    </Component>
  );
};
