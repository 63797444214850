import uniqBy from 'lodash.uniqby';
import { createContext, createElement, type ReactNode, useCallback, useContext, useMemo, useState } from 'react';

export type Notif = {
  id: string;
  text: string;
  type: 'success' | 'error' | 'warning' | 'info';
  subText?: string;
  autoDismiss?: boolean;
  action?: {
    text: string;
    onClick: () => void;
  };
};

type ContextType = {
  notifications: Notif[];
  sendNotification: (notif: Notif) => void;
  dismissNotification: (id: Notif['id']) => void;
};

const NotificationsContext = createContext<ContextType | undefined>(undefined);

const NotificationsProvider = ({ children }: { children: ReactNode }) => {
  const [notifications, setNotifications] = useState<Notif[]>([]);

  const sendNotification = useCallback((notification: Notif) => {
    setNotifications((prevNotifications) => uniqBy([...prevNotifications, notification], 'id'));

    if (notification.autoDismiss) {
      setTimeout(() => {
        dismissNotification(notification.id);
      }, 4000);
    }
  }, []);

  const dismissNotification = useCallback((id: string) => {
    setNotifications((prevNotifications) => prevNotifications.filter((n) => n.id !== id));
  }, []);

  const value = useMemo(
    () => ({
      notifications,
      sendNotification,
      dismissNotification,
    }),
    [notifications, sendNotification, dismissNotification]
  );

  return createElement(NotificationsContext.Provider, { value }, children);
};

const useNotifications = () => {
  const context = useContext(NotificationsContext);

  if (context === undefined) {
    throw new Error('useNotifications must be used within a NotificationsProvider');
  }

  return context;
};

export { NotificationsProvider, useNotifications };
