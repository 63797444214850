import Papa from 'papaparse';

import { uploadFileToLambda } from '../s3/upload';

const ACCEPTED_FILE_TYPES = ['text/xml', 'text/csv'];

export const parseAndUploadDocument = async (file: File, orgId: string, userId: string) => {
  // Validate the file type
  if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
    throw new Error('FILE_TYPE_INCORRECT');
  }

  const docToSave = [];

  // Handling XML files
  if (file.type === 'text/xml') {
    const docPayload = await getDocPayloadFromXML(file);
    docToSave.push(docPayload);
  }

  // Handling CSV files
  if (file.type === 'text/csv') {
    const docPayload = await getDocPayloadFromCSV(file);
    console.log('docPayload:', docPayload);
    docToSave.push(...docPayload);
  }

  // Upload file to S3
  const uploadRes = await uploadFileToLambda(file, orgId, userId);
  const { path } = uploadRes;

  const payload = docToSave.map((doc) => ({
    ...doc,
    filePath: path,
  }));

  return payload;
};

const getDocPayloadFromXML = async (file: File) => {
  // Read the file content
  const fileContent = await readFileAsync(file);
  const fileContentWithoutHeader = fileContent.replace(
    'This XML file does not appear to have any style information associated with it. The document tree is shown below.',
    ''
  );
  const cleanedFileContent = fileContentWithoutHeader
    .replaceAll(/style=&quot;.*?&quot;/g, '')
    .replaceAll(/color=&quot;.*?&quot;/g, '');

  const parsedDoc = parseXML(cleanedFileContent);

  const description = returnNodes(parsedDoc, ['description']) as string;
  const title = returnNodes(parsedDoc, ['title']) as string;
  const key = returnNodes(parsedDoc, ['key']) as string;
  const status = returnNodes(parsedDoc, ['status']) as string;
  const id = returnNodes(parsedDoc, ['key'], true)[0].getAttribute('id') as string;

  return { fileName: file.name, description, title, key, id, status };
};

const getDocPayloadFromCSV = async (file: File) => {
  // Read the file content
  const fileContent = await readFileAsync(file);
  const parsedData = Papa.parse(fileContent, { header: true });
  const { meta, data } = parsedData;
  const localizedFields = getLocalizedFields(meta);

  const documentsToCreate = (
    localizedFields
      ? data.map((row: any) => {
          return {
            description: row[localizedFields.description],
            title: row[localizedFields.title],
            key: row[localizedFields.key],
            id: row[localizedFields.documentId],
            status: row[localizedFields.status],
            fileName: file.name,
          };
        })
      : []
  ).filter((doc) => doc.id);

  return documentsToCreate;
};

const getLocalizedFields = (meta: Papa.ParseMeta) => {
  // Detect CSV lanaugage (for now only French and English are supported)
  const language = meta?.fields?.[0] === 'Résumé' ? 'fr' : 'en';

  if (language === 'fr') {
    return {
      title: 'Résumé',
      documentId: 'ID de ticket',
      key: 'Clé de ticket',
      type: 'Type de ticket',
      status: 'État',
      description: 'Description',
    };
  }

  return undefined;
};

// Helper function to read file asynchronously
function readFileAsync(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });
}

const returnNodes = (doc: Document, nodes: string[], returnCompleteNodes = false) => {
  const nodesContent: any[] = [];

  function traverse(node: any) {
    if (
      nodes.includes(node.nodeName) &&
      node.textContent.trim() !== "Ce fichier est la représentation XML d'un ticket"
    ) {
      nodesContent.push(returnCompleteNodes ? node : node.textContent.trim());
    }

    for (let i = 0; i < node.childNodes.length; i++) {
      const childNode = node.childNodes[i];
      if (childNode.nodeType === Node.ELEMENT_NODE) {
        traverse(childNode);
      }
    }
  }

  traverse(doc.documentElement);

  return returnCompleteNodes ? nodesContent : nodesContent.join('\n');
};

const parseXML = (xml: string) => {
  const parser = new DOMParser();
  const parsedDoc = parser.parseFromString(xml, 'text/xml');

  const parsererror = parsedDoc.getElementsByTagName('parsererror');
  if (parsererror.length > 0) {
    throw new Error('XML_INVALID');
  }

  return parsedDoc;
};
