import { Switch } from '@headlessui/react';
import { useState } from 'react';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export default function Toggle({ title, onChange }: { title: string; onChange: any }) {
  const [enabled, setEnabled] = useState(false);

  const handleOnChange = () => {
    setEnabled(!enabled);
    onChange(!enabled);
  };

  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={enabled}
        onChange={handleOnChange}
        className={classNames(
          enabled
            ? 'bg-indigo-600 ring-indigo-600 focus:ring-slate-500'
            : 'bg-slate-600 ring-slate-600 focus:ring-slate-500',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out ring-2 focus:outline-none focus:ring-offset-0'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-slate-200 shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-3 text-sm">
        <span className="font-medium text-slate-200">{title}</span>
      </Switch.Label>
    </Switch.Group>
  );
}
