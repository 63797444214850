import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import { Fragment } from 'react';

import { useActiveOrg, useAuthInfo } from '../../propelauth';
import { classNames } from '../../utils/classNames';

export default function OrganisationSelect({ onChange }: { onChange: (value: any) => void }) {
  const auth = useAuthInfo();
  // @ts-ignore
  const { orgHelper } = auth;
  const orgs = orgHelper?.getOrgs?.() ?? [];

  const activeOrg = useActiveOrg();
  const activeOrgName = activeOrg?.orgId;

  const organisations: { title: string; current: boolean; id: string }[] = orgs.map((org: any) => ({
    id: org.orgId,
    title: org.orgName,
    current: org.orgId === activeOrgName,
  }));
  const selectedOrg = organisations.find((org) => org.current);

  return (
    <Listbox value={selectedOrg} onChange={(item) => onChange(item)}>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">Change organisation</Listbox.Label>
          <div className="relative">
            <div className="inline-flex">
              <Listbox.Button className="inline-flex items-center p-2 focus:outline-none focus:ring-0 focus:ring-0 focus:ring-offset-0 focus:ring-offset-0 text-slate-400 hover:text-slate-200 focus:text-white">
                <div className="inline-flex items-center gap-x-1.5 px-3 py-2 text-slate-200">
                  <p className="text-sm font-semibold">{selectedOrg?.title ?? 'Organisation'}</p>
                </div>
                <span className="sr-only">Change organisation</span>
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
              </Listbox.Button>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute left-0 z-[300] w-64 mt-2 ml-1 origin-bottom-right divide-y divide-slate-900 overflow-hidden rounded-md bg-slate-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {organisations.map((option) => (
                  <Listbox.Option
                    key={option.title}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-indigo-600 text-white' : 'text-slate-300',
                        'cursor-default select-none px-4 py-2.5 text-sm'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex items-center justify-between gap-4">
                          <p className={'font-semibold'}>{option.title}</p>
                          {selected ? (
                            <span className={'text-white'}>
                              <CheckIcon className="h-4 w-4" aria-hidden="true" />
                            </span>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
