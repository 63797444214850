import { useMemo } from 'react';

export const Button = ({
  onClick,
  text,
  size = 'md',
  style = 'secondary',
  type = 'button',
  loading = false,
  disabled = false,
  icon,
  className,
}: {
  onClick?: (e: any) => void;
  text: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  style?: 'primary' | 'secondary';
  type?: 'button' | 'submit' | 'reset';
  loading?: boolean;
  disabled?: boolean;
  icon?: React.JSX.Element | null | undefined;
  className?: string;
}) => {
  const backgroundClass =
    style === 'primary'
      ? `bg-indigo-600 disabled:bg-indigo-800 ${disabled ? '' : 'hover:bg-indigo-500'}`
      : `bg-white/15 ${disabled ? '' : 'hover:bg-white/25'}`;

  const sizeClass = useMemo(() => {
    switch (size) {
      case 'xs':
        return 'rounded-md px-2 py-1 text-xs';
      case 'sm':
        return 'rounded-md px-2 py-1 text-sm';
      case 'lg':
        return 'rounded-lg px-3 py-2 text-base';
      default: // md
        return 'rounded-md px-3 py-2 text-sm';
    }
  }, [size]);

  return (
    <button
      type={type}
      onClick={onClick}
      className={`transition-all flex items-center justify-center gap-1.5 font-semibold text-white ${sizeClass} shadow-sm cursor-${disabled || loading ? 'not-allowed' : 'pointer'} ${backgroundClass} ${className}`}
      disabled={loading || disabled}
    >
      {icon}
      {!loading ? text : 'Loading...'}
    </button>
  );
};
