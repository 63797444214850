import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import { type Notif, useNotifications } from '../../providers/notifications';
import { LinkArrow } from './LinkArrow/LinkArrow';

const getIcon = (type: Notif['type']) => {
  switch (type) {
    case 'success':
      return CheckCircleIcon;
    case 'info':
      return InformationCircleIcon;
    case 'warning':
      return ExclamationCircleIcon;
    case 'error':
      return ExclamationTriangleIcon;
  }
};

export default function Notifications() {
  const { notifications, dismissNotification } = useNotifications();

  return (
    <>
      <div
        aria-live="assertive"
        className="z-50 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {notifications.map((notification) => {
            const Icon = getIcon(notification.type);

            return (
              <div
                key={notification.id}
                className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-slate-800 shadow-lg ring-1 ring-white ring-opacity-30"
              >
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <Icon className={`h-6 w-6 text-white`} aria-hidden="true" />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className={`text-sm font-medium text-white`}>{notification.text}</p>
                      <p className={`mt-1 text-sm text-gray-400`}>{notification.subText}</p>
                      {notification.action && (
                        <span
                          onClick={notification.action.onClick}
                          className="text-sm text-blue-300 font-semibold cursor-pointer hover:text-blue-200"
                        >
                          {notification.action.text} <span aria-hidden="true">&rarr; </span>
                        </span>
                      )}
                    </div>
                    <div className="ml-4 flex flex-shrink-0">
                      <button
                        type="button"
                        className="inline-flex rounded-md bg-slate-600 text-white hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => {
                          dismissNotification(notification.id);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
