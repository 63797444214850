import { API_PROMPTS } from '../../constants';

export type ActionNames =
  | 'scoreAndReview'
  | 'score'
  | 'review'
  | 'generateTestCases'
  | 'generateTestCasesWithHTMLFlow'
  | 'generateAutomatedTests'
  | 'cleanUpHTMLActions';

export type Action = {
  name: ActionNames;
  startCb?: () => void;
  chunkCb?: (chunk: string) => void;
  finalCb?: (response: string) => void;
};

export const postAction = async (
  actions: Action[],
  data: any,
  streamCallback?: (chunk: string) => void,
  finalCallback?: (response: string) => void
) => {
  const aggregatedResponse = [];
  try {
    const myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow' as RequestRedirect,
      body: JSON.stringify({ actions, data }),
    };

    const req = await fetch(API_PROMPTS, requestOptions);

    if (!req.body) {
      console.error('Error fetching data: req.body is undefined');

      return;
    }

    const reader = req.body.getReader();

    let actionName = '';
    let currentAction: Action | undefined;
    while (true) {
      const data = await reader.read();
      const { done, value } = data;

      if (done) {
        const finalResponse = aggregatedResponse.join('');
        finalCallback?.(finalResponse);

        break;
      }

      // Handle each chunk of data
      const streamChunk = new TextDecoder('utf-8').decode(value);

      if (streamChunk.includes('actionStart:')) {
        actionName = streamChunk.replace('actionStart:', '');
        currentAction = actions.find((action) => action.name === actionName);
        currentAction?.startCb?.();

        console.log(`${actionName} AI action started.`);
      } else if (streamChunk.includes('actionEnd:')) {
        currentAction?.finalCb?.(JSON.parse(streamChunk.replace('actionEnd:', '').trim()));

        console.log(`${actionName} AI action completed. Response:`, streamChunk.replace('actionEnd:', '').trim());
      } else {
        currentAction?.chunkCb?.(streamChunk);
        streamCallback?.(streamChunk);

        aggregatedResponse.push(streamChunk);
      }
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};
