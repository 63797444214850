import { LinkIcon } from '@heroicons/react/24/outline';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getProjects } from '../../api/jira/getProjects';
import { useNotifications } from '../../providers/notifications';
import { useActiveOrg, useAuthInfo } from '../propelauth';
import { trpc } from '../trpc';
import { Button } from './Button';
import { SectionHeader } from './SectionHeader/SectionHeader';
import { Typo } from './Typo';

export function SettingsProjectIntegration() {
  const { t } = useTranslation();
  const { projectId } = useParams<{ projectId: string }>();
  const { sendNotification } = useNotifications();
  const activeOrg = useActiveOrg();
  const orgId = activeOrg?.orgId ?? '';
  const auth = useAuthInfo();
  // @ts-ignore
  const { user } = auth;

  const [integration, setIntegration] = useState('');
  const [step, setStep] = useState(0);
  const [integrationAPIKey, setIntegrationAPIKey] = useState('');
  const [integrationEmail, setIntegrationEmail] = useState(user?.email || '');
  const [integrationNamespace, setIntegrationNamespace] = useState('');
  const [integrationProjectId, setIntegrationProjectId] = useState('');
  const [integrationProjects, setIntegrationProjects] = useState<any[]>([]);
  const [isLoadingJiraProjects, setIsLoadingJiraProjects] = useState(false);

  const getProjectsQuery = trpc.projects.getProjects.useQuery({ orgId }, { enabled: !!orgId });
  const projectQuery = trpc.projects.getProject.useQuery(
    { projectId: projectId!, orgId },
    { enabled: !!orgId && !!projectId }
  );
  const { data: project } = projectQuery;

  const resetFormState = () => {
    setIntegration('');
    setIntegrationAPIKey('');
    setIntegrationEmail('');
    setIntegrationNamespace('');
    setIntegrationProjectId('');
    setStep(0);
  };

  const updateProjectMutation = trpc.projects.updateProject.useMutation({
    onSuccess: () => {
      sendNotification({
        id: 'update-project-success',
        text: t('settings.projects.updateSuccess'),
        type: 'success',
        autoDismiss: true,
      });

      projectQuery.refetch();
      getProjectsQuery.refetch();
      resetFormState();
    },
    onError: (err) => {
      sendNotification({
        id: 'update-project-error',
        text: t('settings.projects.updateFailure'),
        type: 'error',
      });
    },
  });

  const token = useMemo(() => {
    const creds = integrationEmail + ':' + integrationAPIKey;
    return btoa(creds);
  }, [integrationEmail, integrationAPIKey]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!projectId) return;
    if (!orgId) return;

    updateProjectMutation.mutate({
      projectId,
      orgId,
      integration,
      integrationNamespace,
      integrationProjectId,
      integrationAPIKey,
      integrationEmail,
      integrationToken: token,
    });
  };

  const handleCancel = () => {
    resetFormState();
  };

  const handleRemove = () => {
    if (!projectId) return;
    if (!orgId) return;

    updateProjectMutation.mutate({
      projectId,
      orgId,
      integration: '',
      integrationNamespace: '',
      integrationProjectId: '',
      integrationAPIKey: '',
      integrationEmail: '',
      integrationToken: '',
    });
  };

  const handleFetchProjectsFromIntegration = async () => {
    const projects: any[] | undefined = await getProjects({
      jiraDomain: integrationNamespace,
      token,
    });

    const mappedProjects =
      projects?.map((p: any) => ({
        name: p.name,
        id: p.id,
      })) || [];

    setIntegrationProjects(mappedProjects);
    setIntegrationProjectId(mappedProjects[0]?.id ?? '');
    setStep(1);
    setIsLoadingJiraProjects(false);
  };

  const handleNext = () => {
    if (integration === 'Jira' && integrationNamespace !== '') {
      setIsLoadingJiraProjects(true);
      setIntegrationProjects([]);
      setIntegrationProjectId('');

      handleFetchProjectsFromIntegration();
    }
  };

  const handleBack = () => {
    setStep(0);
  };

  const canContinue = useMemo(() => {
    return integrationAPIKey !== '' && integrationEmail !== '' && integrationNamespace !== '';
  }, [integrationAPIKey, integrationEmail, integrationNamespace]);

  const canSubmit = useMemo(() => {
    return canContinue && integrationProjectId !== '';
  }, [canContinue, integrationProjectId]);

  return (
    <div className="flex h-full flex-col bg-slate-900 p-4 rounded-lg">
      <SectionHeader
        title={t('settings.projects.integration.title')}
        subTitle={t('settings.projects.integration.subtitle')}
        component={<LinkIcon className="text-white h-6 w-6 shrink-0" aria-hidden="true" />}
      />

      {project?.integration && (
        <div className="flex items-center gap-3">
          {project?.integration === 'Jira' && (
            <img
              src="https://images.ctfassets.net/8j5aqoy0ts8s/7hk7q0fgUzs9Q8BuHclXtZ/f26088dbde28d0fb9a3eba2deaa1e136/logo-gradient-white-jira.svg"
              alt="Jira"
              className="w-10 h-10"
            />
          )}

          <div className="flex-1">
            <Typo colour="slate-300">{project?.integrationNamespace}</Typo>
          </div>

          <Button
            text={t('settings.projects.integration.removeCTA')}
            onClick={() => handleRemove()}
            style="secondary"
            disabled={updateProjectMutation.isLoading}
          />
        </div>
      )}

      {!project?.integration && (
        <div className="flex-1">
          {!integration && (
            <Button
              text={t('settings.projects.integration.setupCTA')}
              onClick={() => setIntegration('Jira')}
              style="primary"
            />
          )}

          {integration && (
            <form className="flex h-full flex-col">
              {step === 0 && (
                <div className="flex-1">
                  <div>
                    <div className="space-y-2 sm:grid sm:grid-cols-4 sm:gap-8 sm:space-y-0 sm:py-5">
                      <div className="grid grid-cols-1 gap-2 sm:col-span-2">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                          {t('settings.projects.integration.email')}
                        </label>
                        <p className="text-sm text-slate-500">{t('settings.projects.integration.emailSubtitle')}</p>
                      </div>
                      <div className="sm:col-span-2">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="block w-full rounded-md border-0 bg-slate-900 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                          placeholder={t('settings.projects.integration.email')}
                          value={integrationEmail}
                          onChange={(e) => setIntegrationEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="space-y-2 sm:grid sm:grid-cols-4 sm:sm:gap-8 sm:space-y-0 sm:py-5">
                      <div className="grid grid-cols-1 gap-2 sm:col-span-2">
                        <label htmlFor="apiKey" className="block text-sm font-medium leading-6 text-white">
                          {t('settings.projects.integration.apiKey')}
                        </label>
                        {/* text-cyan-400 hover:text-cyan-500 */}
                        <p
                          className="text-sm text-slate-500"
                          dangerouslySetInnerHTML={{
                            __html: t('settings.projects.integration.apiKeySubtitle'),
                          }}
                        ></p>
                      </div>
                      <div className="sm:col-span-2">
                        <input
                          type="text"
                          name="apiKey"
                          id="apiKey"
                          className="block w-full rounded-md border-0 bg-slate-900 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                          placeholder={t('settings.projects.integration.apiKey')}
                          value={integrationAPIKey}
                          onChange={(e) => setIntegrationAPIKey(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="space-y-2 sm:grid sm:grid-cols-4 sm:gap-8 sm:space-y-0 sm:py-5">
                      <div className="grid grid-cols-1 gap-2 sm:col-span-2">
                        <label htmlFor="namespace" className="block text-sm font-medium leading-6 text-white">
                          {t('settings.projects.integration.namespace')}
                        </label>
                        <p
                          className="text-sm text-slate-500"
                          dangerouslySetInnerHTML={{
                            __html: t('settings.projects.integration.namespaceSubtitle'),
                          }}
                        ></p>
                      </div>
                      <div className="sm:col-span-2">
                        <input
                          type="text"
                          name="namespace"
                          id="namespace"
                          className="block w-full rounded-md border-0 bg-slate-900 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                          placeholder={t('settings.projects.integration.namespace')}
                          value={integrationNamespace}
                          onChange={(e) => setIntegrationNamespace(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {step === 1 && (
                <div>
                  <div className="space-y-2 sm:grid sm:grid-cols-4 sm:gap-8 sm:space-y-0 sm:py-5">
                    <div className="grid grid-cols-1 gap-2 sm:col-span-2">
                      <label htmlFor="integrationProjectId" className="block text-sm font-medium leading-6 text-white">
                        {t('settings.projects.integration.project')}
                      </label>
                      <p
                        className="text-sm text-slate-500"
                        dangerouslySetInnerHTML={{
                          __html: t('settings.projects.integration.projectSubtitle', { name: project?.name }),
                        }}
                      ></p>
                    </div>
                    <div className="sm:col-span-2">
                      {integrationProjects.length > 0 ? (
                        <select
                          id="integrationProjectId"
                          name="integrationProjectId"
                          className="block w-full rounded-md border-0 bg-slate-900 pl-3 pr-10 text-white ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                          onChange={(e) => setIntegrationProjectId(e.target.value)}
                        >
                          {integrationProjects.map((p) => (
                            <option key={p.id} value={p.id}>
                              {p.name}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <p className="text-sm text-gray-400 mt-2">
                          {t('settings.projects.integration.noProjectFound')}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="flex justify-end space-x-3">
                <div className="flex gap-3">
                  {step === 0 ? (
                    <>
                      <Button type="button" text={t('common.cancel')} onClick={handleCancel} style="secondary" />
                      <Button
                        type="button"
                        text={t('settings.projects.integration.nextCTA')}
                        onClick={handleNext}
                        style="primary"
                        loading={isLoadingJiraProjects}
                        disabled={!canContinue}
                      />
                    </>
                  ) : (
                    <>
                      <Button type="button" text="Back" onClick={handleBack} style="secondary" />
                      <Button
                        type="submit"
                        text={t('settings.projects.integration.addCTA')}
                        onClick={(e) => handleSubmit(e)}
                        style="primary"
                        loading={updateProjectMutation.isLoading}
                        disabled={!canSubmit}
                      />
                    </>
                  )}
                </div>
              </div>
            </form>
          )}
        </div>
      )}
    </div>
  );
}
