import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { SparklesIcon } from '@heroicons/react/24/solid';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatMarkdown } from '../../helpers';
import { usePrompts } from '../../providers/prompts';

export default function PromptSidePanel() {
  const { t } = useTranslation();
  const { action, result } = usePrompts();
  const [open, setOpen] = useState(false);

  const title = useMemo(() => {
    switch (action) {
      case 'scoreAndReview':
        return t('promptPanels.titles.scoreAndReview');
      case 'generateTestCases':
        return t('promptPanels.titles.testCases');
      case 'generateAutomatedTests':
        return t('promptPanels.titles.automatedTests');
      default:
        return '';
    }
  }, [action]);

  useEffect(() => {
    if (action) {
      setOpen(true);
    }
  }, [action]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 top-12 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-6xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-slate-900 py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <div className="flex items-center gap-4">
                          <div className="mx-auto flex h-8 w-8 items-center justify-center rounded-full bg-green-500">
                            <SparklesIcon className="h-4 w-4 text-green-100" aria-hidden="true" />
                          </div>
                          <Dialog.Title className="text-base font-semibold leading-6 text-slate-200">
                            {title}
                          </Dialog.Title>
                        </div>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-slate-900 text-slate-400 hover:text-slate-100 focus:outline-none focus:ring-2 focus:ring-slate-600"
                            onClick={() => setOpen(false)}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      <pre className="output whitespace-pre-wrap text-sm text-white overflow-x-auto">
                        <div
                          className="font-mono text-slate-200 text-sm"
                          dangerouslySetInnerHTML={{
                            __html: formatMarkdown(result),
                          }}
                        />
                      </pre>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
