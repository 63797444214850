import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useModals } from '../../providers/modals';

export const Modals = () => {
  const { t } = useTranslation();
  const { modal, closeModal, isOpen } = useModals();

  const confirmButtonRef = useRef(null);

  const handleCancel = () => {
    if (!modal) return;

    closeModal(modal.id);
  };

  const handleConfirm = () => {
    if (!modal) return;

    modal.onConfirm();
    closeModal(modal.id);
  };

  const Icon = useMemo(() => {
    switch (modal?.type) {
      case 'confirm':
        return CheckIcon;
      case 'danger':
        return ExclamationTriangleIcon;
      default:
        return CheckIcon;
    }
  }, [modal?.type]);

  const getIconColour = useMemo(() => {
    switch (modal?.type) {
      case 'confirm':
        return 'text-green-100';
      case 'danger':
        return 'text-red-100'; // bg-red-300 text-red-100
      default:
        return 'text-green-100';
    }
  }, [modal?.type]);

  const getButtonBackground = useMemo(() => {
    switch (modal?.type) {
      case 'danger':
        return 'red'; // bg-red-600 hover:bg-red-500
      default:
        return 'indigo'; // bg-indigo-600 hover:bg-indigo-500
    }
  }, [modal?.type]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={confirmButtonRef}
        onClose={() => closeModal(modal?.id ?? '')}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed z-999 inset-0 bg-black bg-opacity-90 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-999 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-slate-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className={`mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-slate-600`}>
                    <Icon className={`h-6 w-6 ${getIconColour}`} aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-slate-100">
                      {modal?.text}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-slate-300">{modal?.subText}</p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className={`inline-flex w-full justify-center rounded-md bg-${getButtonBackground}-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-${getButtonBackground}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${getButtonBackground}-600 sm:col-start-2`}
                    onClick={handleConfirm}
                    ref={confirmButtonRef}
                  >
                    {t('common.confirm')}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-slate-600 px-3 py-2 text-sm font-semibold text-slate-100 shadow-sm ring-1 ring-inset ring-slate-600 hover:bg-slate-500 sm:col-start-1 sm:mt-0"
                    onClick={handleCancel}
                  >
                    {t('common.cancel')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
