import { ChevronDownIcon } from '@heroicons/react/24/outline';

import { Typo } from '../Typo';

export const SectionHeader = ({
  title,
  component,
  action,
  collapsible,
  collapsed = false,
  onCollapse,
  actionPosition = 'right',
  subTitle,
}: {
  title: string;
  component?: React.JSX.Element | null | undefined;
  action?: React.JSX.Element | null | undefined;
  collapsible?: boolean;
  collapsed?: boolean;
  onCollapse?: () => void;
  actionPosition?: 'left' | 'right';
  subTitle?: string;
}) => {
  return (
    <div
      className={`flex items-center justify-between ${collapsible && collapsed ? '' : 'border-b border-slate-800 mb-6'} pb-3`}
    >
      <div className="flex gap-3 items-center">
        {collapsible && onCollapse && (
          <button
            onClick={onCollapse}
            className="rounded bg-white/10 px-1 py-1 text-xs font-semibold text-white shadow-sm hover:bg-white/20"
          >
            <ChevronDownIcon className={`text-white h-5 w-5 ${!collapsed ? 'rotate-180' : ''}`} />
          </button>
        )}
        {component}
        <h3
          onClick={onCollapse}
          className={`flex items-center gap-4 text-base font-semibold text-lg text-white ${collapsible ? 'cursor-pointer' : ''}`}
        >
          <div className="flex flex-col gap-0.5">
            {title}
            {subTitle && (
              <Typo colour="slate-400" size="sm">
                {subTitle}
              </Typo>
            )}
          </div>
          {actionPosition === 'left' && action}
        </h3>
      </div>
      {actionPosition === 'right' && action}
    </div>
  );
};
