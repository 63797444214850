export const displaySuggestions = (suggestions: any, title?: string): any => {
  const isUnclearSection = title?.includes('Unclear or inconsistent elements to be clarified');
  const firstColumnTitle = isUnclearSection ? 'Inconsistent element' : 'Missing criterion';
  const secondColumnTitle = isUnclearSection ? 'Suggested clarification' : 'Suggested completion';

  // if is array
  if (suggestions instanceof Array) {
    return (
      <div className="output">
        <h3 className="flex items-center gap-4 text-lg font-semibold text-white mb-2">{title}</h3>
        <table className="mb-8 text-gray-300">
          <thead>
            <tr>
              <th>{firstColumnTitle}</th>
              <th>{secondColumnTitle}</th>
            </tr>
          </thead>
          <tbody>
            {suggestions
              .filter((s: any) => !s.missingCriterion.includes('---'))
              .map((suggestion: any, index: number) => (
                <tr key={`suggestion.missingCriterion.trim()-${index}`}>
                  <td>{suggestion.missingCriterion}</td>
                  <td>{suggestion.suggestions}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  }

  // new score and review format
  return (
    <>
      {suggestions.missingElements?.length > 0 &&
        displaySuggestions(suggestions.missingElements, 'Missing elements in the user story')}
      {suggestions.inconsistencies?.length > 0 &&
        displaySuggestions(suggestions.inconsistencies, 'Unclear or inconsistent elements to be clarified')}
      {suggestions.missingQualityElements?.length > 0 &&
        displaySuggestions(suggestions.missingQualityElements, 'Missing quality criteria')}
    </>
  );
};
