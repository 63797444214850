import { CreateDocument } from './CreateDocument/CreateDocument';
import { Layout } from './Layout';

export const CreateDocumentPage = () => {
  return (
    <Layout title={`Create document`}>
      <CreateDocument />
    </Layout>
  );
};
