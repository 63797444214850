import {
  BeakerIcon,
  CreditCardIcon,
  CurrencyEuroIcon,
  DocumentIcon,
  FolderIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { SparklesIcon } from '@heroicons/react/24/solid';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { formatAmountForDisplay } from '../../../helpers/stripe';
import { useActiveOrg, useAuthInfo } from '../../propelauth';
import { trpc } from '../../trpc';
import { Button } from '../Button';
import { Loader } from '../Loader/Loader';
import { ProjectCard } from '../Projects/ProjectCard/ProjectCard';
import { SectionHeader } from '../SectionHeader/SectionHeader';
import { GlobalStatsItem } from './GlobalStatItem/GlobalStatItem';
import { useNavigate } from 'react-router-dom';

const GlobalStats = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const orgId = useActiveOrg()?.orgId ?? '';

  const usersQuery = trpc.auth.fetchOrgUsers.useQuery({ orgId }, { enabled: !!orgId });
  const { data: users, isLoading: areUsersLoading } = usersQuery;

  const statsQuery = trpc.stats.getGlobalStats.useQuery({ orgId }, { enabled: !!orgId });
  const { data: stats, isLoading: areStatsLoading } = statsQuery;

  const { projectsCount, documentsCount, testCasesCount, automatedTestsCount } = stats || {
    projectsCount: 0,
    documentsCount: 0,
    testCasesCount: 0,
    automatedTestsCount: 0,
  };

  const auth = useAuthInfo();
  // @ts-ignore
  const { accessHelper } = auth;

  const canViewUsage = useMemo(() => {
    return !accessHelper ? false : accessHelper.isRole(orgId, 'Owner');
  }, [accessHelper, orgId]);

  const creditsBalanceQuery = trpc.creditsBalance.getCreditsBalance.useQuery({ orgId }, { enabled: !!orgId });
  const { data: creditsBalanceData, isLoading: isCreditsBalanceLoading } = creditsBalanceQuery;
  const balance = Number(creditsBalanceData?.balance || 0);

  const elements: { name: string; value: string; icon?: any; action?: any }[] = [
    { name: t('stats.organisation.projectsCount'), value: projectsCount.toString(), icon: FolderIcon },
    { name: t('stats.organisation.documentsCount'), value: documentsCount.toString(), icon: DocumentIcon },
    { name: t('stats.organisation.testCasesCount'), value: testCasesCount.toString(), icon: BeakerIcon },
    { name: t('stats.organisation.automatedTestsCount'), value: automatedTestsCount.toString(), icon: SparklesIcon },
    { name: t('stats.organisation.teammatesCount'), value: (users?.length ?? 0).toString(), icon: UsersIcon },
    canViewUsage && {
      name: t('stats.organisation.creditsBalance'),
      value: formatAmountForDisplay(balance),
      icon: CurrencyEuroIcon,
      action: (
        <Button
          text={t('common.buyCredits')}
          size="sm"
          icon={<CreditCardIcon className="w-4 h-4" />}
          onClick={() => navigate('/app/settings/organisation/usage')}
        />
      ),
    },
  ].filter(Boolean);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5">
      {elements.map((stat) => (
        <GlobalStatsItem
          key={`${orgId}-${stat.name}`}
          stat={stat}
          isLoading={isCreditsBalanceLoading || areStatsLoading || areUsersLoading}
        />
      ))}
    </div>
  );
};

export const WelcomeScreen = () => {
  const { t } = useTranslation();
  const orgId = useActiveOrg()?.orgId ?? '';

  if (!orgId) {
    return (
      <div className="grid grid-cols-1 min-h-full h-screen justify-items-center items-center">
        <Loader />
      </div>
    );
  }

  const projectsQuery = trpc.projects.getProjects.useQuery({ orgId }, { enabled: !!orgId });
  const { data: projects } = projectsQuery;

  return (
    <div className="flex flex-col gap-12">
      <div>
        <SectionHeader title={t('stats.organisation.overviewTitle')} />
        <GlobalStats />
      </div>

      <div>
        <SectionHeader title={t('stats.projects.overviewTitle')} />
        <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-8">
          {projects?.map((project) => <ProjectCard key={project.id} project={project} />)}
        </div>
      </div>
    </div>
  );
};
