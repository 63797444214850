import { NotificationsProvider } from '../../../providers/notifications';
import Notifications from '../Notifications';
import { OnboardingSignUpConfirmation } from '../OnboardingSignUpConfirmation/OnboardingSignUpConfirmation';

export const SignUpConfirmation = () => (
  <NotificationsProvider>
    <>
      <OnboardingSignUpConfirmation />
      <Notifications />
    </>
  </NotificationsProvider>
);
