import ContentLoader from 'react-content-loader';

const GlobalStatsItemLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={300}
      height={72}
      viewBox="0 0 300 72"
      backgroundColor="#334155"
      foregroundColor="#64748B"
    >
      <rect x="0" y="8" rx="3" ry="3" width="120" height="10" />
      <rect x="0" y="42" rx="6" ry="6" width="24" height="24" />
      <rect x="36" y="42" rx="6" ry="6" width="50" height="24" />
    </ContentLoader>
  );
};

export const GlobalStatsItem = ({
  stat,
  isLoading,
}: {
  isLoading: boolean;
  stat: { name: string; value: string; icon?: any; action?: any };
}) => {
  return (
    <div className=" px-4 py-6 sm:px-6 lg:px-8">
      {isLoading ? (
        <GlobalStatsItemLoader />
      ) : (
        <>
          <div>
            <p className="text-sm font-medium leading-6 text-gray-400">{stat.name}</p>
          </div>
          <div className="flex items-start gap-2 mt-2">
            {stat.icon && <stat.icon className="h-6 w-6 mt-2 shrink-0 text-slate-400" aria-hidden="true" />}
            <p className="flex items-baseline gap-x-2">
              <span className="text-4xl font-semibold tracking-tight text-white">{stat.value}</span>
              {/* {stat.unit ? <span className="text-sm text-gray-400">{stat.unit}</span> : null} */}
            </p>
          </div>
          {stat.action && <div className="mt-4">{stat.action}</div>}
        </>
      )}
    </div>
  );
};
