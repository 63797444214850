import { TrashIcon } from '@heroicons/react/24/outline';
import hljs from 'highlight.js';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useModals } from '../../../../providers/modals';
import { useNotifications } from '../../../../providers/notifications';
import { trpc } from '../../../trpc';
import { Dropdown } from '../../Dropdown';

export const AutomatedTest: React.FC<{ test: any; noHeader?: boolean }> = ({ test, noHeader }) => {
  const { t } = useTranslation();
  const { testCaseId, projectId } = useParams<{ testCaseId: string; projectId: string }>();
  const { sendNotification } = useNotifications();
  const { openModal } = useModals();

  const testCaseQuery = trpc.testCases.getTestCase.useQuery(
    {
      testCaseId: testCaseId ?? '',
      projectId: projectId ?? '',
    },
    {
      enabled: !!testCaseId && !!projectId,
    }
  );

  const deleteAutomatedTestMutation = trpc.automatedTests.deleteAutomatedTest.useMutation({
    onSuccess: () => {
      sendNotification({
        id: 'delete-automated-test-success',
        type: 'success',
        text: t('automatedTests.deletionSuccessful'),
        autoDismiss: true,
      });
      testCaseQuery.refetch();
    },
    onError: (error) => {
      console.error('deleteTestCaseMutation error:', error);
    },
  });

  return (
    <div key={test.id} className="relative">
      {!noHeader && <h4 className="text-white text-base font-bold mb-4">{test.title}</h4>}
      {/* <div className="text-slate-300 font-semibold bg-slate-700 text-sm p-2 rounded-t-lg py-2 px-4">
        {test.language.charAt(0).toUpperCase() + test.language.slice(1)} -{' '}
        {test.framework.charAt(0).toUpperCase() + test.framework.slice(1)}
      </div> */}
      <pre className="language-javascript text-sm text-slate-200 overflow-x-auto border border-slate-700 rounded-lg px-4 pb-4">
        <code
          className="language-javascript"
          dangerouslySetInnerHTML={{
            __html: hljs.highlight(test.language, test.code).value,
          }}
        />
      </pre>

      <div className="absolute top-5 right-5">
        <Dropdown
          actions={[
            {
              text: t('automatedTests.deleteButton'),
              action: () =>
                openModal({
                  id: 'delete-automated-test',
                  text: t('automatedTests.deletionConfirmationQuestion'),
                  type: 'danger',
                  onConfirm: () => {
                    deleteAutomatedTestMutation.mutate({ id: test.id });
                  },
                }),
              icon: TrashIcon,
              danger: true,
            },
          ]}
          dots
        />
      </div>
    </div>
  );
};
