import { nanoid } from 'nanoid';

import { API_FILE_UPLOAD } from '../../constants';

export const uploadFileToLambda = async (file: File, orgId: string, userId: string): Promise<any> => {
  const name = `${nanoid()}.${file.name.split('.').pop()}`;

  // Create FormData object and append the file
  const formData = new FormData();
  formData.append('file', file);
  formData.append('name', name);
  formData.append('orgId', orgId);
  formData.append('userId', userId);

  // Perform POST request to the Lambda function endpoint
  const response = await fetch(API_FILE_UPLOAD, {
    method: 'POST',
    body: formData,
  });

  if (response.ok) {
    return response.json();
  } else {
    throw new Error('Failed to upload file to Lambda function');
  }
};
