import { format, formatRelative } from 'date-fns';

export const getRelativeDateTime = (date: Date) => {
  return formatRelative(date, new Date());
};

export const getDayAndMonth = (date: Date) => {
  return format(date, 'LLLL d');
};

export const getDayAndMonthShorter = (date: Date) => {
  return format(date, 'd LLL');
};

export const getDayAndMonthAndYear = (date: Date) => {
  return format(date, 'LLLL d, yyyy');
};

export const getMonth = (date: Date) => {
  return format(date, 'LLLL');
};
