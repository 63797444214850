import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import type { UserMetadata } from '@propelauth/node';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { classNames } from '../../../utils/classNames';

export default function RoleSelect({
  teammate,
  onChange,
}: {
  teammate: UserMetadata | undefined;
  onChange: (value: string) => void;
}) {
  const { t } = useTranslation();

  if (!teammate) {
    return null;
  }

  //  @ts-ignore
  const role = teammate.roleInOrg;

  const publishingOptions = [
    {
      title: t('roles.member'),
      description: t('roles.memberDescription'),
      current: role === 'Member',
    },
    {
      title: t('roles.admin'),
      description: t('roles.adminDescription'),
      current: role === 'Admin',
    },
    // {
    //   title: 'Owner',
    //   description: 'Can fully manage organisation, projects, team members and billing.',
    //   current: role === 'Owner',
    // },
  ];

  return (
    <Listbox value={role} onChange={(item) => onChange(item.title)}>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">Change role</Listbox.Label>
          <div className="relative">
            <div className="inline-flex divide-x divide-slate-800 rounded-md shadow-sm">
              <div className="inline-flex items-center gap-x-1.5 rounded-l-md bg-slate-700 px-3 py-2 text-white shadow-sm">
                <p className="text-sm font-semibold">{t(`roles.${role.toLowerCase()}`)}</p>
              </div>
              <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md bg-slate-700 p-2 hover:bg-slate-600 focus:outline-none focus:ring-1 focus:ring-slate-500 focus:ring-offset-1 focus:ring-offset-slate-500">
                <span className="sr-only">Change role</span>
                <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
              </Listbox.Button>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-slate-800 overflow-hidden rounded-md bg-slate-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {publishingOptions.map((option) => (
                  <Listbox.Option
                    key={option.title}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-indigo-600 text-white' : 'text-slate-100',
                        'cursor-default select-none p-4 text-sm'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p className={selected ? 'font-semibold' : 'font-normal'}>{option.title}</p>
                          {selected ? (
                            <span className={active ? 'text-white' : 'text-indigo-600'}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </div>
                        <p className={classNames(active ? 'text-indigo-200' : 'text-slate-400', 'mt-2')}>
                          {option.description}
                        </p>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
